import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const Navdata = () => {
	const history = useHistory();
	//state data
	const [isDashboard, setIsDashboard] = useState(false);

	const [iscurrentState, setIscurrentState] = useState("Tools");

	// Multi Level
	const [isLanding, setIsLanding] = useState(false);

	function updateIconSidebar(e) {
		if (e && e.target && e.target.getAttribute("subitems")) {
			const ul = document.getElementById("two-column-menu");
			const iconItems = ul.querySelectorAll(".nav-icon.active");
			let activeIconItems = [...iconItems];
			activeIconItems.forEach((item) => {
				item.classList.remove("active");
				var id = item.getAttribute("subitems");
				if (document.getElementById(id)) document.getElementById(id).classList.remove("show");
			});
		}
	}

	useEffect(() => {
		document.body.classList.remove("twocolumn-panel");
		if (iscurrentState !== "Dashboard") {
			setIsDashboard(false);
		}
		if (iscurrentState !== "Landing") {
			setIsLanding(false);
		}
	}, [history, iscurrentState, isLanding]);

	const menuItems = [
		{
			label: "Menu",
			isHeader: true,
		},
		{
			id: "email",
			label: "Email",
			icon: "ri-rocket-line",
			link: "/#",
			stateVariables: isLanding,
			click: function (e) {
				e.preventDefault();
				setIsLanding(!isLanding);
				setIscurrentState("Landing");
				updateIconSidebar(e);
			},
			subItems: [
				{ id: "onePage", label: "One Page", link: "/configuration/list-email", parentId: "landing" },
				// { id: "nftLanding", label: "NFT Landing", link: "/nft-landing", parentId: "landing" },
			],
		},
	];
	return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;

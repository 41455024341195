import io from "socket.io-client";
// Secret key used for JWT signing
const host = process.env.REACT_APP_SOCKET_HOST_ERP;

const utilSocketClientERP = () => {
	const socket = io(`${host}`, {
		// query: {
		// 	secret: secretKey,
		// 	user_id: JSON.parse(localStorage.getItem("authUser")).data.userId,
		// }, // Send the token as a query parameter
		transports: ["websocket"],
		upgrade: false,
	});

	return socket;
};

export default utilSocketClientERP;

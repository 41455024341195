import React, { useEffect, useMemo, useRef, useState } from "react";
import { Formik } from "formik";
import { Button, Col, Modal, ModalBody, Row } from "reactstrap";
import FormSubmit from "../../../Components/Common/FormSubmit";
import { Link } from "react-router-dom";
import InputComponent from "../../../Components/Hooks/InputComponent";
import SimpleBar from "simplebar-react";
import SelectComponent from "../../../Components/Hooks/SelectComponent";
import DeleteModal from "../../../Components/Common/DeleteModal";
import { useDispatch } from "react-redux";
import * as yup from "yup";

import {
	listEmailsClearTestEmailConfig,
	listEmailsTestEmailConfig,
	listMailsAddConfigEmail,
	listMailsClearActionConfigEmail,
	listMailsDeleteConfigEmail,
	listMailsGetListConfigEmail,
	listMailsUpdateConfigEmail,
} from "../../../store/actions";
import { useSelector } from "react-redux";
import { numberOnly } from "../../../Components/Hooks/ValidateComponent";
import utilSocketClientERP from "../../../utils/utilSocketClientERP";

const ModalConfigEmail = ({ isOpen = false, toggle = () => {}, responseSocket = null, setResponseSocket }) => {
	const dispatch = useDispatch();
	const [isTesting, setIsTesting] = useState(false);
	const { dataListConfigEmail, dataDefinitions, isDeleteConfigEmailSuccess, isAddConfigEmailSuccess, isUpdateConfigEmailSuccess, resultTestEmail } =
		useSelector((state) => state.DashboardListMails);

	const [listEmailConfig, setListEmailConfig] = useState([]);
	const [dataConfigById, setDataConfigById] = useState(null);
	const [openOutgoingServer, setOpenOutgoingServer] = useState(true);
	const [openModalDelete, setOpenDeleteModal] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [optionEmailTLS, setOptionEmailTLS] = useState({
		id: "",
		code: "None",
		value: "None",
	});
	const formikRef = useRef();

	useEffect(() => {
		if (Array.isArray(dataListConfigEmail)) {
			setListEmailConfig(dataListConfigEmail);
		}
	}, [dataListConfigEmail]);

	// useEffect(() => {
	// 	const isOpenOutgoingServer = dataConfigById?.emailHostSend || dataConfigById?.emailSendPort || dataConfigById?.emailTLS;
	// 	setOpenOutgoingServer(isOpenOutgoingServer);
	// }, [dataConfigById]);

	useEffect(() => {
		if (!openOutgoingServer) {
			formikRef.current?.setFieldValue("emailHostSend", "");
			formikRef.current?.setFieldValue("emailSendPort", "");
			formikRef.current?.setFieldValue("emailTLS", false);
			setOptionEmailTLS({
				id: "",
				code: "None",
				value: "None",
			});
		}
	}, [openOutgoingServer]);

	useEffect(() => {
		if (isAddConfigEmailSuccess || isDeleteConfigEmailSuccess || isUpdateConfigEmailSuccess) {
			if ((isAddConfigEmailSuccess, isDeleteConfigEmailSuccess)) {
				setDataConfigById(dataListConfigEmail?.[0] ?? null);
			} else {
				setDataConfigById((prev) => {
					const prevId = prev?.id;
					if (!prev?.id) return prev;
					return dataListConfigEmail?.find((i) => i.id === prevId);
				});
			}
			dispatch(listMailsClearActionConfigEmail());
			dispatch(listEmailsClearTestEmailConfig());
		}
	}, [isAddConfigEmailSuccess, isDeleteConfigEmailSuccess, isUpdateConfigEmailSuccess, dataListConfigEmail]);

	useEffect(() => {
		if (isUpdateConfigEmailSuccess) {
			dispatch(listMailsClearActionConfigEmail());
		}
	}, [isUpdateConfigEmailSuccess, dataListConfigEmail]);

	useEffect(() => {
		dispatch(listMailsGetListConfigEmail());
	}, [dispatch]);

	const validateSchema = yup.object({
		emailAddress: yup.string("Email is required").email("Please enter email format").required("Email is required"),
		emailAccount: yup.string("Account is required").email("Please enter email format").required("Account is required"),
		emailPass: dataConfigById?.id ? yup.string() : yup.string().required("Password is required"),
		emailHost: yup.string().required("Host is required"),
		emailReadPort: yup.string().required("Port is required"),
		emailSendPort: openOutgoingServer ? yup.string().required("Outgoing Port is required") : yup.string(),
		emailHostSend: openOutgoingServer ? yup.string().required("Outgoing Host is required") : yup.string(),
	});

	const submitForm = (values) => {
		let data = {
			emailAddress: values?.emailAddress ?? "",
			emailAccount: values?.emailAccount ?? "",
			emailPass: values?.emailPass ?? "",
			emailHost: values?.emailHost ?? "",
			emailReadPort: openOutgoingServer ? Number(values?.emailReadPort ?? 0) : "",
			emailSendPort: openOutgoingServer ? Number(values?.emailSendPort ?? 0) : "",
			emailHostSend: openOutgoingServer ? values?.emailHostSend ?? "" : "",
			emailTLS: values?.emailTLS,
		};
		// if (openOutgoingServer) {
		// 	data.emailSendPort = Number(values?.emailSendPort ?? 0);
		// 	data.emailHostSend = values?.emailHostSend ?? "";
		// }

		if (dataConfigById?.id) {
			let dataUpdate = { ...data, id: dataConfigById?.id };
			if (!dataUpdate.emailPass) {
				delete dataUpdate.emailPass;
			}
			dispatch(listMailsUpdateConfigEmail(dataUpdate));
		} else {
			dispatch(listMailsAddConfigEmail(data));
		}
	};

	const initialValues = {
		id: dataConfigById?.id ?? "",
		emailAddress: dataConfigById?.emailAddress ?? "",
		emailAccount: dataConfigById?.emailAccount ?? "",
		emailPass: dataConfigById?.emailPass ?? "",
		emailHost: dataConfigById?.emailHost ?? "",
		emailReadPort: dataConfigById?.emailReadPort ?? "",
		emailSendPort: dataConfigById?.emailSendPort ?? "",
		emailHostSend: dataConfigById?.emailHostSend ?? "",
		emailTLS: dataConfigById?.emailTLS ?? false,
	};

	const handleReset = () => {
		setDataConfigById(null);
		dispatch(listMailsClearActionConfigEmail());
		dispatch(listEmailsClearTestEmailConfig());
		setOpenOutgoingServer(true);
		setShowPassword(false);
		setOptionEmailTLS({
			id: "",
			code: "None",
			value: "None",
		});

		toggle();
	};

	const sumEmailTotal = () => {
		if (dataConfigById?.emailAccount === responseSocket?.user && dataConfigById?.id) return responseSocket?.count ?? 0;
		if (dataConfigById?.id) return dataConfigById?.emailTotal ?? 0;
		return listEmailConfig?.reduce((prev, item) => {
			prev = prev + Number(item?.emailTotal ?? 0);
			return prev;
		}, 0);
	};

	useEffect(() => {
		if (resultTestEmail) {
			setIsTesting(false);
		}
	}, [resultTestEmail]);

	return (
		<>
			<DeleteModal
				show={openModalDelete}
				onDeleteClick={() => {
					if (dataConfigById?.id) {
						dispatch(listMailsDeleteConfigEmail(dataConfigById?.id));
						setDataConfigById(null);
					}
					setOpenDeleteModal(false);
				}}
				onCloseClick={() => {
					setOpenDeleteModal(false);
				}}
			/>
			<Modal
				isOpen={isOpen}
				toggle={() => {
					handleReset();
				}}
				centered
				className="modal-lg-2">
				<Formik enableReinitialize initialValues={initialValues} validationSchema={validateSchema} onSubmit={submitForm} innerRef={formikRef}>
					{(formik) => {
						const {
							values,
							handleChange,
							setFieldValue,
							handleSubmit,
							errors,
							touched,
							handleBlur,
							isValid,
							dirty,
							validateForm,
							setFieldError,
							setFieldTouched,
						} = formik;
						return (
							<FormSubmit onSubmit={handleSubmit}>
								<ModalBody className="p-0">
									<Row className="d-flex align-items-center border-bottom border-1 fs-16 m-0">
										<Col xs={3} className="pe-0">
											Configure Email
										</Col>
										<Col xs={9} className="d-flex align-items-center justify-content-between border-start border-1">
											{/* {dataQuickFilter?.id ? "Edit filter" : "Create new filter"} */}
											Setting Email
											<Link to="#" onClick={handleReset}>
												<i style={{ color: "#adadad" }} className="ri-close-line fs-2"></i>
											</Link>
										</Col>
									</Row>
									<Row className="m-0">
										<Col xs={3} className="pe-0 ps-0">
											<div
												onClick={() => setDataConfigById(null)}
												style={{ backgroundColor: "#98FFCF" }}
												className="table-row-hover border-bottom border-1 ">
												<div className="d-flex align-items-center p-2">
													<i className="ri-add-line me-1"></i>Add Email
												</div>
											</div>
											<SimpleBar style={{ height: openOutgoingServer ? "60vh" : "40vh" }}>
												{listEmailConfig?.map((value, index) => (
													<div
														style={{ backgroundColor: dataConfigById?.id === value?.id && value?.id ? "#d9d9d9" : "" }}
														onClick={() => {
															setDataConfigById(value);
															setResponseSocket(null);
															dispatch(listEmailsClearTestEmailConfig());
														}}
														className="table-row-hover border-bottom border-1"
														key={index}>
														<div className="p-2">{value?.emailAccount}</div>
													</div>
												))}
											</SimpleBar>

											<div
												className="p-2"
												style={{
													background: "#B6EFFF",
												}}>
												Total: {sumEmailTotal()}
											</div>
										</Col>
										<Col xs={9} className="border-start border-1 pb-3 ps-0 pe-0  d-flex flex-column justify-content-center ">
											<div className="py-2 border-bottom border-1 w-100 d-flex mx-auto align-items-center">
												<div className="w-25 text-end me-3">Email Address:</div>
												<div className="w-75 me-4">
													<InputComponent
														id={"emailAddress"}
														type="search"
														name="emailAddress"
														className="form-control"
														placeholder="Enter Email Address"
														value={values.emailAddress}
														onChange={(value) => setFieldValue("emailAddress", value)}
														onBlur={() => {
															handleBlur({ target: { name: "emailAddress" } });
														}}
														invalid={touched.emailAddress && errors.emailAddress ? true : false}
														error={errors?.emailAddress}
														englishValue
													/>
												</div>
											</div>

											{/* //ResponseSocket */}
											<div className="py-2 border-bottom border-1 w-100 d-flex mx-auto align-items-center">
												<div className="w-25 text-end me-3">Host:</div>
												<div className="w-75 me-4">
													<InputComponent
														id={"emailHost"}
														type="search"
														name="emailHost"
														className="form-control"
														placeholder="Enter Host"
														value={values.emailHost}
														onChange={(value) => {
															setFieldValue("emailHost", value);
															setFieldValue("emailHostSend", value);
														}}
														onBlur={() => {
															handleBlur({ target: { name: "emailHost" } });
														}}
														invalid={touched.emailHost && errors.emailHost ? true : false}
														error={errors?.emailHost}
														englishValue
													/>
												</div>
											</div>
											<div className="py-2 border-bottom border-1 w-100 d-flex mx-auto align-items-center">
												<div className="w-25 text-end me-3">Port:</div>
												<div className="w-75 me-4">
													<InputComponent
														id={"emailReadPort"}
														type="search"
														name="emailReadPort"
														className="form-control"
														placeholder="Enter Port"
														value={values.emailReadPort}
														onChange={(value) => {
															const number = numberOnly(value);
															setFieldValue("emailReadPort", number);
														}}
														onBlur={() => {
															handleBlur({ target: { name: "emailReadPort" } });
														}}
														invalid={touched.emailReadPort && errors.emailReadPort ? true : false}
														error={errors?.emailReadPort}
														englishValue
													/>
												</div>
											</div>
											<div className="py-2 border-bottom border-1 w-100 d-flex mx-auto align-items-center">
												<div className="w-25 text-end me-3">User name:</div>
												<div className="w-75 me-4">
													<InputComponent
														id={"emailAccount"}
														type="search"
														name="emailAccount"
														className="form-control"
														placeholder="Enter Username"
														value={values.emailAccount}
														onChange={(value) => setFieldValue("emailAccount", value)}
														onBlur={() => {
															handleBlur({ target: { name: "emailAccount" } });
														}}
														invalid={touched.emailAccount && errors.emailAccount ? true : false}
														error={errors?.emailAccount}
														englishValue
													/>
												</div>
											</div>
											<div className="py-2 border-bottom border-1 w-100 d-flex mx-auto align-items-center">
												<div className="w-25 text-end me-3">Password:</div>
												<div className="w-75 me-4">
													<InputComponent
														id={"emailPass"}
														type={showPassword ? "text" : "password"}
														name="emailPass"
														className="form-control"
														placeholder={dataConfigById?.id ? "Enter change Password" : "Enter Password"}
														value={values.emailPass}
														onChange={(value) => setFieldValue("emailPass", value)}
														onBlur={() => {
															handleBlur({ target: { name: "emailPass" } });
														}}
														invalid={touched.emailPass && errors.emailPass ? true : false}
														error={errors?.emailPass}
														englishValue
														otherComponent={
															<button
																className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
																type="button"
																id="password-addon"
																onClick={() => setShowPassword((prev) => !prev)}>
																{values.emailPass != "" ? (
																	values.showPassword ? (
																		<i className="ri-eye-fill align-middle"></i>
																	) : (
																		<i className="ri-eye-fill align-middle"></i>
																	)
																) : null}
															</button>
														}
													/>
												</div>
											</div>
											<div className="py-2 border-1 w-100 d-flex mx-auto align-items-center">
												<div className="w-25 text-end me-3">Outgoing server:</div>
												<div className="w-75 me-4">
													<InputComponent
														type="checkbox"
														name="notSeen"
														checked={openOutgoingServer}
														onChange={(checked) => {
															setOpenOutgoingServer(checked);
														}}
														englishValue
													/>
												</div>
											</div>
											{openOutgoingServer && (
												<>
													<div className="py-2 border-bottom border-1 w-100 d-flex mx-auto align-items-center">
														<div className="w-25 text-end me-3">Host:</div>
														<div className="w-75 me-4">
															<div className="cursor-pointer">
																<InputComponent
																	id={"emailHostSend"}
																	type="search"
																	name="emailHostSend"
																	placeholder="Outgoing host"
																	value={values.emailHostSend}
																	onChange={(value) => {
																		setFieldValue("emailHostSend", value);
																	}}
																	onBlur={() => {
																		handleBlur({ target: { name: "emailHostSend" } });
																	}}
																	englishValue
																	invalid={touched.emailHostSend && errors.emailHostSend ? true : false}
																	error={errors?.emailHostSend}
																/>
															</div>
														</div>
													</div>
													<div className="py-2 border-bottom border-1 w-100 d-flex mx-auto align-items-center">
														<div className="w-25 text-end me-3">Port:</div>
														<div className="w-75 me-4">
															<div className="cursor-pointer">
																<InputComponent
																	id={"emailSendPort"}
																	type="search"
																	name="emailSendPort"
																	placeholder="Outgoing Port"
																	value={values.emailSendPort}
																	onChange={(value) => {
																		const number = numberOnly(value);
																		setFieldValue("emailSendPort", number);
																	}}
																	onBlur={() => {
																		handleBlur({ target: { name: "emailSendPort" } });
																	}}
																	englishValue
																	invalid={touched.emailSendPort && errors.emailSendPort ? true : false}
																	error={errors?.emailSendPort}
																/>
															</div>
														</div>
													</div>

													<div className="py-2 border-1 w-100 d-flex mx-auto align-items-center">
														<div className="w-25 text-end me-3">Connection Security:</div>
														<div className="w-75 me-4">
															<div className="cursor-pointer">
																<SelectComponent
																	options={[
																		{
																			id: "",
																			code: "None",
																			value: "None",
																		},
																		{
																			id: "SSL/TLS",
																			code: "SSL/TLS",
																			value: "SSL/TLS",
																		},
																	]}
																	name="assigned"
																	onChange={(option) => {
																		setOptionEmailTLS(option);
																		setFieldValue("emailTLS", Boolean(option?.id));
																	}}
																	value={optionEmailTLS}
																	fieldValue="id"
																	// setInputValue={setInputValueSearchAssign}
																	// inputValue={inputValueSearchAssign}
																	display="value"
																/>
															</div>
														</div>
													</div>
												</>
											)}

											<div className="py-2 border-1 w-100 d-flex px-4 align-items-center justify-content-between">
												<div>
													{isTesting && !resultTestEmail && (
														<span className="text-secondary d-flex align-items-center">
															Connecting <i className="ms-1 bx bx-loader-circle bx-spin bx-rotate-90"></i>
														</span>
													)}
													{resultTestEmail && resultTestEmail?.isSuccess && <span className="text-success">Connected successfully</span>}{" "}
													{resultTestEmail && !resultTestEmail?.isSuccess && <span className="text-danger">Connected fail</span>}
												</div>

												<Button
													disabled={isTesting}
													size="sm"
													// className="px-3"
													type="button"
													color="light"
													onClick={async () => {
														dispatch(listEmailsClearTestEmailConfig());
														if (!values.emailAccount) {
															await setFieldError("emailAccount", `Email is required`);
															await setFieldTouched("emailAccount", true);
														}
														if (!values.emailHost) {
															await setFieldError("emailHost", `Host is required`);
															await setFieldTouched("emailHost", true);
														}
														if (!values.emailPass && dataConfigById?.id) {
															await setFieldError("emailPass", `Password is required`);
															await setFieldTouched("emailPass", true);
														}
														if (!values.emailReadPort) {
															await setFieldError("emailReadPort", `Port is required`);
															await setFieldTouched("emailReadPort", true);
														}

														if (Object.keys(errors ?? {})?.length > 0) return;

														const dataTest = {
															emailAccount: values.emailAccount,
															emailHost: values.emailHost,
															emailPass: values.emailPass,
															emailReadPort: Number(values.emailReadPort),
														};
														await dispatch(listEmailsTestEmailConfig(dataTest));
														setIsTesting(true);
													}}>
													Test
												</Button>
											</div>
											<div className="mt-3 w-100 mx-auto d-flex align-items-center justify-content-between">
												<Button
													disabled={!dataConfigById?.id}
													className="btn-danger mx-3"
													onClick={() => {
														setOpenDeleteModal(true);
													}}>
													Delete
												</Button>
												<div className="me-4">
													<Button
														className="btn-soft-info mx-3"
														onClick={() => {
															handleReset();
														}}>
														Cancel
													</Button>

													<Button
														disabled={!dirty}
														type="button"
														color="success"
														onClick={() => {
															handleSubmit(values);
														}}>
														Save
													</Button>
												</div>
											</div>
										</Col>
									</Row>
								</ModalBody>
							</FormSubmit>
						);
					}}
				</Formik>
			</Modal>
		</>
	);
};

export default ModalConfigEmail;

import PropTypes from "prop-types";
import React from "react";
import { Modal, ModalBody } from "reactstrap";

const DeleteModal = ({
	show,
	onDeleteClick,
	onCloseClick,
	src = "https://cdn.lordicon.com/gsqxdxog.json",
	messageWarning = "Are you sure you want to remove this record ?",
	messageAccepted = "Yes, Delete It!",
	buttonOptional = {
		title: "",
		onClick: () => {},
	},
	icon,
	viewButtonClose = true,
	messageConfirm = "Are you sure ?",
	anotherComponent = null,
}) => {
	return (
		<Modal isOpen={show} toggle={onCloseClick} centered={true}>
			<ModalBody className="p-3">
				<div className="mt-2 text-center">
					{icon ? (
						icon
					) : (
						<lord-icon src={src} trigger="loop" colors="primary:#405189,secondary:#f06548" style={{ width: "100px", height: "100px" }}></lord-icon>
					)}
					<div className="mt-4 pt-2 fs-15 mx-4 mx-sm-3">
						<h4>{messageConfirm}</h4>
						<p className="text-muted mx-4 mb-0">{messageWarning}</p>
					</div>
				</div>
				{anotherComponent ? anotherComponent : null}
				<div className="d-flex gap-2 justify-content-center mt-4 mb-2">
					{viewButtonClose && (
						<button type="button" className="btn w-sm btn-light" data-bs-dismiss="modal" onClick={onCloseClick}>
							Close
						</button>
					)}

					{typeof onDeleteClick != "undefined" && (
						<button type="button" className="btn w-sm btn-danger " id="delete-record" onClick={onDeleteClick}>
							{messageAccepted}
						</button>
					)}

					{buttonOptional?.title && (
						<button type="button" className="btn w-sm btn-success " id="delete-record" onClick={buttonOptional.onClick}>
							{buttonOptional.title}
						</button>
					)}
				</div>
			</ModalBody>
		</Modal>
	);
};

DeleteModal.propTypes = {
	onCloseClick: PropTypes.func,
	onDeleteClick: PropTypes.func,
	show: PropTypes.any,
};

export default DeleteModal;

import {
	LIST_MAILS_GET_LIST_MAILS,
	LIST_MAILS_API_RESPONSE_SUCCESS,
	LIST_MAILS_API_RESPONSE_ERROR,
	LIST_MAILS_GET_LOAD_FOLDER,
	LIST_MAILS_GET_LOAD_LABEL,
	LIST_MAILS_GET_DEFINITIONS,
	LIST_MAILS_GET_MAIL_BY_ID,
	LIST_MAILS_CLEAR_CACHE,
	LIST_MAILS_SYNC_DATA,
	LIST_MAILS_ADD_MARK_SUCCESS,
	LIST_MAILS_ADD_MARK_FAIL,
	LIST_MAILS_REMOVE_MARK_SUCCESS,
	LIST_MAILS_REMOVE_MARK_FAIL,
	LIST_MAILS_ADD_LABEL_SUCCESS,
	LIST_MAILS_ADD_LABEL_FAIL,
	LIST_MAILS_REMOVE_LABEL_SUCCESS,
	LIST_MAILS_REMOVE_LABEL_FAIL,
	LIST_MAILS_CLEAR_CACHE_INFO,
	LIST_MAILS_GET_LIST_QUICK_FILTER,
	LIST_MAILS_UPDATE_QUICK_FILTER_SUCCESS,
	LIST_MAILS_UPDATE_QUICK_FILTER_FAIL,
	LIST_MAILS_DELETE_QUICK_FILTER_SUCCESS,
	LIST_MAILS_DELETE_QUICK_FILTER_FAIL,
	LIST_MAILS_ADD_QUICK_FILTER_SUCCESS,
	LIST_MAILS_ADD_QUICK_FILTER_FAIL,
	LIST_MAILS_CLEAR_CACHE_ACTION,
	LIST_MAILS_GET_LIST_USERS,
	LIST_MAILS_SEND_MAIL_SUCCESS,
	LIST_MAILS_SEND_MAIL_FAIL,
	LIST_MAILS_ASSIGN_TASK_SUCCESS,
	LIST_MAILS_GET_LIST_ACCOUNTS,
	LIST_MAILS_DELETE_EMAIL_CONFIG_SUCCESS,
	LIST_MAILS_GET_LIST_EMAIL_CONFIG,
	LIST_MAILS_GET_LIST_USER_CONFIG_ASSIGN,
	LIST_MAILS_GET_LIST_TEAM_CONFIG_ASSIGN,
	LIST_MAILS_GET_LIST_CONFIG_ASSIGN,
	LIST_MAILS_DELETE_CONFIG_ASSIGN_SUCCESS,
	LIST_MAILS_UPDATE_EMAIL_CONFIG_SUCCESS,
	LIST_MAILS_ADD_CONFIG_EMAIL_SUCCESS,
	LIST_MAILS_CLEAR_ACTION_CONFIG_EMAIL,
	LIST_MAILS_ADD_CONFIG_ASSIGN_SUCCESS,
	LIST_MAILS_UPDATE_CONFIG_ASSIGN_SUCCESS,
	LIST_MAILS_CLEAR_ACTION_CONFIG_ASSIGN,
	LIST_MAILS_TEST_EMAIL_CONFIG_SUCCESS,
	LIST_MAILS_TEST_EMAIL_CONFIG_FAIL,
	LIST_MAILS_CLEAR_TEST_EMAIL_CONFIG,
} from "./actionType";

const INIT_STATE = {
	error: {},
	loadingPage: false,
	dataListMails: [],
	totalListMails: 0,
	dataEmailByID: {},
	dataListFolder: [],
	dataListLabel: [],
	dataDefinitions: {},
	isSyncSuccess: false,
	dataListQuickFilter: [],
	dataListUsers: [],
	loadingQuickFilter: false,
	isFetchDetailEmailByID: false,
	dataListAccounts: [],
	dataListConfigEmail: [],
	dataListUserConfigAssign: [],
	dataListTeamConfigAssign: [],
	resultTestEmail: null,
};

const DashboardListMails = (state = INIT_STATE, action) => {
	switch (action.type) {
		case LIST_MAILS_API_RESPONSE_SUCCESS:
			switch (action.payload.actionType) {
				case LIST_MAILS_GET_LIST_MAILS:
					return {
						...state,
						dataListMails: action.payload.data.data,
						totalListMails: action.payload.data.total,
					};

				case LIST_MAILS_GET_MAIL_BY_ID:
					return {
						...state,
						dataEmailByID: action.payload.data.data,
						dataProductsFromDomain: action.payload.data.productsFromDomain,
						dataProductsInContent: action.payload.data.productsInContent,
						// isFetchDetailEmailByID: false,
					};
				case LIST_MAILS_GET_LOAD_FOLDER:
					return {
						...state,
						dataListFolder: action.payload.data.data,
					};
				case LIST_MAILS_GET_LOAD_LABEL:
					return {
						...state,
						dataListLabel: action.payload.data.data,
					};
				case LIST_MAILS_GET_DEFINITIONS:
					return {
						...state,
						dataDefinitions: action.payload.data.data,
					};
				case LIST_MAILS_SYNC_DATA:
					return {
						...state,
						isSyncSuccess: !state.isSyncSuccess,
						loadingPage: !state.loadingPage,
					};
				case LIST_MAILS_GET_LIST_QUICK_FILTER:
					return {
						...state,
						dataListQuickFilter: action.payload.data.data,
					};
				case LIST_MAILS_GET_LIST_USERS:
					return {
						...state,
						dataListUsers: action.payload.data.data,
					};
				case LIST_MAILS_GET_LIST_ACCOUNTS:
					return {
						...state,
						dataListAccounts: action.payload.data.data, // ??
					};

				case LIST_MAILS_GET_LIST_EMAIL_CONFIG: {
					return {
						...state,
						dataListConfigEmail: action.payload.data.data,
					};
				}
				case LIST_MAILS_GET_LIST_USER_CONFIG_ASSIGN: {
					return {
						...state,
						dataListUserConfigAssign: action.payload.data.data,
					};
				}
				case LIST_MAILS_GET_LIST_TEAM_CONFIG_ASSIGN: {
					return {
						...state,
						dataListTeamConfigAssign: action.payload.data.data,
					};
				}
				case LIST_MAILS_GET_LIST_CONFIG_ASSIGN: {
					return {
						...state,
						dataListConfigAssign: action.payload.data.data,
					};
				}
				default:
					return { ...state };
			}
		case LIST_MAILS_API_RESPONSE_ERROR:
			switch (action.payload.actionType) {
				case LIST_MAILS_GET_LIST_MAILS:
					return {
						...state,
						error: action.payload.error,
					};
				default:
					return { ...state };
			}
		case LIST_MAILS_CLEAR_CACHE:
			return {
				error: {},
				loadingPage: false,
				dataListMails: [],
				totalListMails: 0,
				dataEmailByID: {},
				dataListFolder: [],
				dataListLabel: [],
				dataDefinitions: {},
				dataListQuickFilter: [],
				dataListUsers: [],
				dataListAccounts: [],
			};

		case LIST_MAILS_ADD_CONFIG_EMAIL_SUCCESS: {
			return {
				...state,
				dataListConfigEmail: [action.payload.data, ...state.dataListConfigEmail],
				isAddConfigEmailSuccess: true,
			};
		}
		case LIST_MAILS_ADD_CONFIG_ASSIGN_SUCCESS: {
			return {
				...state,
				dataListConfigAssign: [action.payload.data, ...state.dataListConfigAssign],
				isAddConfigAssignSuccess: true,
			};
		}
		case LIST_MAILS_CLEAR_ACTION_CONFIG_EMAIL: {
			return {
				...state,
				isAddConfigEmailSuccess: false,
				isDeleteConfigEmailSuccess: false,
				isUpdateConfigEmailSuccess: false,
			};
		}

		case LIST_MAILS_CLEAR_ACTION_CONFIG_ASSIGN: {
			return {
				...state,
				isAddConfigAssignSuccess: false,
				isDeleteConfigAssignSuccess: false,
				isUpdateConfigAssignSuccess: false,
			};
		}
		case LIST_MAILS_TEST_EMAIL_CONFIG_SUCCESS: {
			return {
				...state,
				resultTestEmail: action.payload,
			};
		}
		case LIST_MAILS_CLEAR_TEST_EMAIL_CONFIG: {
			return {
				...state,
				resultTestEmail: null,
			};
		}
		case LIST_MAILS_TEST_EMAIL_CONFIG_FAIL: {
			return {
				...state,
				error: "",
			};
		}
		case LIST_MAILS_ADD_MARK_FAIL:
		case LIST_MAILS_REMOVE_MARK_FAIL:
		case LIST_MAILS_ADD_LABEL_FAIL:
		case LIST_MAILS_REMOVE_LABEL_FAIL:
		case LIST_MAILS_SEND_MAIL_FAIL:
			return {
				...state,
				error: action.payload.error,
			};
		case LIST_MAILS_ADD_MARK_SUCCESS:
		case LIST_MAILS_REMOVE_MARK_SUCCESS:
		case LIST_MAILS_REMOVE_LABEL_SUCCESS:
		case LIST_MAILS_SEND_MAIL_SUCCESS:
		case LIST_MAILS_ASSIGN_TASK_SUCCESS:
		case LIST_MAILS_ADD_LABEL_SUCCESS:
			return {
				...state,
				// dataListMails: state.dataListMails?.map((filter) =>
				// 	filter.id === action.payload?.dataAddTags?.id ? { ...filter, tags: action.payload?.dataAddTags?.listAddTags } : filter
				// ),
			};
		case LIST_MAILS_CLEAR_CACHE_INFO:
			return {
				...state,
				dataEmailByID: {},
				dataProductsFromDomain: [],
				dataProductsInContent: [],
			};

		case LIST_MAILS_CLEAR_CACHE_ACTION:
			return {
				...state,
				isAddQuickFilterSuccess: false,
				isAddQuickFilterFalse: true,
				isUpdateQuickFilterSuccess: false,
				isUpdateQuickFilterFalse: true,
				isDeleteQuickFilterSuccess: false,
			};
		case LIST_MAILS_DELETE_EMAIL_CONFIG_SUCCESS:
			return {
				...state,
				dataListConfigEmail: state.dataListConfigEmail?.filter((item) => item.id !== action.payload.id),
				isDeleteConfigEmailSuccess: true,
			};
		case LIST_MAILS_DELETE_CONFIG_ASSIGN_SUCCESS: {
			return {
				...state,
				dataListConfigAssign: state.dataListConfigAssign?.filter((item) => item.id !== action.payload.id),
				isDeleteConfigAssignSuccess: true,
			};
		}
		case LIST_MAILS_ADD_QUICK_FILTER_SUCCESS:
			return {
				...state,
				dataListQuickFilter: [action.payload.data, ...state.dataListQuickFilter],
				isAddQuickFilterSuccess: true,
				isAddQuickFilterFalse: false,
			};
		case LIST_MAILS_ADD_QUICK_FILTER_FAIL:
			return {
				...state,
				error: action.payload.error,
				isAddQuickFilterSuccess: false,
				isAddQuickFilterFalse: true,
				loadingQuickFilter: !state.loadingQuickFilter,
			};
		case LIST_MAILS_UPDATE_QUICK_FILTER_SUCCESS:
			return {
				...state,
				dataListQuickFilter: state.dataListQuickFilter?.map((filter) =>
					filter.id === action.payload?.data?.id
						? { ...filter, ...action.payload?.data, assignedInfo: action.payload?.data.assignedInfo ?? [] }
						: filter
				),
				isUpdateQuickFilterSuccess: true,
				isUpdateQuickFilterFalse: false,
			};
		case LIST_MAILS_UPDATE_EMAIL_CONFIG_SUCCESS: {
			return {
				...state,
				dataListConfigEmail: state.dataListConfigEmail.map((item) => {
					return item.id === action.payload.data.id ? { ...item, ...action.payload.data } : item;
				}),
				isUpdateConfigEmailSuccess: true,
			};
		}
		case LIST_MAILS_UPDATE_CONFIG_ASSIGN_SUCCESS: {
			return {
				...state,
				dataListConfigAssign: state.dataListConfigAssign.map((item) => {
					return item.id === action.payload.data.id ? action.payload.data : item;
				}),
				isUpdateConfigAssignSuccess: true,
			};
		}
		case LIST_MAILS_UPDATE_QUICK_FILTER_FAIL:
			return {
				...state,
				error: action.payload.error,
				isUpdateQuickFilterSuccess: false,
				isUpdateQuickFilterFalse: true,
				loadingQuickFilter: !state.loadingQuickFilter,
			};
		case LIST_MAILS_DELETE_QUICK_FILTER_SUCCESS:
			return {
				...state,
				dataListQuickFilter: state.dataListQuickFilter?.filter((filter) => filter.id !== action.payload?.id),
				isDeleteQuickFilterSuccess: true,
			};

		case LIST_MAILS_DELETE_QUICK_FILTER_FAIL:
			return {
				...state,
				error: action.payload.error,
			};
		default:
			return { ...state };
	}
};

export default DashboardListMails;

export const dateFormatString = "DD/MM/YYYY HH:mm"; // 30/08/2023 08:26
export const dateFormatString1 = "YYYY MMM DD"; // 2023 sep 20
export const dateFormatString2 = "MMMM  Do YYYY, h:mm:ss a"; // 2023 29th 2023, 7:00:22 am
export const dateFormatString3 = "yyyy MMM d"; // 2023 Sep 6
export const dateFormatString4 = "YYYY-MM-DD HH:mm"; // 2023-08-30 08:26
export const dateFormatString5 = "DD/MM/YYYY"; // 30/08/2023
export const dateFormatString6 = "HH:mm a"; // 22:17 pm  or  07:00 am
export const dateFormatString7 = "MMMM Do YYYY"; //September 29th 2023
export const dateFormatString8 = "YYYY-MM-DD HH:mm:ss"; // 2023-08-30 08:26:23
export const dateFormatString9 = "YYYY/MM/DD HH:mm:ss"; // 2023/08/30 08:26:23
export const dateFormatString10 = "YYYY/MM/DD"; // 2023/08/30
export const dateFormatString11 = "MMM DD, YYYY h:mm a"; // Sep 26, 2023 7:00 am
export const dateFormatString12 = "HH:mm"; // 08:26
export const dateFormatString13 = "DD MMM YYYY, HH:mm"; // 20 sep 2023, 08:26
export const dateFormatString14 = "MMM DD, HH:mm"; // sep 20, 08:26
export const dateFormatString15 = "YYYY/MM/DD HH:mm"; // 2023/08/30 08:26
export const dateFormatString16 = "YYYY MMM DD, HH:mm"; // 2023 sep 30, 08:26
export const dateFormatString17 = "DD.MMM"; // 20.sep

import { useEffect, useRef, useState } from "react";

import unidecode from "unidecode";
import { regexEmail } from "./ValidateComponent";

const dataClassName = ["bg-primary", "bg-secondary", "bg-success", "bg-info", "bg-warning", "bg-danger", "bg-dark"];

const InputTagComponent = ({
	valueTag = [],
	setValueTag = () => {},
	placeholder = "Enter to add tag",
	englishValue = true,
	valueUpperCase = false,
	handleSetValueField,
	setBgColor = false,
	style = {},
	styleBox = {},
	styleContainer = {},
	className = "",
	classNameBox = "",
	classNameContainer = "",
	typeValueTag = "text",
}) => {
	const [inputText, setInputText] = useState("");
	const [errorMessage, setErrorMessage] = useState(null);
	const inputRef = useRef();

	const selectionRef = useRef({ start: 0, end: 0 });

	const addTag = (e) => {
		if (e.key === "Enter" && inputText) {
			if (valueTag?.find((item) => item === inputText)) {
				return;
			} else {
				if (typeValueTag === "email") {
					const textValid = regexEmail.test(inputText);
					if (!textValid) {
						setErrorMessage("Please enter email value");
						return;
					}
					errorMessage && setErrorMessage("");
				}
				const tagArr = [...new Set([...valueTag, inputText])];
				setValueTag(tagArr);
				handleSetValueField && handleSetValueField(tagArr);
				setInputText("");
				selectionRef.current = { start: 0, end: 0 };
			}
		}
	};

	// useEffect(() => {
	// 	const { start, end } = selectionRef.current;
	// 	inputRef.current.setSelectionRange(start, end);
	// }, [inputText]);

	const removeTag = (removedTag) => {
		const tagArr = valueTag?.filter((tag) => tag !== removedTag);
		setValueTag(tagArr);
		handleSetValueField && handleSetValueField(tagArr);
	};
	const handleRemoveAll = () => {
		setValueTag([]);
		handleSetValueField && handleSetValueField([]);
	};
	const handleChangeInputText = (e) => {
		const input = e.target;
		const start = input.selectionStart;
		const end = input.selectionEnd;
		selectionRef.current = { start, end };

		const convertedValue = englishValue ? unidecode(input.value) : input.value;
		const upperCaseText = valueUpperCase ? convertedValue.toUpperCase() : convertedValue;
		setInputText(upperCaseText);
	};

	useEffect(() => {
		if (setBgColor) {
			var elements = document.getElementsByClassName("tagCustom");

			// Loop through each element and set the background color
			for (var i = 0; i < elements.length; i++) {
				// elements[i].style.backgroundColor = "blue"; // Set your desired background color here
				elements[i].classList.add(dataClassName[i % dataClassName.length], "text-white");
			}
		}
	}, [valueTag]);

	return (
		<div>
			<div className={`tagCustom-container ${classNameContainer}`} style={styleContainer}>
				<div className={`tagCustom-box ${classNameBox}`} style={styleBox}>
					{valueTag?.map((tag, index) => {
						return (
							<button key={index} className="tagCustom">
								<span className="tagCustom-value">{tag}</span>
								<i className="ri-close-line" onClick={() => removeTag(tag)}></i>
							</button>
						);
					})}

					<input
						className={`tagCustom-input ${className}`}
						placeholder={placeholder}
						value={inputText}
						onChange={handleChangeInputText}
						onKeyDown={addTag}
						ref={inputRef}
						style={style}
					/>
				</div>
				{valueTag?.length > 0 && (
					<svg
						onClick={handleRemoveAll}
						height="20"
						width="20"
						viewBox="0 0 20 20"
						aria-hidden="true"
						focusable="false"
						className="tagCustom-removeAll css-tj5bde-Svg  ri-close-line">
						<path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
					</svg>
				)}
			</div>
			{errorMessage && <span className="text-danger fs-11">{errorMessage}</span>}
		</div>
	);
};

export default InputTagComponent;

import { DASHBOARD_MASTER_DATA_CLEAR_DATA_FILTER, DASHBOARD_MASTER_DATA_FILTER } from "./actionType";

const INIT_STATE = {
	paramsFilter: [],
};

const DashboardDataMasterFilter = (state = INIT_STATE, action) => {
	switch (action.type) {
		case DASHBOARD_MASTER_DATA_FILTER:
			return {
				...state,
				paramsFilter: action.payload,
			};

		case DASHBOARD_MASTER_DATA_CLEAR_DATA_FILTER:
			return {
				...state,
				paramsFilter: null,
			};

		default:
			return state;
	}
};

export default DashboardDataMasterFilter;

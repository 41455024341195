import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Popover, PopoverBody, Button } from "reactstrap";
import AvatarIcon from "../../Components/Common/AvatarIcon";
import InputComponent from "../../Components/Hooks/InputComponent";
import SelectComponent from "../../Components/Hooks/SelectComponent";
import { listMailsGetListUsers } from "../../store/actions";

function PopoverListEmails({
	data = {},
	isOpen = false,
	placement = "right",
	target,
	toggle,
	isAdd = false,
	onAdd = () => {},
	onAssign = () => {},
	onRemove = () => {},
}) {
	const dispatch = useDispatch();
	const { dataListLabel, dataDefinitions, dataListUsers } = useSelector((state) => state.DashboardListMails);
	const [listUsers, setListUsers] = useState([]);
	const [optionUsers, setOptionUsers] = useState([]);
	const [inputValueSearchUsers, setInputValueSearchUsers] = useState("");
	const [listLabels, setListLabels] = useState([]);
	const [optionLabels, setOptionLabels] = useState([]);
	const [inputValueSearchLabels, setInputValueSearchLabels] = useState("");

	const popoverRef = useRef(null);

	//Get data list users
	useEffect(() => {
		if (isOpen && !isAdd) {
			let delayDebounceFn = setTimeout(() => {
				dispatch(listMailsGetListUsers(inputValueSearchUsers));
			}, 300);

			return () => clearTimeout(delayDebounceFn);
		}
	}, [dispatch, inputValueSearchUsers, isOpen]);

	useEffect(() => {
		if (Array.isArray(dataListLabel)) setListLabels(dataListLabel);
	}, [dataListLabel]);

	useEffect(() => {
		if (Array.isArray(dataListUsers))
			setListUsers(() =>
				dataListUsers?.map((i) => ({
					...i,
					icon: <AvatarIcon text={i?.fullName} sizeAvatar="xxs" />,
				}))
			);
	}, [dataListUsers]);

	useEffect(() => {
		if (typeof data?.assignedInfo !== "undefined" && data?.assignedInfo?.length > 0) {
			setOptionUsers(() => data?.assignedInfo?.map((i) => ({ ...i, icon: <AvatarIcon text={i?.fullName} /> })) || []);
		} else setOptionUsers([]);
	}, [data]);

	useEffect(() => {
		if (typeof data?.tags !== "undefined" && data?.tags?.length > 0) {
			const tags = data?.tags?.map((i) => i.name) ?? [];
			setOptionLabels(listLabels?.filter((label) => tags?.includes(label.name)));
		} else setOptionLabels([]);
	}, [data, listLabels]);

	const colorStyles = {
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				color: data.color,
			};
		},
		multiValue: (styles, { data }) => {
			const color = data.color;
			return {
				...styles,
				backgroundColor: color,
			};
		},
		multiValueLabel: (styles, { data }) => ({
			...styles,
			color: "#000000",
		}),
	};

	useEffect(() => {
		if (isOpen) {
			const handleClickOutside = (event) => {
				if (popoverRef.current && !popoverRef.current.contains(event.target) && event.returnValue) {
					if (!isAdd) {
						if (
							data?.assignedInfo?.map((i) => i.id)?.length === optionUsers?.map((user) => user.id).length &&
							data?.assignedInfo?.map((i) => i.id)?.every((item) => optionUsers?.map((user) => user.id).includes(item))
						) {
							toggle();
						} else {
							toggle();
							onAssign({
								data: {
									emailId: data?.id,
									assignedId: optionUsers?.length > 0 ? optionUsers?.map((user) => user.id) : [],
								},
								listAssign: optionUsers,
							});
						}
					} else toggle();
				}
			};

			document.addEventListener("mousedown", handleClickOutside);

			return () => {
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}
	}, [isOpen, popoverRef, data, toggle, optionUsers, onAssign]);

	return (
		<Popover fade={false} placement={placement} isOpen={isOpen} target={target} toggle={toggle} innerRef={popoverRef}>
			<PopoverBody
				style={{
					height: "auto",
					width: "400px",
					backgroundColor: "white",
					boxShadow: "0 5px 15px rgba(30, 32, 37, 0.12)",
				}}>
				{!isAdd ? (
					<div>
						<div style={{ width: "130px" }}>Assign to:</div>
						<div style={{ width: "100%" }}>
							<SelectComponent
								isSearchServer
								options={listUsers}
								name="users"
								onChange={(option) => {
									setOptionUsers(option);
									setInputValueSearchUsers("");
								}}
								value={optionUsers}
								placeholder={"Select users"}
								fieldValue="id"
								setInputValue={setInputValueSearchUsers}
								inputValue={inputValueSearchUsers}
								display="fullName"
								isMulti
								isShowMultiRows
								optionIcon
								displayFieldIcon="icon"
								isClearable={true}
							/>
						</div>
						<div className="mt-1 d-flex justify-content-end">
							<Button style={{ width: "60px" }} className="btn btn-sm me-2" type="button" outline color="danger" onClick={toggle}>
								Cancel
							</Button>
							<Button
								style={{ width: "60px" }}
								disabled={
									data?.assignedInfo?.map((i) => i.id)?.length === optionUsers?.map((user) => user.id).length &&
									data?.assignedInfo?.map((i) => i.id)?.every((item) => optionUsers?.map((user) => user.id).includes(item))
								}
								type="button"
								color="primary"
								className="btn btn-sm"
								onClick={() => {
									onAssign({
										data: {
											emailId: data?.id,
											assignedId: optionUsers?.length > 0 ? optionUsers?.map((user) => user.id) : [],
										},
										listAssign: optionUsers,
									});
									toggle();
								}}>
								Save
							</Button>
						</div>
					</div>
				) : (
					<div>
						<div style={{ width: "130px" }}>Add tags:</div>
						<div style={{ width: "100%" }}>
							<SelectComponent
								options={listLabels}
								name="tags"
								onChange={(option) => {
									setOptionLabels(option);
									setInputValueSearchLabels("");
									if (option?.length > data?.tags?.length) {
										onAdd({
											data: {
												listId: [data?.id],
												tag: option?.length > 0 ? option[option?.length - 1]?.name : "",
											},
											listAddTags: option,
										});
									} else {
										onRemove({
											data: {
												listId: [data?.id],
												tag: data?.tags?.map((i) => i.name)?.filter((tag) => !option?.map((i) => i.name)?.includes(tag))[0],
											},
											listAddTags: option,
										});
									}
								}}
								value={optionLabels}
								placeholder={"Select tags"}
								fieldValue="id"
								setInputValue={setInputValueSearchLabels}
								inputValue={inputValueSearchLabels}
								display="name"
								isMulti
								isShowMultiRows
								colorStyles={colorStyles}
								isClearable={false}
							/>
						</div>
						<div className="mt-1 d-flex justify-content-end">
							<Button style={{ width: "60px" }} className="btn btn-sm me-2" type="button" outline color="primary" onClick={toggle}>
								Close
							</Button>
							{/* <Button
								style={{ width: "60px" }}
								disabled={
									data?.tags?.map((i) => i.name)?.length === optionLabels?.map((label) => label.name).length &&
									data?.tags?.map((i) => i.name)?.every((item) => optionLabels?.map((label) => label.name).includes(item))
								}
								type="button"
								color="primary"
								className="btn btn-sm"
								onClick={() => {
									onAdd({
										data: {
											listId: [data?.id],
											tag: optionLabels?.length > 0 ? optionLabels?.map((label) => label.name) : [],
										},
										listAddTags: optionLabels,
									});
									toggle();
								}}>
								Save
							</Button> */}
						</div>
					</div>
				)}
			</PopoverBody>
		</Popover>
	);
}

export default PopoverListEmails;

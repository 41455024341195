const data = [
	"",
	"/ebaytools",
	"/sell",
	"/buys",
	"/inventory",
	"/logistics",
	"/productdata",
	"/permissions",
	"/crm",
	"/erptools",
	"/log-time",
	"/log-time-management",
	"/permissions/configaccountxero",
	"/logistics/packagemanagement",
	// "/logistics/incomingpackages",
	"/logistics/confirm-shipment",
	"/inventory/snoutstock",
	"/inventory/updatelocation",
	"/buys/wanttobuy",
	"/sell/listinvoice",
	"/erptools/index-by-serial-numbers",
	"/erptools/index-by-warehouse",
	"/ebaytools/customers",
	"/ebaytools/convert-template2",
	"/ebaytools/chats-ebay",
	"/ebaytools/listing-ebay",
	"/ebaytools/offers-management",
	"/permissions/users",
	"/sell/warrantymanagement",
	"/buys/purchaseteam",
	"/buys/vendorinvoice",
	"/buys/returntovendor",
	"/inventory/warehousetransfer",
	"/buys/vendorinvoice",
	"/logistics/packageslips",
	"/permissions/list-users",
	"/permissions/permissiongroup",
	"/crm/contacts",
	"/sell/configuration-terms",
	"/buys/suppliers-pricing",
	"/configuration/list-task",

	"/erptools/monitor-us",
	"/erptools/monitor",
	"/ebaytools/new-items",
	"/ebaytools/convert-template",
	"/sell/listquotes",
	"/buys/purchaseorder",
	"/sell/listorders",
	"/sell/salesteam",
	"/crm/grouppartner",
	"/productdata/products",
	"/productdata/category",
];

const useAuthUser = () => {
	const authUser = JSON.parse(localStorage.getItem("authUser"));
	const pageView = JSON.parse(localStorage.getItem("pageView"));

	const version = localStorage.getItem("version");
	const pathName = window.location.pathname;
	const { arrayMenuNotSearch, arrayPage, arrayPageDetail, arrayPageRoles, arrayPageRolesWH } = pageView ?? {};
	const { orgId, userEmail, userId, userToken, workForHome, firstName, lastName, type } = authUser?.data ?? {};
	const typeUser = type ?? "0";
	const fullName = firstName + " " + (lastName ?? "");
	data.map((item) => {
		arrayMenuNotSearch?.push(item);
	});

	return {
		authUser,
		pageView,
		arrayMenuNotSearch,
		arrayPage,
		arrayPageDetail,
		arrayPageRoles,
		arrayPageRolesWH,
		orgId,
		userEmail,
		userId,
		userToken,
		workForHome,
		pathName,
		firstName,
		lastName,
		fullName,
		version,
		typeUser,
	};
};

export default useAuthUser;

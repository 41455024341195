const isNumeric = (str) => {
	if (typeof str != "string") return false; // we only process strings!
	return (
		!isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
		!isNaN(parseFloat(str))
	); // ...and ensure strings of whitespace fail
};

const checkNumber = (values) => {
	let isNum = false;
	values.forEach((value) => {
		switch (value) {
			case "0":
				isNum = true;
				break;
			case "1":
				isNum = true;
				break;
			case "2":
				isNum = true;
				break;
			case "3":
				isNum = true;
				break;
			case "4":
				isNum = true;
				break;
			case "5":
				isNum = true;
				break;
			case "6":
				isNum = true;
				break;
			case "7":
				isNum = true;
				break;
			case "8":
				isNum = true;
				break;
			case "9":
				isNum = true;
				break;
			default:
				break;
		}
	});
	return isNum;
};

const numberOnly = (value) => {
	let numb = value?.toString()?.match(/[\d|.]+/g);
	if (numb) {
		numb = numb.join("");
		return numb;
	} else return "";
};

const regexSerialNumber = (value, isAdd) => {
	let res = false;
	if (isAdd) res = value?.match(/[a-zA-Z-0-9-]/g);
	else res = value?.match(/[a-zA-Z-0-9\s;\n\t\r,-]/g);
	if (res) {
		res = res.join("");
		return res;
	} else return "";
};

const regexEmail = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;

const removeVietnameseAccent = (str) => {
	str = str.toLowerCase();
	str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
	str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
	str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
	str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
	str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
	str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
	str = str.replace(/đ/g, "d");
	return str;
};

export { isNumeric, checkNumber, numberOnly, regexSerialNumber, regexEmail, removeVietnameseAccent };

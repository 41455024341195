import { DASHBOARD_MASTER_DATA_CLEAR_DATA_FILTER, DASHBOARD_MASTER_DATA_FILTER } from "./actionType";

export const dashboardMasterDataParamsFilter = (data) => ({
	type: DASHBOARD_MASTER_DATA_FILTER,
	payload: data,
});

export const dashboardMasterDataClearParamsFilter = () => ({
	type: DASHBOARD_MASTER_DATA_CLEAR_DATA_FILTER,
});

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { debounce } from "lodash";
import _ from "lodash";
import moment from "moment";
import {
	Col,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Row,
	Dropdown,
	UncontrolledDropdown,
	UncontrolledCollapse,
	ButtonGroup,
	Button,
	Modal,
	ModalBody,
	Spinner,
	Table,
	UncontrolledTooltip,
} from "reactstrap";

import DeleteModal from "../../Components/Common/DeleteModal";
//SimpleBar
import SimpleBar from "simplebar-react";
// Import Images
import { Link, useHistory, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
//redux
import { useSelector, useDispatch } from "react-redux";
import InputComponent from "../../Components/Hooks/InputComponent";
import { dateFormatString13, dateFormatString12, dateFormatString10, dateFormatString1 } from "../../Components/Common/FormatTime";

import {
	listMailsGetListMails,
	// listMailsGetLoadFolder,
	listMailsGetLoadLabel,
	listMailsGetListDefinitions,
	listMailsGetMailByID,
	listMailsSyncData,
	// listMailsMarkImportant,
	listMailsRemoveImportant,
	listMailsAddMark,
	listMailsRemoveMark,
	listMailsAddTag,
	listMailsRemoveTag,
	listMailsClearCacheInfo,
	listMailsClearCache,
	listMailsGetListQuickFilter,
	listMailsClearCacheAction,
	listMailsAddQuickFilter,
	listMailsUpdateQuickFilter,
	listMailsDeleteQuickFilter,
	// listMailsGetListUsers,
} from "../../store/actions";

import InputTagComponent from "../../Components/Hooks/InputTagComponent";
import AccordionComponent from "../../Components/Hooks/AccordionComponent";
import FormSubmit from "../../Components/Common/FormSubmit";
import { Formik } from "formik";
import SelectComponent from "../../Components/Hooks/SelectComponent";
import PopoverListEmails from "./PopoverListEmails";
import useAuthUser from "../../Components/Hooks/AuthUser";
import { api } from "../../config";
import { TooltipValue, removeStyleHtml } from "../../Components/Hooks/Functions";
import utilSocketClientERP from "../../utils/utilSocketClientERP";
import ModalConfigEmail from "./Modal/ModalConfigEmail";
import ModalConfigFilterAssigner from "./Modal/ModalConfigFilterAssigner";
import AvatarIcon from "../../Components/Common/AvatarIcon";

const rowsPerPage = [50, 100, 500];
const EmailToolbar = () => {
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();
	const { userEmail, arrayPageRoles, fullName, userId, typeUser, userName } = useAuthUser();
	const currentUser = useAuthUser();
	const [apiVersion, setApiVersion] = useState("");
	const [isProfileDropdown, setIsProfileDropdown] = useState(false);
	useEffect(() => {
		const fetchApiVersion = async () => {
			try {
				const response = await fetch(api.API_URL + "/api/version");
				const data = await response.json();
				if (typeof data?.data?.name != "undefined") setApiVersion(data?.data?.name);
			} catch (error) {
				console.error("Error fetching API version:", error);
			}
		};

		fetchApiVersion();
	}, [currentUser.pathName]);

	useEffect(() => {
		if (apiVersion != "" && typeof apiVersion != "undefined") caching();
	}, [apiVersion]);

	const caching = () => {
		if (currentUser.version != apiVersion) {
			if ("caches" in window) {
				caches.keys().then((names) => {
					// Delete all the cache files
					names.forEach((name) => {
						caches.delete(name);
					});
				});
				// Makes sure the page reloads. Changes are only visible after you refresh.
				window.location.reload(true);
			}
			localStorage.setItem("version", apiVersion);
		}
	};

	const {
		error,
		dataListMails,
		totalListMails,
		dataDefinitions,
		loadingPage,
		dataListLabel,
		dataEmailByID,
		isSyncSuccess,
		dataListQuickFilter,
		isUpdateQuickFilterSuccess,
		isUpdateQuickFilterFalse,
		isAddQuickFilterSuccess,
		isAddQuickFilterFalse,
		loadingQuickFilter,
		dataListAccounts,
		isDeleteQuickFilterSuccess,
	} = useSelector((state) => state.DashboardListMails);
	// const { searchText, loadingSearchOption } = useSelector((state) => {
	// 	return {
	// 		searchText: state.DashboardSearchOption.searchOption,
	// 		loadingSearchOption: state.DashboardSearchOption.loadingSearchOption,
	// 	};
	// });
	const formikRef = useRef();

	const formikRefSendMail = useRef(null);

	const [isFolderTab, setIsFolderTab] = useState("");
	const [isLabelTab, setIsLabelTab] = useState([]);
	const [isTypeTab, setIsTypeTab] = useState([]);
	const [isSelectedAssign, setIsSelectedAssign] = useState(false);
	const [selectedFilter, setSelectedFilter] = useState({});
	const [selectedConfigFilter, setSelectedConfigFilter] = useState({});
	const [listAccountEmail, setListAccountEmail] = useState([]);
	const [accountEmail, setAccountEmail] = useState("");

	const [openModalConfigEmail, setOpenModalConfigEmail] = useState(false);
	const [openConfigFilterAssigner, setOpenConfigFilterAssigner] = useState(false);

	const [data, setData] = useState([]);
	const [dataRowFilter, setDataRowFilter] = useState("");
	const [deleleModalFilter, setDeleleModalFilter] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [order, setOrder] = useState("date");
	const [sortDirection, setSortDirection] = useState("desc");
	const [perPage, setPerPage] = useState(50);
	const totalPages = Math.ceil(totalRows / perPage);
	const [selectedValues, setSelectedValues] = useState([]);

	const [inputValueSearch, setInputValueSearch] = useState("");
	// const [listFolders, setListFolders] = useState([]);
	const [listLabels, setListLabels] = useState([]);
	const [fromDate, setFromDate] = useState("");
	const [toDate, setToDate] = useState("");

	const [openConfigForAdmin, setOpenConfigForAdmin] = useState(false);

	const [showAllFolder, setShowAllFolder] = useState(false);
	const [showAllLabel, setShowAllLabel] = useState(false);

	const [emailInfo, setEmailInfo] = useState([]);
	const [loadingGetInfo, setLoadingGetInfo] = useState(false);

	const [isSyncing, setIsSyncing] = useState(false);

	const [modalPreviewFile, setModalPreviewFile] = useState(false);
	const [dataPreviewFile, setDataPreviewFile] = useState("");
	const [typeFilePreview, setTypeFilePreview] = useState("pdf");

	const [listQuickFilter, setListQuickFilter] = useState([]);
	const [isOpenQuickFilter, setIsOpenQuickFilter] = useState(false);
	const [dataQuickFilter, setDataQuickFilter] = useState({});
	const [isOpenConfigureFilter, setIsOpenConfigureFilter] = useState(false);
	const [isSubmittingQuickFilter, setIsSubmittingQuickFilter] = useState(false);
	const [isLoadingMails, setIsLoadingMails] = useState(false);
	const [isDisableDownloadAll, setIsDisableDownloadAll] = useState(false);
	const [end, setEnd] = useState(false);
	const [inputValueSearchLabel, setInputValueSearchLabel] = useState("");
	// const [listAssigner, setListAssigner] = useState([]);
	// const [optionAssigner, setOptionAssigner] = useState([]);
	// const [inputValueSearchAssign, setInputValueSearchAssign] = useState("");
	const [inputValueNotShow, setInputValueNotShow] = useState([]);
	const [inputValueShow, setInputValueShow] = useState([]);
	const [isOpenSelectAccount, setIsOpenSelectAccount] = useState(false);

	const [isShowOptionSearch, setIsShowOptionSearch] = useState(false);
	const [listOptionSearch, setListOptionSearch] = useState([]);
	const [optionSearch, setOptionSearch] = useState([]);
	const [notShowOptionSearchFilter, setNotShowOptionSearchFilter] = useState([]);
	const [qOptionSearch, setQOptionSearch] = useState([]);
	const [notShowOptionSearch, setNotShowOptionSearch] = useState([]);

	const [mailResponseSocket, setMailResponseSocket] = useState({});

	const [serverSocket, setServerSocket] = useState(null);
	const [responseSocket, setResponseSocket] = useState(null);

	useEffect(() => {
		setAccountEmail(userEmail);
	}, [userEmail]);

	//dataDefinitions?.SOCKET_EVENT_CLIEN?.JOIN_PROJECT_ROOM)
	//dataDefinitions?.SOCKET_ROOM_NAME?.EMAIL_SYNC?.event
	//dataDefinitions?.SOCKET_ROOM_NAME?.EMAIL_SYNC?.room

	useEffect(() => {
		if (Number(typeUser) === 1) {
			if (dataDefinitions?.SOCKET_ROOM_NAME?.EMAIL_SYNC) {
				setServerSocket(dataDefinitions?.SOCKET_ROOM_NAME?.EMAIL_SYNC);
			}
		}
	}, [dataDefinitions]);

	useEffect(() => {
		if (serverSocket?.event && serverSocket?.room) {
			// console.log(socket);
			// socket.on("connect", () => {
			// 	socket.emit("join-project-room", {
			// 		projectRoom: "room-mail-sync-progress",
			// 	});
			// });
			//on event of server
			// socket.on(//UpdateMailSyncProgress, handleUpdateModalConfigData);
			// return () => {
			// 	socket.disconnect();
			// };
			const socket = utilSocketClientERP();
			socket.on("connect", () => {
				socket.emit(dataDefinitions?.SOCKET_EVENT_CLIENT?.JOIN_PROJECT_ROOM, {
					projectRoom: serverSocket.room,
				});
			});

			socket.on(serverSocket?.event, handleUpdateModalConfigData);
			return () => {
				socket.disconnect();
			};
		}
	}, [serverSocket]);

	// console.log(dataDefinitions?.SOCKET_ROOM_NAME?.EMAIL_SYNC);

	function handleUpdateModalConfigData(res) {
		console.log(res, "res");
		//{"user":"admin@apactech.io","count":100}
		if (res?.user) {
			setResponseSocket(res);
		}
	}

	useEffect(() => {
		if (mailResponseSocket?.id) {
			const mail = data?.find((value) => value.id.toString() === mailResponseSocket.id.toString());
			if (mail?.id) {
				setData((preData) => {
					return preData.map((todo) => {
						if (mailResponseSocket.id.toString() === todo.id.toString()) {
							return mailResponseSocket;
						} else {
							return todo;
						}
					});
				});
			} else {
				setData((pre) => [mailResponseSocket, ...pre]);
			}
			setMailResponseSocket({});
		}
	}, [mailResponseSocket, data]);

	useEffect(() => {
		const optionSearchInitArr = Object.values(dataDefinitions?.OPTION_SEARCH_EMAIL ?? {}) ?? [];
		setListOptionSearch(optionSearchInitArr);
		setOptionSearch(optionSearchInitArr);
	}, [dataDefinitions?.OPTION_SEARCH_EMAIL]);

	useEffect(() => {
		setQOptionSearch(dataQuickFilter?._qoptionSearch ?? []);
	}, [dataQuickFilter]);

	useEffect(() => {
		if (Array.isArray(dataQuickFilter?.notShowOptionSearch)) {
			setNotShowOptionSearch(dataQuickFilter?.notShowOptionSearch ?? []);
		}
	}, [dataQuickFilter]);

	useEffect(() => {
		return function cleanup() {
			// sidebarClose("list-emails-detail-show");
			dispatch(listMailsClearCache());
		};
	}, [dispatch]);

	useEffect(() => {
		if (Array.isArray(dataListAccounts) && dataListAccounts?.length > 0) {
			setListAccountEmail(dataListAccounts);

			setIsFolderTab(dataListAccounts[0]?.folders);
		}
	}, [dataListAccounts]);

	const handleSearch = (resetFilter) => {
		// setIsLoadingMails(true);
		const fromDateGMT = fromDate ? moment(fromDate).format(dateFormatString10) : "";
		const toDateGMT = toDate ? moment(toDate).format(dateFormatString10) : "";
		const timezone = moment().format("Z");
		const dataFilter = resetFilter ? {} : selectedFilter;
		// const dataFilter = { ...selectedFilter }; // Keep all options

		if (dataFilter?._q?.length > 0 || inputValueSearch !== "") setIsShowOptionSearch(true);
		else setIsShowOptionSearch(false);
		// const search = inputValueSearch?.split(",") ?? dataFilter?._q;
		dispatch(
			listMailsGetListMails(
				currentPage,
				perPage,
				"date",
				"desc",
				// search,
				dataFilter?._q ? dataFilter?._q : inputValueSearch !== "" ? inputValueSearch?.split(",") : [],
				accountEmail,
				isFolderTab,
				isLabelTab?.length > 0 ? isLabelTab : "",
				fromDateGMT,
				toDateGMT,
				isTypeTab?.length > 0 ? isTypeTab : "",
				timezone,
				dataFilter?.assigned && dataFilter?.assigned?.length > 0 ? dataFilter?.assigned : isSelectedAssign ? [] : null,
				dataFilter?.notShow ?? [],
				optionSearch,
				notShowOptionSearchFilter
			)
		);
	};

	useEffect(() => {
		if (accountEmail !== "" && listOptionSearch?.length > 0) handleSearch();
	}, [
		currentPage,
		perPage,
		accountEmail,
		isLabelTab,
		isFolderTab,
		fromDate,
		toDate,
		isTypeTab,
		isSelectedAssign,
		loadingPage,
		optionSearch,
		listOptionSearch,
	]);

	useEffect(() => {
		if (Array.isArray(dataListMails)) {
			setData((pre) => [...pre, ...dataListMails]);
		}
		if (typeof totalListMails !== "undefined" && totalListMails !== null) setTotalRows(totalListMails);
		setIsLoadingMails(false);
	}, [dataListMails, totalListMails]);

	//Sort data FE
	const handleSortData = (fieldSort) => {
		let direction = "desc";
		if (fieldSort === order && sortDirection === "desc") direction = "asc";
		setSortDirection(direction);
		setOrder(fieldSort);
		setData((preData) =>
			preData?.sort((a, b) => {
				let keyA = 0,
					keyB = 0;
				switch (fieldSort) {
					case "subject":
						keyA = a.subject.toLowerCase();
						keyB = b.subject.toLowerCase();
						break;
					case "account":
						keyA = a.account.toLowerCase();
						keyB = b.account.toLowerCase();
						break;
					case "correspondent":
						keyA = a?.from?.value
							?.map((value) => value.name || value.address)
							?.join(", ")
							.toLowerCase();
						keyB = b?.from?.value
							?.map((value) => value.name || value.address)
							?.join(", ")
							.toLowerCase();
						break;
					case "tag":
						keyA = b?.tags?.length;
						keyB = a?.tags?.length;
						break;
					case "assign":
						keyA = b?.assignedInfo?.length;
						keyB = a?.assignedInfo?.length;
						break;
					case "star":
						keyA = b?.mark?.includes("Flagged") ? 1 : 0;
						keyB = a?.mark?.includes("Flagged") ? 1 : 0;
						break;
					case "attachment":
						keyA = b?.filenames?.length;
						keyB = a?.filenames?.length;
						break;
					case "unread":
						keyA = !b?.mark?.includes("Seen") ? 1 : 0;
						keyB = !a?.mark?.includes("Seen") ? 1 : 0;
						break;
					default:
						keyA = new Date(b?.date);
						keyB = new Date(a?.date);
						break;
				}
				if (keyA < keyB) return direction === "desc" ? -1 : 1;
				if (keyA > keyB) return direction === "desc" ? 1 : -1;
				return 0;
			})
		);
		document.getElementsByClassName("simplebar-content-wrapper")[0].scrollTop = 0;
	};

	//Get data
	useEffect(() => {
		dispatch(listMailsGetListDefinitions());
		// dispatch(listMailsGetListAccount());
	}, [dispatch]);

	//Get data

	useEffect(() => {
		if (accountEmail !== "") {
			// dispatch(listMailsGetLoadFolder(accountEmail));
			dispatch(listMailsGetLoadLabel(accountEmail));
			dispatch(listMailsGetListQuickFilter(accountEmail));
		}
	}, [dispatch, accountEmail]);

	// useEffect(() => {
	// 	setListFolders(dataListFolder);
	// }, [dataListFolder]);

	useEffect(() => {
		setListLabels(dataListLabel);
	}, [dataListLabel]);

	useEffect(() => {
		setListQuickFilter(dataListQuickFilter);
	}, [dataListQuickFilter]);

	// useEffect(() => {
	// 	dispatch(listMailsGetListUsers(""));
	// }, []);

	// useEffect(() => {
	// 	if (isOpenConfigureFilter) {
	// 		let delayDebounceFn = setTimeout(() => {
	// 			if (inputValueSearchAssign !== "") dispatch(listMailsGetListUsers(inputValueSearchAssign));
	// 		}, 300);

	// 		return () => clearTimeout(delayDebounceFn);
	// 	}
	// }, [dispatch, inputValueSearchAssign]);

	// useEffect(() => {
	// 	setListAssigner(dataListUsers);
	// }, [dataListUsers]);

	useEffect(() => {
		setEmailInfo(dataEmailByID);
	}, [dataEmailByID]);

	useEffect(() => {
		if (emailInfo?.id) {
			//"list-emails-detail-id" //
			document?.getElementById("list-emails-detail-id")?.classList.add("list-emails-detail--active");
			document?.getElementById("list-emails-content-id")?.classList.add("list-emails-detail-show");
		}
	}, [emailInfo]);

	const handleReset = (isNonResetFilter) => {
		// sidebarClose("list-emails-detail-show");
		setData([]);
		setEnd(false);
		setCurrentPage(1);
		setSelectedValues([]);
		if (!isNonResetFilter) {
			setSelectedFilter({});
			setNotShowOptionSearchFilter([]);
		}
		// setOptionAssigner([]);
		dispatch(listMailsClearCacheInfo());
		setIsLoadingMails(true);
		setSortDirection("desc");
		setOrder("date");
	};

	const toggleTab = (label, type) => {
		if (!isLoadingMails) {
			if (Array.isArray(label) && isLabelTab?.length === 0) setIsLabelTab(label);
			else if (label !== null) setIsLabelTab([]);

			if (Array.isArray(type) && type?.length === 0) setIsTypeTab([]);
			else if (!isTypeTab?.includes(type) && !Array.isArray(type)) {
				setIsTypeTab((preData) => [...preData, type]);
			} else setIsTypeTab((preData) => preData?.filter((value) => value !== type));

			handleReset();
		}
	};

	// SideBar Open
	function sidebarOpen(ele, value) {
		const element = document.getElementsByTagName("body")[0];
		if (value) element.classList.add(value);
		ele.closest("li").classList.remove("unread");
	}

	// SideBar Close
	function sidebarClose(value) {
		const element = document.getElementsByTagName("body")[0];
		element.classList.remove(value);
	}

	useEffect(() => {
		// sidebarClose("list-emails-detail-show");
		const element = document.getElementsByTagName("body")[0];
		element.classList.add("list-emails-detail-show");
	}, []);

	const [modal, setModal] = useState(false);

	const toggle = () => {
		if (modal) {
			setModal(false);
		} else {
			setModal(true);
		}
	};

	// delete button toggle
	const onChangeCheckBox = (value) => {
		if (!selectedValues?.includes(value) && !Array.isArray(value)) {
			setSelectedValues((preData) => [...preData, value]);
		} else setSelectedValues((preData) => preData?.filter((selected) => selected !== value));
	};

	// Delete Email
	const removeEmail = () => {
		const element = document.querySelectorAll(".message-list li");
		const ele = document.getElementById("email-topbar-actions");
		const checkall = document.getElementById("checkall");
		element.forEach((element) => {
			if (element.classList.contains("active")) {
				const forId = element.querySelector(".form-check-input").value;
				// dispatch(deleteMail(forId));
			}
			element.classList.remove("active");
			element.querySelector(".form-check-input").checked = false;
		});
		checkall.checked = false;
		ele.style.display = "none";
	};

	const favouriteBtn = (ele) => {
		if (ele.closest("button").classList.contains("active")) {
			ele.closest("button").classList.remove("active");
		} else {
			ele.closest("button").classList.add("active");
		}
	};

	//delete order
	const [deleteModal, setDeleteModal] = useState(false);

	useEffect(() => {
		setIsSyncing(false);
	}, [isSyncSuccess]);

	const togglePreviewFile = useCallback(() => {
		if (modalPreviewFile) {
			setModalPreviewFile(false);
			setTimeout(() => {
				setDataPreviewFile("");
				setTypeFilePreview("");
			}, 100);
		} else {
			setModalPreviewFile(true);
		}
	}, [modalPreviewFile]);

	const handleGetInfoEmail = (target, email) => {
		sidebarOpen(target);
		dispatch(listMailsClearCacheInfo());
		dispatch(listMailsGetMailByID(email?.id));
		document
			.getElementById("btn-dot-" + email.id)
			.closest("button")
			?.classList?.remove("active");
		setLoadingGetInfo(true);
		setTimeout(() => {
			setLoadingGetInfo(false);
		}, 1000);
		// clear table quote
		setValueButtonCopy("");
	};

	const formatBytes = (bytes, decimals = 2) => {
		if (bytes === 0) return "0 Bytes";
		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
	};

	const handleDownloadFile = (file) => {
		const link = document.createElement("a");
		const extend = file?.filename?.split(".")[1];
		link.href = `data:${extend};base64,` + file?.content;
		link.download = file.filename;
		link.click();
	};

	const showIconFile = (filename) => {
		const extend = filename?.split(".")[1];
		if (["jpeg", "png", "jpg", "gif"]?.includes(extend)) {
			return <i className="bx bxs-file-image fs-5 me-1 mt-1"></i>;
		} else if (["zip", "rar", "7z", "sitx", "gz"]?.includes(extend)) {
			return <i className="bx bxs-file-archive fs-5 me-1 mt-1"></i>;
		} else if (["doc", "docx"]?.includes(extend)) {
			return <i className="ri-file-word-2-fill fs-5 me-1 mt-1 text-primary"></i>;
		} else if (["xls", "xlsx", "csv"]?.includes(extend)) {
			return <i className="ri-file-excel-2-fill fs-5 me-1 mt-1 text-success"></i>;
		} else if (["txt"]?.includes(extend)) {
			return <i className="bx bxs-file-txt fs-5 me-1 mt-1"></i>;
		} else if (["pdf"]?.includes(extend)) {
			return <i className="bx bxs-file-pdf fs-5 me-1 mt-1 text-warning"></i>;
		} else return <i className="bx bxs-file fs-5 me-1 mt-1"></i>;
	};

	//Lazy loading
	const LazyLoading = useCallback(
		(ele) => {
			const observerLoading = new IntersectionObserver((entries, observer) => {
				for (let entry of entries) {
					if (entry.isIntersecting) {
						if (data?.length === totalRows && totalRows > 0) {
							setEnd(true);
						} else {
							setCurrentPage(currentPage + 1);
						}
						observer.unobserve(entry.target);
					}
				}
			});
			if (ele[0]) observerLoading.observe(ele[0]);
		},
		[currentPage, totalRows, data]
	);

	useEffect(() => {
		if (!end && data) LazyLoading(document.getElementsByClassName("lazy-loading"));
	}, [data?.length]);

	const toggleConfigureFilter = () => {
		if (isOpenConfigureFilter) {
			setIsOpenConfigureFilter(false);
			setDataQuickFilter({});
			setInputValueNotShow([]);
			// setInputValueSearchAssign("");
			setInputValueSearchLabel("");

			// clear table quote
			setValueButtonCopy("");
		} else {
			setIsOpenConfigureFilter(true);
		}
	};

	useEffect(() => {
		if (dataQuickFilter?.id) {
			// setOptionAssigner(dataQuickFilter?.assignedInfo ?? []);
			setInputValueNotShow(dataQuickFilter?.notShow ?? []);
			setInputValueShow(dataQuickFilter?._q ?? []);
		} else {
			// setOptionAssigner([]);
			setInputValueNotShow([]);
			setInputValueShow([]);
		}
	}, [dataQuickFilter]);

	const initialValuesConfiguration = {
		title: dataQuickFilter?.title ? dataQuickFilter.title : "",
		important: dataQuickFilter?.important ? dataQuickFilter.important : false,
		notSeen: typeof dataQuickFilter?.notSeen !== "undefined" ? dataQuickFilter.notSeen : false,
		label: dataQuickFilter?.label ? dataQuickFilter.label : [],
		hasAttachment: typeof dataQuickFilter?.hasAttachment !== "undefined" ? dataQuickFilter.hasAttachment : false,
		assigned: typeof dataQuickFilter?.assigned !== "undefined" ? dataQuickFilter.assigned : [],
		notShow: typeof dataQuickFilter?.notShow !== "undefined" ? dataQuickFilter.notShow : [],
		_q: typeof dataQuickFilter?._q !== "undefined" ? dataQuickFilter._q : [],
		account: accountEmail,
		_qoptionSearch: typeof dataQuickFilter?._qoptionSearch !== "undefined" ? dataQuickFilter?._qoptionSearch : listOptionSearch,
		notShowOptionSearch: typeof dataQuickFilter?.notShowOptionSearch !== "undefined" ? dataQuickFilter.notShowOptionSearch : [],
	};

	const validateConfiguration = (values) => {
		let errors = {};
		if (values.title === "") errors.title = "Please enter title";
		return errors;
	};

	const submitFormConfiguration = (values) => {
		let body = {
			title: values.title,
			important: values.important,
			notSeen: values.notSeen,
			label: values.label,
			hasAttachment: values.hasAttachment,
			assigned: values.assigned,
			notShow: values.notShow,
			account: values.account,
			_qoptionSearch: values._qoptionSearch,
			notShowOptionSearch: values.notShowOptionSearch ?? [],
			_q: values._q,
		};
		if (dataQuickFilter?.id) {
			body.id = dataQuickFilter.id;
			dispatch(listMailsUpdateQuickFilter(body));
		} else {
			dispatch(listMailsAddQuickFilter(body));
		}
		setIsSubmittingQuickFilter(true);
	};
	useEffect(() => {
		setIsSubmittingQuickFilter(false);
		if ((isAddQuickFilterSuccess && !isAddQuickFilterFalse) || isDeleteQuickFilterSuccess) {
			setDataQuickFilter(dataListQuickFilter[0]);
		}
		if (isUpdateQuickFilterSuccess && !isUpdateQuickFilterFalse) {
			setDataQuickFilter((preData) => dataListQuickFilter?.find((item) => item.id === preData?.id));
		}

		dispatch(listMailsClearCacheAction());
	}, [
		isUpdateQuickFilterSuccess,
		isUpdateQuickFilterFalse,
		isAddQuickFilterSuccess,
		isAddQuickFilterFalse,
		isDeleteQuickFilterSuccess,
		loadingQuickFilter,
	]);

	//////////////////////////////////////////////,
	const [openAccordionFileUpload, setOpenAccordionFileUpload] = useState("1");

	const toggleOpenAccordionFileUpload = (id) => {
		if (openAccordionFileUpload === id) {
			setOpenAccordionFileUpload();
		} else {
			setOpenAccordionFileUpload(id);
		}
	};

	const colorStyles = {
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				color: data.color,
			};
		},
		multiValue: (styles, { data }) => {
			const color = data.color;
			return {
				...styles,
				backgroundColor: color,
			};
		},
		multiValueLabel: (styles, { data }) => ({
			...styles,
			color: "#000000",
		}),
	};

	const [valueButtonCopy, setValueButtonCopy] = useState("");

	const toggleConfigureEmail = () => {
		setOpenModalConfigEmail(false);
	};

	const toggleConfigFilterAssigner = () => {
		setOpenConfigFilterAssigner(false);
	};

	return (
		<>
			<ToastContainer closeButton={false} />
			<DeleteModal
				show={deleteModal}
				onDeleteClick={() => {
					removeEmail();
					setDeleteModal(false);
				}}
				onCloseClick={() => setDeleteModal(false)}
			/>

			{/* Main */}

			<DeleteModal
				show={deleleModalFilter}
				onDeleteClick={() => {
					dispatch(listMailsDeleteQuickFilter(dataQuickFilter?.id));
					setDeleleModalFilter(false);
				}}
				onCloseClick={() => setDeleleModalFilter(false)}
			/>

			<div className="list-emails-wrapper">
				{/**config filter */}
				<div className="d-flex justify-content-between gap-2 p-1 w-100 list-emails-filter-type">
					{/** email config && config filter and assign */}
					<div className="d-flex align-items-center gap-2 p-1">
						{Number(typeUser) === 1 && (
							<Dropdown
								isOpen={openConfigForAdmin}
								onMouseEnter={() => setOpenConfigForAdmin(true)}
								onMouseLeave={() => setOpenConfigForAdmin(false)}
								toggle={() => {}}>
								<DropdownToggle
									id="dropdownMenuButton"
									style={{ backgroundColor: "#e8e8e8", paddingLeft: "8px", paddingRight: "8px" }}
									tag="button"
									// className={`btn btn-sm ${selectedFilter?.id ? "active" : ""}`}>
									className={`btn btn-sm `}>
									<i className="ri-list-settings-line fs-6"></i>
								</DropdownToggle>
								<DropdownMenu className="pb-0 pt-0">
									<DropdownItem divider className="m-0" />
									<DropdownItem
										className="py-2 d-flex align-items-center"
										onClick={() => {
											setOpenModalConfigEmail(true);
										}}>
										<i className="ri-mail-settings-line fs-5 me-1"></i> <span className="fs-6">Email Configure</span>
									</DropdownItem>
									<DropdownItem
										className="pb-2 pt-2 d-flex align-items-center"
										onClick={() => {
											// setIsOpenQuickFilter(false);
											// toggleConfigureFilter();
											// setDataQuickFilter(listQuickFilter[0]);
											setOpenConfigFilterAssigner(true);
										}}>
										<i className="ri-folder-settings-line fs-5  me-1"></i> <span className="fs-6">Configure Filter & Assign </span>
									</DropdownItem>
								</DropdownMenu>
							</Dropdown>
						)}

						{/** quick filter */}
						<Dropdown
							isOpen={isOpenQuickFilter}
							onMouseEnter={() => setIsOpenQuickFilter(true)}
							onMouseLeave={() => setIsOpenQuickFilter(false)}
							toggle={() => {}}>
							<DropdownToggle
								id="dropdownMenuButton"
								style={{ backgroundColor: "#e8e8e8", paddingLeft: "8px", paddingRight: "8px" }}
								tag="button"
								className={`btn btn-sm ${selectedFilter?.id ? "active" : ""}`}>
								<i className="ri-equalizer-fill fs-6"></i>
							</DropdownToggle>
							<DropdownMenu className="pb-0 pt-0">
								<div className="dropdown-header">
									<span className="fs-6">Quick filter</span>
								</div>
								{listQuickFilter?.map((filter, i) => (
									<DropdownItem
										key={i}
										onClick={() => {
											document?.getElementById("list-emails-detail-id")?.classList.remove("list-emails-detail--active");
											document?.getElementById("list-emails-content-id")?.classList.remove("list-emails-detail-show");
											if (selectedFilter?.id !== filter.id) {
												let arrType = [];
												if (filter.notSeen) arrType.push(dataDefinitions?.EMAIL_TAGS?.NOTSEEN);
												if (filter.important) arrType.push(dataDefinitions?.EMAIL_TAGS?.FLAGGED);
												if (filter.hasAttachment) arrType.push(dataDefinitions?.EMAIL_TAGS?.HAS_ATTACHMENT);
												setIsTypeTab(arrType);
												setIsLabelTab(filter?.label ?? []);
												setIsSelectedAssign(filter?.assigned?.length > 0);
												setSelectedFilter(filter);
												setInputValueSearch(filter?._q?.join(", "));
												setOptionSearch(filter?._qoptionSearch ?? []);
												setNotShowOptionSearchFilter(filter?.notShowOptionSearch || []);
											} else {
												setIsTypeTab([]);
												setIsLabelTab([]);
												setSelectedFilter({});
												setIsSelectedAssign(false);
												setInputValueSearch("");
												setOptionSearch(listOptionSearch);
												setNotShowOptionSearchFilter([]);
											}

											handleReset(true);
											// setSelectedFilter({});

											// clear table quote
											setValueButtonCopy("");

											formikRefSendMail.current?.resetForm();
										}}>
										<span className={`${selectedFilter?.id === filter.id ? "text-primary" : ""}`}>{filter.title}</span>
									</DropdownItem>
								))}
								<DropdownItem divider className="m-0" />
								<DropdownItem
									className="pb-2 pt-2 d-flex align-items-center"
									onClick={() => {
										setIsOpenQuickFilter(false);
										toggleConfigureFilter();
										setDataQuickFilter(listQuickFilter?.[0]);
									}}>
									<i className="ri-list-settings-fill fs-5 me-1"></i> <span className="fs-6">Configure</span>
								</DropdownItem>
							</DropdownMenu>
						</Dropdown>

						{/** ??*/}
						{listAccountEmail?.length > 1 && (
							<Dropdown
								isOpen={isOpenSelectAccount}
								onMouseEnter={() => setIsOpenSelectAccount(true)}
								onMouseLeave={() => setIsOpenSelectAccount(false)}
								toggle={() => {}}>
								<DropdownToggle
									id="dropdownMenuButton"
									style={{ backgroundColor: "#e8e8e8", paddingLeft: "8px", paddingRight: "8px" }}
									tag="button"
									className={`btn btn-sm`}>
									<i className="ri-account-box-line fs-6"></i>
								</DropdownToggle>
								<DropdownMenu className="pb-0 pt-0">
									<div className="dropdown-header">
										<span className="fs-6">Select Account</span>
									</div>
									{listAccountEmail?.map((account, i) => (
										<DropdownItem
											key={i}
											onClick={() => {
												if (accountEmail !== account?.emailAccount) {
													setIsFolderTab(account?.folders);
													setIsTypeTab([]);
													setIsLabelTab([]);
													setSelectedFilter({});
													setIsSelectedAssign(false);
													setInputValueSearch("");
													handleReset(true);
													// clear table quote
													setValueButtonCopy("");

													formikRefSendMail.current?.resetForm();
												}
											}}>
											<span className={`${accountEmail === account?.emailAccount ? "text-primary" : ""}`}>{account?.emailAccount}</span>
										</DropdownItem>
									))}
								</DropdownMenu>
							</Dropdown>
						)}
						{/* <button
								style={{ backgroundColor: "#e8e8e8", paddingLeft: "16px", paddingRight: "16px" }}
								type="button"
								className="btn btn-icon btn-sm d-flex align-items-center justify-content-center hover-btn"
								onClick={() => {}}>
								<i className="ri-pushpin-line fs-5"></i>
							</button> */}

						<div style={{ padding: 0 }} className="app-search d-none d-md-block w-100">
							<div style={{ width: "100%", minWidth: "100px" }} className="position-relative input-search-option">
								<InputComponent
									styleInputGroup={{ width: "30vw" }}
									style={{ height: "30px" }}
									type="search"
									className="form-control"
									placeholder={"Filter these messages"}
									id="search-options"
									value={inputValueSearch}
									autoComplete="off"
									onChange={(e) => {
										setInputValueSearch(e);
									}}
									onKeyDown={(key) => {
										if (key.key === "Enter") {
											handleReset();
											handleSearch(true);
										}
									}}
								/>
								<span
									style={{ lineHeight: "30px" }}
									className="mdi mdi-magnify search-widget-icon"
									onClick={() => {
										handleReset();
										handleSearch(true);
									}}></span>
							</div>
						</div>
						<ButtonGroup>
							<button
								type="button"
								className={`btn btn-sm d-flex align-items-center justify-content-center hover-btn ${
									isTypeTab?.includes(dataDefinitions?.EMAIL_TAGS?.NOTSEEN) ? "active" : ""
								}`}
								style={{ backgroundColor: "#e8e8e8", border: "1px solid #ccc", height: "28px" }}
								onClick={() => {
									toggleTab(null, dataDefinitions?.EMAIL_TAGS?.NOTSEEN);
								}}>
								<i className="ri-mail-unread-fill align-bottom d-inline-block"></i>
								<span className="ms-1 d-none d-sm-inline-block">Unread</span>
							</button>
							<button
								type="button"
								className={`btn btn-sm d-flex align-items-center justify-content-center hover-btn ${
									isTypeTab?.includes(dataDefinitions?.EMAIL_TAGS?.FLAGGED) ? "active" : ""
								}`}
								style={{ backgroundColor: "#e8e8e8", border: "1px solid #ccc", height: "28px" }}
								onClick={() => {
									toggleTab(null, dataDefinitions?.EMAIL_TAGS?.FLAGGED);
								}}>
								<i className="ri-star-line align-bottom d-inline-block"></i>
								<span className="ms-1 d-none d-sm-inline-block">Starred</span>
							</button>

							<button
								type="button"
								className={`btn btn-sm d-flex align-items-center justify-content-center hover-btn ${isLabelTab?.length > 0 ? "active" : ""}`}
								style={{ backgroundColor: "#e8e8e8", border: "1px solid #ccc", height: "28px" }}
								onClick={() => {
									toggleTab(
										listLabels?.map((value) => value.name),
										isTypeTab
									);
								}}>
								<i className="bx bxs-purchase-tag bx-rotate-90 align-bottom d-inline-block"></i>
								<span className="ms-1 d-none d-sm-inline-block">Tags</span>
							</button>
							<button
								type="button"
								className={`btn btn-sm d-flex align-items-center justify-content-center hover-btn ${
									isTypeTab?.includes(dataDefinitions?.EMAIL_TAGS?.HAS_ATTACHMENT) ? "active" : ""
								}`}
								style={{ backgroundColor: "#e8e8e8", border: "1px solid #ccc", height: "28px" }}
								onClick={() => {
									toggleTab(null, dataDefinitions?.EMAIL_TAGS?.HAS_ATTACHMENT);
								}}>
								<i className="las la-paperclip align-bottom d-inline-block"></i>
								<span className="ms-1 d-none d-sm-inline-block">Attachment</span>
							</button>
						</ButtonGroup>
					</div>
					<Dropdown
						isOpen={isProfileDropdown}
						onMouseEnter={() => setIsProfileDropdown(true)}
						onMouseLeave={() => setIsProfileDropdown(false)}
						toggle={() => {}}>
						<DropdownToggle tag="button" type="button" className="btn">
							<span className="d-flex align-items-center">
								<AvatarIcon text={fullName} sizeAvatar="xs" />
								<span className="text-start ms-xl-2">
									<span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">Welcome</span>
									<span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{userEmail}</span>
								</span>
							</span>
						</DropdownToggle>
						<DropdownMenu className="dropdown-menu-end">
							<DropdownItem
								onClick={() => {
									history.push("/login");
									localStorage.clear();
								}}>
								<i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
								<span className="align-middle" data-key="t-logout">
									Logout
								</span>
							</DropdownItem>
						</DropdownMenu>
					</Dropdown>

					{/* <div>
						<AvatarIcon text={fullName} sizeAvatar="xs" /> <i className="bx bx-log-in"></i>
					</div> */}
				</div>

				{/* Filter messages by: */}
				<div style={{ marginLeft: "43px" }}>
					{(isShowOptionSearch || selectedFilter?.id) && (
						<div className="d-flex align-items-center mt-1 list-emails-filter-messages-by">
							<span className="me-3">Filter messages by: </span>
							<div className="d-flex gap-2">
								{listOptionSearch?.map((option, index) => (
									<Button
										size="sm"
										className={`btn-option-search text-dark d-flex align-items-center ${optionSearch?.includes(option) ? "include" : ""}`}
										style={{ height: "24px" }}
										key={index}
										onClick={() => {
											if (!isLoadingMails) {
												if (optionSearch?.includes(option)) setOptionSearch((preData) => preData?.filter((value) => value !== option));
												else setOptionSearch((preData) => [...preData, option]);
												handleReset();
												// handleSearch(true);
											}
										}}>
										<b>{option}</b>
									</Button>
								))}
							</div>
						</div>
					)}
				</div>
				{/* Main content */}
				<>
					<SimpleBar id="list-emails-content-id" className={`list-emails-content mx-1 mt-1 border border-1`}>
						{/**header */}
						<div className="d-flex list-emails-sort border-bottom border-1">
							<div
								className="star-title"
								onClick={() => {
									handleSortData("star");
								}}>
								<i className="ri-star-line" />
							</div>
							<div
								className="attachment-title"
								onClick={() => {
									handleSortData("attachment");
								}}>
								<i className="las la-paperclip fs-5"></i>
							</div>
							<div
								className="account-title d-flex align-items-center justify-content-between"
								onClick={() => {
									handleSortData("account");
								}}>
								Account
								{order === "account" && <i className={`ri-arrow-${sortDirection === "desc" ? "down" : "up"}-s-line fs-5 me-2`}></i>}
							</div>
							<div
								className="correspondent-title d-flex align-items-center justify-content-between"
								onClick={() => {
									handleSortData("correspondent");
								}}>
								Correspondents
								{order === "correspondent" && <i className={`ri-arrow-${sortDirection === "desc" ? "down" : "up"}-s-line fs-5 me-2`}></i>}
							</div>
							<div
								className="subject-title d-flex align-items-center justify-content-between"
								onClick={() => {
									handleSortData("subject");
								}}>
								Subject {order === "subject" && <i className={`ri-arrow-${sortDirection === "desc" ? "down" : "up"}-s-line fs-5 me-2`}></i>}
							</div>

							<div
								className="unread-title"
								onClick={() => {
									handleSortData("unread");
								}}>
								<i className="ri-mail-unread-fill align-bottom d-inline-block"></i>
							</div>
							<div
								className="date-title d-flex align-items-center"
								onClick={() => {
									handleSortData("date");
								}}>
								Date {order === "date" && <i className={`ri-arrow-${sortDirection === "desc" ? "down" : "up"}-s-line fs-5 me-2`}></i>}
							</div>
							<div
								className="tags-title d-flex align-items-center justify-content-between"
								onClick={() => {
									handleSortData("tag");
								}}>
								Tag {order === "tag" && <i className={`ri-arrow-${sortDirection === "desc" ? "down" : "up"}-s-line fs-5 me-2`}></i>}
							</div>

							{/* 
							
							
							<div
								className="unread"
								onClick={() => {
									handleSortData("unread");
								}}>
								<i className="ri-mail-unread-fill align-bottom d-inline-block"></i>
							</div>
							<div
								className="date d-flex align-items-center justify-content-between"
								onClick={() => {
									handleSortData("date");
								}}>
								Date {order === "date" && <i className={`ri-arrow-${sortDirection === "desc" ? "down" : "up"}-s-line fs-5 me-2`}></i>}
							</div>
							<div
								className="tags d-flex align-items-center justify-content-between"
								onClick={() => {
									handleSortData("tag");
								}}>
								Tag {order === "tag" && <i className={`ri-arrow-${sortDirection === "desc" ? "down" : "up"}-s-line fs-5 me-2`}></i>}
							</div>
							<div
								className="assign d-flex align-items-center justify-content-between"
								onClick={() => {
									handleSortData("assign");
								}}>
								Assign {order === "assign" && <i className={`ri-arrow-${sortDirection === "desc" ? "down" : "up"}-s-line fs-5 me-2`}></i>}
							</div> */}
						</div>
						{isLoadingMails ? (
							<div style={{ height: "34vh" }} className="d-flex align-items-center justify-content-center">
								<Spinner color="primary"></Spinner>
							</div>
						) : (
							<React.Fragment>
								<ul className="list-emails-message-list" id="mail-list">
									{data?.map((item, key) => (
										<li
											style={{ backgroundColor: item?.id === emailInfo?.id ? "#e0e0e0" : "" }}
											className={!item?.mark?.includes("Seen") ? "unread" : ""}
											key={key}
											id={item.id}>
											<button
												id={"btnImportant" + item?.id}
												type="button"
												// className={(item?.mark?.includes("Flagged") ? "active" : "") + `star-test btn avata-xs pr-0 favourite-btn fs-15`}
												className={`star-content ${item?.mark?.includes("Flagged") && "active"} btn avata-xs pr-0 favourite-btn fs-15`}
												onClick={(e) => {
													if (e.target.closest("button").classList.contains("active")) {
														// dispatch(
														// 	listMailsRemoveImportant({
														// 		listId: [item?.id],
														// 		mark: "Flagged",
														// 	})
														// );
													} else {
														// dispatch(
														// 	listMailsMarkImportant({
														// 		listId: [item?.id],
														// 		mark: "Flagged",
														// 	})
														// );
													}
													favouriteBtn(e.target);
												}}>
												<i className="ri-star-fill" />
											</button>
											<div className="attachment-content">{item?.filenames?.length > 0 && <i className="las la-paperclip fs-5 me-2"></i>}</div>
											<Link
												to="#"
												className="account-content "
												onClick={(e) => {
													handleGetInfoEmail(e.target, item);
												}}>
												<div id={"tooltipTop" + key}>{item?.account}</div>
												<UncontrolledTooltip placement="top" target={"tooltipTop" + key}>
													{item?.account}
												</UncontrolledTooltip>
											</Link>
											<Link
												to="#"
												className="correspondent-content "
												onClick={(e) => {
													handleGetInfoEmail(e.target, item);
												}}>
												{item?.from?.value?.map((value) => value.name || value.address)?.join(", ")}
											</Link>
											<Link
												to="#"
												className="subject-content"
												onClick={(e) => {
													handleGetInfoEmail(e.target, item);
												}}>
												{item.subject} - <span className="teaser">{item.teaser}</span>
											</Link>
											<button
												id={"btn-dot-" + item.id}
												className={`unread-content btn btn-icon dot ${!item?.mark?.includes("Seen") && "active"}`}
												onClick={(ele) => {
													const classList = ele.target?.closest("button")?.classList;
													const rowClassList = document.getElementById(item.id).closest("li")?.classList;
													if (classList?.contains("active")) {
														// dispatch(
														// 	listMailsMarkImportant({
														// 		listId: [item?.id],
														// 		mark: "Seen",
														// 	})
														// );
														classList?.remove("active");
														rowClassList?.remove("unread");
													} else {
														// dispatch(
														// 	listMailsRemoveImportant({
														// 		listId: [item?.id],
														// 		mark: "Seen",
														// 	})
														// );
														classList?.add("active");
														rowClassList?.add("unread");
													}
												}}>
												{item?.mark?.includes("Seen") && <i className="bx bx-circle"></i>}
												{!item?.mark?.includes("Seen") && <i className="ri-checkbox-blank-circle-fill text-primary"></i>}
											</button>
											<div
												className="date-content"
												onClick={(e) => {
													handleGetInfoEmail(e.target, item);
												}}>
												{moment(item.date).format(dateFormatString13)}
											</div>
											<div className="tags-content">
												{item?.tags?.length > 0 && (
													<span style={{ width: "44px" }} className={"badge bg-primary pt-1 pb-1 fs-12 rounded-4"}>
														{item?.tags?.length > 1 ? item?.tags?.length + " tags" : item?.tags?.length + " tag"}
													</span>
												)}
												{/* <Button
													id={"popoverAddTags" + key}
													style={{
														height: "20px",
														width: "20px",
														marginLeft: item?.tags?.length > 0 ? "-4px" : "40px",
														backgroundColor: "#d9d9d9",
													}}
													className="btn btn-icon btn-light rounded-circle"
													onClick={() => {
														const newEmail = [...data];
														newEmail[key].openAddTags = data[key].openAddTags === true ? false : true;
														newEmail[key].openAssign = false;
														setData([...newEmail]);
													}}>
													<i className="ri-price-tag-3-line"></i>
												</Button>
												<PopoverListEmails
													data={item}
													isAdd
													placement="right"
													isOpen={item?.openAddTags}
													target={"popoverAddTags" + key}
													toggle={() => {
														const newEmail = [...data];
														newEmail[key].openAddTags = data[key].openAddTags === true ? false : true;
														setData([...newEmail]);
													}}
													onAdd={(body) => {
														// dispatch(listMailsAddTag(body.data));
														const newEmail = [...data];
														newEmail[key].tags = body.listAddTags;
														setData([...newEmail]);
													}}
													onRemove={(body) => {
														// dispatch(listMailsRemoveTag(body.data));
														const newEmail = [...data];
														newEmail[key].tags = body.listAddTags;
														setData([...newEmail]);
													}}
												/> */}
											</div>
										</li>
									))}
								</ul>
								{!end && totalRows > 0 && <div className="lazy-loading w-100 d-flex justify-content-center text-success mb-1">loading...</div>}
							</React.Fragment>
						)}
					</SimpleBar>
					<div className="text-end my-1">Total: {totalListMails ?? 0}</div>
				</>
				{/* Detail email */}

				<div id="list-emails-detail-id" className="list-emails-detail">
					{loadingGetInfo ? (
						<div style={{ height: "400px" }} className="d-flex align-items-center justify-content-center">
							<Spinner color="primary"></Spinner>
						</div>
					) : (
						<div className="ms-1 pt-1 d-flex flex-column h-100 border border-1">
							{emailInfo?.id ? (
								<div className="pb-1 border-bottom border-bottom-dashed">
									<Row>
										<Col className="col">
											<i
												className="ri-close-line fs-16 list-emails-iconClose"
												onClick={() => {
													document?.getElementById("list-emails-detail-id")?.classList.remove("list-emails-detail--active");
													document?.getElementById("list-emails-content-id")?.classList.remove("list-emails-detail-show");
												}}></i>
											<div className="hstack gap-sm-1 align-items-center flex-wrap email-topbar-link ps-1">
												<UncontrolledDropdown>
													<DropdownToggle tag="button" className="btn btn-ghost-secondary btn-icon btn-sm fs-16">
														<i className="ri-more-2-fill align-bottom"></i>
													</DropdownToggle>
													<DropdownMenu className="dropdown-menu-end">
														<DropdownItem
															onClick={() => {
																const rowClassList = document.getElementById(emailInfo.id).closest("li")?.classList;
																const dotClassList = document.getElementById("btn-dot-" + emailInfo.id).closest("button")?.classList;
																// dispatch(
																// 	listMailsMarkImportant({
																// 		listId: [emailInfo?.id],
																// 		mark: "Seen",
																// 	})
																// );
																rowClassList?.remove("unread");
																dotClassList?.remove("active");
															}}>
															Mark as Read
														</DropdownItem>
														<DropdownItem
															onClick={() => {
																const rowClassList = document.getElementById(emailInfo.id).closest("li")?.classList;
																const dotClassList = document.getElementById("btn-dot-" + emailInfo.id).closest("button")?.classList;
																// dispatch(
																// 	listMailsRemoveImportant({
																// 		listId: [emailInfo?.id],
																// 		mark: "Seen",
																// 	})
																// );
																dotClassList?.add("active");
																rowClassList?.add("unread");
															}}>
															Mark as Unread
														</DropdownItem>
													</DropdownMenu>
												</UncontrolledDropdown>
												<button
													type="button"
													className={(emailInfo?.mark?.includes("Flagged") ? "active" : "") + ` btn avatar-xs p-0 favourite-btn fs-15`}
													onClick={(e) => {
														if (e.target.closest("button").classList.contains("active")) {
															// dispatch(
															// 	listMailsRemoveImportant({
															// 		listId: [emailInfo?.id],
															// 		mark: "Flagged",
															// 	})
															// );
														} else {
															// dispatch(
															// 	listMailsMarkImportant({
															// 		listId: [emailInfo?.id],
															// 		mark: "Flagged",
															// 	})
															// );
														}
														favouriteBtn(document.getElementById("btnImportant" + emailInfo?.id));
														favouriteBtn(e.target);
													}}>
													<i className="ri-star-fill" />
												</button>
												{/* <button className="btn btn-ghost-secondary btn-icon btn-sm fs-16">
													<i className="ri-printer-fill align-bottom"></i>
												</button>
												<button className="btn btn-ghost-secondary btn-icon btn-sm fs-16">
													<i className="ri-delete-bin-5-fill align-bottom"></i>
												</button> */}
											</div>
										</Col>
										{/* <Col className="col-auto">
											<div className="pe-1">
												<button
													type="button"
													className="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"
													onClick={() => {
														sidebarClose("list-emails-detail-show");
														// setEmailInfo({});
														dispatch(listMailsClearCacheInfo());
													}}>
													<i className="ri-close-fill align-bottom"></i>
												</button>
											</div>
										</Col> */}
									</Row>
								</div>
							) : (
								<div style={{ height: "38px" }}></div>
							)}
							<SimpleBar className="list-emails-detail-content-scroll">
								<div className="mt-4 mb-3 px-1">
									<h5 className="fw-bold email-subject-title">{emailInfo?.subject}</h5>
								</div>
								<div className="accordion accordion-flush">
									{emailInfo?.emailContents?.map((content, index) => {
										let dataContent = "";
										let isHtml = true;
										if (dataContent === "") {
											if (typeof content.html !== "undefined") {
												if (content.html) {
													dataContent = content.html;
												}
											}
										}
										if (dataContent === "") {
											if (typeof content.textAsHtml !== "undefined") {
												if (content.textAsHtml) {
													dataContent = content.textAsHtml;
												}
											}
										}
										if (dataContent === "") {
											if (typeof content.text !== "undefined") {
												if (content.text) {
													dataContent = content.text;
													isHtml = false;
												}
											}
										}

										return (
											<div key={index}>
												<div className="accordion-item border-dashed right ">
													<div className="accordion-header border-dashed" style={{ border: 0, borderBottom: "1px" }}>
														<a
															href="/#"
															role="button"
															className="btn w-100 text-start px-1 bg-transparent shadow-none collapsed"
															data-bs-toggle="collapse"
															id="email-collapseTwo"
															aria-expanded="true"
															aria-controls="email-collapseTwo">
															<div className="d-flex align-items-center text-muted">
																<div className="d-flex align-items-center justify-content-center avatar-xs text-uppercase border rounded-circle bg-light text-primary fs-6 me-3">
																	{emailInfo?.from?.value
																		?.map((value) => value.name || value.address)
																		?.join(", ")
																		?.charAt(0) +
																		emailInfo?.from?.value
																			?.map((value) => value.name || value.address)
																			?.join(", ")
																			?.split(" ")
																			.slice(-1)
																			.toString()
																			.charAt(0)}
																</div>
																<div className="flex-grow-1 overflow-hidden">
																	<h5 className="fs-14 text-truncate email-user-name-right mb-0">
																		{emailInfo?.from?.value?.map((value) => value.name || value.address)?.join(", ")}
																	</h5>
																	<div className="text-truncate fs-12">from: {emailInfo?.from?.value?.map((value) => value.address)?.join(", ")}</div>
																	<div className="mt-1">
																		{emailInfo?.tags?.map((tags, index) => (
																			<span
																				key={index}
																				className={"badge me-2"}
																				style={{
																					backgroundColor: listLabels?.find((value) => value.name === tags.name)?.color
																						? listLabels?.find((value) => value.name === tags.name)?.color
																						: "#50c3e6",
																				}}>
																				{tags.name}
																			</span>
																		))}
																	</div>
																</div>
																<div className="flex-shrink-0 align-self-start">
																	<div className="text-muted fs-12">{moment(content.date).format(dateFormatString13)}</div>
																</div>
															</div>
														</a>
													</div>

													<UncontrolledCollapse toggler="#email-collapseTwo" defaultOpen>
														<div id={"list-email-selectionText"} style={{ minHeight: "28.54vh" }} className="accordion-body text-body px-1">
															{/* U6-PRO */}
															{/* <textarea ref={textRef} readOnly style={{ display: "none" }} /> */}
															<div>
																{isHtml ? (
																	<div id="contentHTML" dangerouslySetInnerHTML={{ __html: removeStyleHtml(dataContent) }}></div>
																) : (
																	<pre>{dataContent}</pre>
																)}
															</div>
														</div>
													</UncontrolledCollapse>
												</div>

												{content?.attachments?.length > 0 && (
													<div className="list-emails-list-files">
														<AccordionComponent
															classNameHeader={"accordion-padding-1"}
															isAutoOpen={false}
															header={
																<div className="list-files-header">
																	<div className="d-flex align-items-center">
																		<i className="las la-paperclip align-bottom fs-5 d-inline-block me-1"></i>
																		{content?.attachments?.length} {content?.attachments?.length > 1 ? "attachments" : "attachment"}:
																		<span className="ms-1 text-muted">
																			{formatBytes(
																				content?.attachments?.map((attachment) => attachment?.size)?.reduce((pre, current) => pre + current, 0)
																			)}
																		</span>
																	</div>
																	<div
																		onClick={(event) => {
																			event.preventDefault();
																			event.stopPropagation();
																		}}>
																		<ButtonGroup>
																			<Link
																				to="#"
																				className={`btn btn-sm d-flex align-items-center justify-content-center hover-btn ${
																					isDisableDownloadAll ? "isDisable" : ""
																				}`}
																				title="Save all the attached files"
																				onClick={() => {
																					setIsDisableDownloadAll(true);
																					content?.attachments?.forEach((file) => {
																						handleDownloadFile(file);
																					});
																					setTimeout(() => {
																						setIsDisableDownloadAll(false);
																					}, 5000);
																				}}>
																				<i className="ri-download-2-line align-bottom d-inline-block"></i>
																				<span className="ms-1 d-none d-sm-inline-block">Save</span>
																			</Link>
																			<Link
																				to="#"
																				className="btn btn-sm d-flex align-items-center justify-content-center hover-btn border-start border-1">
																				<i className="mdi mdi-chevron-down"></i>
																			</Link>
																		</ButtonGroup>
																	</div>
																</div>
															}
															content={
																<div className="list-files-content gap-1">
																	{content?.attachments?.map((attachment, index) => (
																		<div key={index} className="list-files-details">
																			<div className="d-flex">
																				{showIconFile(attachment?.filename)}
																				<span className="fs-12 d-flex align-items-center">
																					{`${attachment.filename?.slice(0, 12)}...${attachment.filename?.slice(
																						attachment.filename?.length - 7,
																						attachment.filename?.length + 1
																					)}`}
																				</span>
																			</div>
																			<div>
																				<span className="ms-1 text-muted">{formatBytes(attachment?.size)}</span>
																				<Button
																					style={{ width: "20px", height: "20px" }}
																					color="light"
																					className="btn-icon rounded-circle ms-2"
																					type="button"
																					title="Download"
																					onClick={() => {
																						handleDownloadFile(attachment);
																					}}>
																					<i className="ri-download-2-line"></i>
																				</Button>
																			</div>
																		</div>
																	))}
																</div>
															}
														/>
													</div>
												)}
											</div>
										);
									})}
								</div>
							</SimpleBar>
						</div>
					)}
				</div>
			</div>

			{/* Modal Config Filter */}
			{Number(typeUser) === 1 && (
				<ModalConfigEmail
					isOpen={openModalConfigEmail}
					toggle={toggleConfigureEmail}
					responseSocket={responseSocket}
					setResponseSocket={setResponseSocket}
				/>
			)}

			<ModalConfigFilterAssigner isOpen={openConfigFilterAssigner} toggle={toggleConfigFilterAssigner} />
			<Modal isOpen={isOpenConfigureFilter} toggle={toggleConfigureFilter} centered className="modal-lg-2">
				<Formik
					innerRef={formikRef}
					enableReinitialize
					initialValues={initialValuesConfiguration}
					validate={validateConfiguration}
					onSubmit={submitFormConfiguration}>
					{(formik) => {
						const { values, handleChange, setFieldValue, handleSubmit, errors, touched, handleBlur, isValid, dirty, validateForm } = formik;
						return (
							<FormSubmit onSubmit={handleSubmit}>
								<ModalBody className="p-0">
									<Row className="d-flex align-items-center border-bottom border-1 fs-16 m-0">
										<Col xs={3} className="pe-0">
											Quick Filter
										</Col>
										<Col xs={9} className="d-flex align-items-center justify-content-between border-start border-1">
											{dataQuickFilter?.id ? "Edit filter" : "Create new filter"}
											<Link to="#" onClick={() => toggleConfigureFilter()}>
												<i style={{ color: "#adadad" }} className="ri-close-line fs-2"></i>
											</Link>
										</Col>
									</Row>
									<Row className="m-0">
										<Col xs={3} className="pe-0 ps-0">
											<SimpleBar style={{ height: "40vh" }}>
												<div onClick={() => setDataQuickFilter({})} className="table-row-hover border-bottom border-1">
													<div className="d-flex align-items-center p-2">
														<i className="ri-add-line me-1"></i>Create new
													</div>
												</div>
												{listQuickFilter?.map((value, index) => (
													<div
														style={{ backgroundColor: dataQuickFilter?.id === value?.id ? "#d9d9d9" : "" }}
														onClick={() => setDataQuickFilter(value)}
														className="table-row-hover border-bottom border-1"
														key={index}>
														<div className="p-2">{value?.title}</div>
													</div>
												))}
											</SimpleBar>
										</Col>
										<Col xs={9} className="border-start border-1 pb-3 ps-0 pe-0">
											<div className="p-2 border-bottom border-1">
												<InputComponent
													type="search"
													name="title"
													className="form-control"
													placeholder="Enter filter name"
													value={values.title}
													onChange={(value) => setFieldValue("title", value)}
													onBlur={() => {
														handleBlur({ target: { name: "title" } });
													}}
													invalid={touched.title && errors.title ? true : false}
													error={errors?.title}
													englishValue
												/>
											</div>
											<div className="p-2 d-flex align-items-center justify-content-between border-bottom border-1 pe-3">
												<div className="cursor-pointer">
													<InputComponent
														type="checkbox"
														name="notSeen"
														checked={values.notSeen}
														onChange={(value) => {
															let notSeen = true;
															if (values.notSeen === true) notSeen = false;
															else notSeen = true;
															setFieldValue("notSeen", notSeen);
														}}
														onBlur={() => {
															handleBlur({ target: { name: "notSeen" } });
														}}
														englishValue
														otherComponent={
															<span
																role="button"
																className="ms-2"
																onClick={() => {
																	let notSeen = true;
																	if (values.notSeen === true) notSeen = false;
																	else notSeen = true;
																	setFieldValue("notSeen", notSeen);
																}}>
																Unread
															</span>
														}
													/>
												</div>
												<div className="cursor-pointer">
													<InputComponent
														type="checkbox"
														name="important"
														checked={values.important}
														onChange={(value) => {
															let important = true;
															if (values.important === true) important = false;
															else important = true;
															setFieldValue("important", important);
														}}
														onBlur={() => {
															handleBlur({ target: { name: "important" } });
														}}
														englishValue
														otherComponent={
															<span
																role="button"
																className="ms-2"
																onClick={() => {
																	let important = true;
																	if (values.important === true) important = false;
																	else important = true;
																	setFieldValue("important", important);
																}}>
																Starred
															</span>
														}
													/>
												</div>
												<div className="cursor-pointer">
													<InputComponent
														type="checkbox"
														name="hasAttachment"
														checked={values.hasAttachment}
														onChange={(value) => {
															let hasAttachment = true;
															if (values.hasAttachment === true) hasAttachment = false;
															else hasAttachment = true;
															setFieldValue("hasAttachment", hasAttachment);
														}}
														onBlur={() => {
															handleBlur({ target: { name: "hasAttachment" } });
														}}
														englishValue
														otherComponent={
															<span
																role="button"
																className="ms-2"
																onClick={() => {
																	let hasAttachment = true;
																	if (values.hasAttachment === true) hasAttachment = false;
																	else hasAttachment = true;
																	setFieldValue("hasAttachment", hasAttachment);
																}}>
																Attachment
															</span>
														}
													/>
												</div>
											</div>
											{/* <div className="p-2 d-flex align-items-center border-bottom border-1">
												<div className="me-3" style={{ width: "150px" }}>
													Assigner:
												</div>
												<div className="w-100">
													<SelectComponent
														isMulti
														isSearchServer
														options={listAssigner}
														name="assigned"
														onChange={(option) => {
															setOptionAssigner(option);
															setFieldValue("assigned", option?.map((value) => value.id) || []);
															setInputValueSearchAssign("");
														}}
														value={optionAssigner}
														fieldValue="id"
														setInputValue={setInputValueSearchAssign}
														inputValue={inputValueSearchAssign}
														placeholder="Select assigner"
														display="fullName"
													/>
												</div>
											</div> */}
											<div className="p-2 d-flex align-items-center border-bottom border-1">
												<div className="me-3" style={{ width: "150px" }}>
													Tags:
												</div>
												<div className="w-100">
													<SelectComponent
														isMulti
														options={listLabels}
														name="type"
														onChange={(option) => {
															setFieldValue(
																"label",
																option?.map((value) => value.name)
															);
															setInputValueSearchLabel("");
														}}
														value={listLabels?.filter((label) => values.label?.includes(label.name)) ?? []}
														fieldValue="id"
														setInputValue={setInputValueSearchLabel}
														inputValue={inputValueSearchLabel}
														placeholder="Select tag"
														display="name"
														colorStyles={colorStyles}
													/>
												</div>
											</div>
											<div className="p-2 d-flex align-items-center border-bottom border-1">
												<div className="me-3" style={{ width: "150px" }}>
													Keywords:
												</div>
												<div className="w-100">
													<InputTagComponent
														classNameContainer="border-0"
														classNameBox="p-1 border border-1"
														styleBox={{ minHeight: "40px" }}
														styleContainer={{ minHeight: "40px" }}
														style={{ height: "30px" }}
														valueTag={inputValueShow}
														setValueTag={setInputValueShow}
														placeholder="Type and enter to add"
														handleSetValueField={(newTags) => {
															setFieldValue("_q", newTags);
														}}
														englishValue
													/>
												</div>
											</div>
											<div className="p-2 d-flex align-items-center border-bottom border-1">
												<div className="me-3" style={{ width: "150px" }}>
													Keywords Option:
												</div>
												<div className="w-100 d-flex">
													{listOptionSearch?.map((item, index) => (
														<div key={index} className="me-2 cursor-pointer">
															<InputComponent
																type="checkbox"
																name="important"
																checked={qOptionSearch?.some((i) => i === item)}
																onChange={(checked) => {
																	const qOptionSearchClone = [...qOptionSearch];
																	if (checked) {
																		qOptionSearchClone.push(item);
																		setQOptionSearch(qOptionSearchClone);
																		setFieldValue("_qoptionSearch", qOptionSearchClone);
																	} else {
																		setQOptionSearch(qOptionSearchClone?.filter((i) => i !== item));
																		setFieldValue(
																			"_qoptionSearch",
																			qOptionSearchClone?.filter((i) => i !== item)
																		);
																	}
																}}
																englishValue
															/>
															<span
																className="ms-1"
																onClick={() => {
																	const qOptionSearchClone = [...qOptionSearch];
																	if (qOptionSearchClone.some((i) => i === item)) {
																		setQOptionSearch(qOptionSearchClone?.filter((i) => i !== item));
																		setFieldValue(
																			"_qoptionSearch",
																			qOptionSearchClone?.filter((i) => i !== item)
																		);
																	} else {
																		qOptionSearchClone.push(item);
																		setQOptionSearch(qOptionSearchClone);
																		setFieldValue("_qoptionSearch", qOptionSearchClone);
																	}
																}}>
																{item}
															</span>
														</div>
													))}
												</div>
											</div>
											<div className="p-2 d-flex align-items-center border-bottom border-1">
												<div className="me-3" style={{ width: "150px" }}>
													Keywords exclude:
												</div>
												<div className="w-100">
													<InputTagComponent
														classNameContainer="border-0"
														classNameBox="p-1 border border-1"
														styleBox={{ minHeight: "40px" }}
														styleContainer={{ minHeight: "40px" }}
														style={{ height: "30px" }}
														valueTag={inputValueNotShow}
														setValueTag={setInputValueNotShow}
														placeholder="Type and enter to add"
														handleSetValueField={(newTags) => {
															setFieldValue("notShow", newTags);
														}}
														englishValue
													/>
												</div>
											</div>
											<div className="p-2 d-flex align-items-center border-bottom border-1">
												<div className="me-3" style={{ width: "150px" }}>
													Keywords exclude option:
												</div>
												<div className="w-100 d-flex">
													{listOptionSearch?.map((item, index) => (
														<div key={index} className="me-2 cursor-pointer" onClick={() => {}}>
															<InputComponent
																type="checkbox"
																name="KeywordsExcludeOption"
																checked={notShowOptionSearch?.some((i) => i === item)}
																onChange={(checked) => {
																	const notShowOptionSearchClone = [...notShowOptionSearch];
																	if (checked) {
																		notShowOptionSearchClone.push(item);
																		setNotShowOptionSearch(notShowOptionSearchClone);
																		setFieldValue("notShowOptionSearch", notShowOptionSearchClone);
																	} else {
																		setNotShowOptionSearch(notShowOptionSearchClone?.filter((i) => i !== item));
																		setFieldValue(
																			"notShowOptionSearch",
																			notShowOptionSearchClone?.filter((i) => i !== item)
																		);
																	}
																}}
																englishValue
															/>
															<span
																className="ms-1"
																onClick={() => {
																	const notShowOptionSearchClone = [...notShowOptionSearch];
																	if (notShowOptionSearchClone?.some((i) => i === item)) {
																		setNotShowOptionSearch(notShowOptionSearchClone?.filter((i) => i !== item));
																		setFieldValue(
																			"notShowOptionSearch",
																			notShowOptionSearchClone?.filter((i) => i !== item)
																		);
																	} else {
																		notShowOptionSearchClone.push(item);
																		setNotShowOptionSearch(notShowOptionSearchClone);
																		setFieldValue("notShowOptionSearch", notShowOptionSearchClone);
																	}
																}}>
																{item}
															</span>
														</div>
													))}
												</div>
											</div>

											<div className="mt-3 me-3 d-flex align-items-center justify-content-between">
												<Button
													disabled={!dataQuickFilter?.id}
													className="btn-danger mx-3"
													onClick={() => {
														setDeleleModalFilter(true);
													}}>
													Delete
												</Button>
												<div>
													<Button
														className="btn-soft-info mx-3"
														onClick={() => {
															toggleConfigureFilter();
														}}>
														Cancel
													</Button>
													<Button
														disabled={isSubmittingQuickFilter || !dirty}
														type="button"
														color="success"
														onClick={() => {
															handleSubmit(values);
														}}>
														Save
													</Button>
												</div>
											</div>
										</Col>
									</Row>
								</ModalBody>
							</FormSubmit>
						);
					}}
				</Formik>
			</Modal>
		</>
	);
};

export default EmailToolbar;

import React from "react";
import { Container, Col, Row } from "reactstrap";
import ListEmails from "./ListEmails";
// import "./ListEmails.scss";

const ListEmail = () => {
	document.title = "List Mail | IP Supply";
	return (
		<React.Fragment>
			<div className="auth-page-content font-lato">
				<Container fluid>
					<ListEmails />
				</Container>
			</div>
		</React.Fragment>
	);
};

export default ListEmail;

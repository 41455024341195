import {
	SEARCH_VPN_GET_PRODUCT_PRICE_XERO,
	SEARCH_VPN_GET_PRODUCT_PRICE,
	SEARCH_VPN_GET_PRODUCT_PRICE_QUOTE_IN,
	SEARCH_VPN_GET_PRODUCT_ERP,
	SEARCH_VPN_RESPONSE_SUCCESS,
	SEARCH_VPN_RESPONSE_ERROR,
	SEARCH_VPN_REFRESH_LOADING,
	SEARCH_VPN_REFRESH_LOADING_LIST_SN,
	SEARCH_VPN_GET_XERO_INVOICE_BY_NUM,
	GET_XERO_INVOICE_BY_NUM,
	RESET_LIST_XERO_INVOICE_BY_NUM,
	RESET_XERO_INVOICE_BY_NUM,
	SEARCH_VPN_GET_AMOUNT_PRODUCT_ERP_AU,
	SEARCH_VPN_GET_AMOUNT_PRODUCT_ERP_US,
	SEARCH_VPN_GET_FILTER_CONTACT_NAME,
	SEARCH_VPN_GET_FILTER_INVOICE_DATE,
	SEARCH_VPN_GET_LIST_SERIAL_NUMBER_SEARCH_VPN,
	SEARCH_VPN_PRODUCT_ERP_UPDATE_SN_FAIL,
	SEARCH_VPN_PRODUCT_ERP_UPDATE_SN_SUCCESS,
	SEARCH_VPN_PRODUCT_ERP_UPDATE_SN,
	SEARCH_VPN_CLEAR_IS_FETCH_LIST_PRODUCT_ERP,
	SEARCH_VPN_GET_SN_BY_ID,
	SEARCH_VPN_RESET_LOADING_PAGE_LIST_SN,
	SEARCH_VPN_GET_LIST_ORGANIZATION,
	SEARCH_VPN_GET_LIST_POSITION,
	SEARCH_VPN_GET_PARAMS_TABLE_FOOTER_SEARCH,
	SEARCH_VPN_GET_LIST_EMAIL,
	SEARCH_VPN_CLEAR_DATA_EDIT_PRODUCT_ERP,
	SEARCH_VPN_CLEAR_DATA_EMAIL,
	SEARCH_VPN_GET_TEST_LOG_SERIAL_NUMBER_PRODUCT_ERP,
	SEARCH_VPN_GET_LOG_TEXT_SERIAL_NUMBER_PRODUCT_ERP,
	SEARCH_VPN_GET_LOG_TEXT_SERIAL_NUMBER_PRODUCT_ERP_SUCCESS,
	SEARCH_VPN_GET_LOG_TEXT_SERIAL_NUMBER_PRODUCT_ERP_FAIL,
	SEARCH_VPN_RESET_INIT_DATA_LOG_PRODUCT_ERP,
	SEARCH_VPN_GET_HISTORY_PRODUCT_ERP,
	SEARCH_VPN_GET_RECORD_LOGS_PRODUCT_ERP,
	SEARCH_VPN_GET_LIST_WAREHOUSE_PRODUCT_ERP,
	SEARCH_VPN_GET_LIST_CONDITION_PRODUCT_ERP,
	SEARCH_VPN_GET_LIST_MANAGEMENT_PRODUCT_ERP,
	SEARCH_VPN_GET_LIST_STATUS_PRODUCT_ERP,
	SEARCH_VPN_GET_LIST_HASHTAGS_PRODUCT_ERP,
	SEARCH_VPN_GET_LIST_SUPPLIER_PRODUCT_ERP,
	SEARCH_VPN_QUICK_ADD_SUPPLIER_FILTER_PRODUCT_ERP,
	SEARCH_VPN_QUICK_ADD_SUPPLIER_FILTER_PRODUCT_ERP_SUCCESS,
	SEARCH_VPN_QUICK_ADD_SUPPLIER_FILTER_PRODUCT_ERP_FAIL,
	SEARCH_VPN_CLEAR_QUICK_ADD_SUPPLIER_FILTER_PRODUCT_ERP,
	SEARCH_VPN_CLEAR_QUICK_ADD_SUPPLIER_EDIT_PRODUCT_ERP,
	SEARCH_VPN_QUICK_ADD_SUPPLIER_EDIT_PRODUCT_ERP_FAIL,
	SEARCH_VPN_QUICK_ADD_SUPPLIER_EDIT_PRODUCT_ERP_SUCCESS,
	SEARCH_VPN_QUICK_ADD_SUPPLIER_EDIT_PRODUCT_ERP,
	SEARCH_VPN_CLEAR_SEARCH_DATA_PRODUCT_ERP,
	SEARCH_VPN_GET_PRODUCT_MODEL_CODE_FILTER_PRODUCT_ERP,
	SEARCH_VPN_GET_PRODUCT_MODEL_CODE_ID_FILTER_PRODUCT_ERP,
	SEARCH_VPN_GET_ORGCODE_FILTER_PRODUCT_ERP,
	SEARCH_VPN_GET_CONDITION_FILTER_PRODUCT_ERP,
	SEARCH_VPN_GET_CONDITION_CODE_FILTER_PRODUCT_ERP,
	SEARCH_VPN_GET_WAREHOUSE_FILTER_PRODUCT_ERP,
	SEARCH_VPN_GET_LIST_USER_PRODUCT_ERP,
	SEARCH_VPN_GET_LIST_ORGANIZATION_PRODUCT_ERP,
	SEARCH_VPN_GET_LIST_POSITION_PRODUCT_ERP,
	SEARCH_VPN_GET_PRODUCT_PRICE_XERO_QUOTE_IN,
} from "./actionType";

// action creator
export const getProductPriceXeroDataSearchVPN = (vpn, search = true, limit = 50) => ({
	type: SEARCH_VPN_GET_PRODUCT_PRICE_XERO, //Mô tả Hành động này là gì
	payload: { vpn, search, limit }, // tham số hành động (nếu có)
});

export const getProductPriceDataSearchVPN = (vpn, search = true, limit = 50) => ({
	type: SEARCH_VPN_GET_PRODUCT_PRICE,
	payload: { vpn, search, limit }, // tham số hành động (nếu có)
});

// export const getProductPriceDataQuoteInSearchVPN = (vpn, search = true, limit = 50) => ({
// 	type: SEARCH_VPN_GET_PRODUCT_PRICE_QUOTE_IN,
// 	payload: { vpn, search, limit }, // tham số hành động (nếu có)
// });

export const getProductPriceDataQuoteInSearchVPN = (search = "", order = "productCode asc, updatedAt desc", limit = 50) => ({
	type: SEARCH_VPN_GET_PRODUCT_PRICE_QUOTE_IN,
	payload: {
		urlAPI: "/api/wtbquote-result/get-product-price-quote-in",
		filter: {
			limit,
			order,
			where: {
				_q: search,
			},
		},
	},
});

export const getProductPriceDataXeroQuoteInSearchVPN = (search = "", email = "", quoteId = "", order = "date desc", limit = 50) => ({
	type: SEARCH_VPN_GET_PRODUCT_PRICE_XERO_QUOTE_IN,
	payload: {
		urlAPI: "/api/tool-email/get-products-in-quote-and-xero",
		filter: {
			limit,
			order,
			skip: 0,
			where: {
				productModelCode: search,
				email: email,
				quoteId: quoteId,
			},
		},
	},
});

export const getProductDataErpSearchVPN = (vpn, limit = 50, page = 1) => ({
	type: SEARCH_VPN_GET_PRODUCT_ERP,
	payload: { vpn, limit, page }, // tham số hành động (nếu có)
});

// export const getAmountProductErpAuSearchVPN = (vpn, warehouse, limit = 50, page = 1) => ({
// 	type: SEARCH_VPN_GET_AMOUNT_PRODUCT_ERP_AU,
// 	payload: { vpn, warehouse, limit, page }, // tham số hành động (nếu có)
// });
export const getAmountProductErpAuSearchVPN = (vpn, warehouse, limit = 50, page = 1) => ({
	type: SEARCH_VPN_GET_AMOUNT_PRODUCT_ERP_AU,
	payload: {
		urlAPI: "/api/amount-by-products",
		filter: {
			limit: 50,
			skip: 0,
			order: "productCode asc",
			where: {
				q: vpn,
				warehouse,
			},
		},
		host: "ERP_API_URL",
	},
});

export const getAmountProductErpUsSearchVPN = (vpn, warehouse, limit = 50, page = 1) => ({
	type: SEARCH_VPN_GET_AMOUNT_PRODUCT_ERP_US,
	payload: {
		urlAPI: "/api/amount-by-products",
		filter: {
			limit: 50,
			skip: 0,
			order: "productCode asc",
			where: {
				q: vpn,
				warehouse,
			},
		},
		host: "ERP_API_URL",
	},
});

// common success
export const dashboardSearchVPNApiSuccess = (actionType, data) => ({
	type: SEARCH_VPN_RESPONSE_SUCCESS,
	payload: { actionType, data },
});

// common error
export const dashboardSearchVPNApiError = (actionType, error) => ({
	type: SEARCH_VPN_RESPONSE_ERROR,
	payload: { actionType, error },
});

export const resetInitDataSearchVPN = () => ({
	type: SEARCH_VPN_REFRESH_LOADING, //Mô tả Hành động này là gì
});

export const resetDataListSNSearchVPN = () => ({
	type: SEARCH_VPN_REFRESH_LOADING_LIST_SN,
});

// Scan log
export const getTestLogSerialNumberProductErpSearchVPN = (data) => ({
	type: SEARCH_VPN_GET_TEST_LOG_SERIAL_NUMBER_PRODUCT_ERP,
	payload: {
		urlAPI: "/api/test-log-serial-number/get-list",
		filter: {
			where: {
				_q: data,
			},
		},
	},
});

export const getLogTextSNProductErpSearchVPN = (data) => ({
	type: SEARCH_VPN_GET_LOG_TEXT_SERIAL_NUMBER_PRODUCT_ERP,
	payload: data,
});

export const getLogTextSNProductErpSearchVPNSuccess = (data) => ({
	type: SEARCH_VPN_GET_LOG_TEXT_SERIAL_NUMBER_PRODUCT_ERP_SUCCESS,
	payload: data,
});

export const getLogTextSNProductErpSearchVPNFail = (error) => ({
	type: SEARCH_VPN_GET_LOG_TEXT_SERIAL_NUMBER_PRODUCT_ERP_FAIL,
	payload: error,
});

//reset init data log
export const resetInitDataLogTextProductErpSearchVPN = () => ({
	type: SEARCH_VPN_RESET_INIT_DATA_LOG_PRODUCT_ERP,
});

//Detail ERP

// export const getListSNDataSearchVPN = (
// 	search = "",
// 	condition = "[]",
// 	status = "[]",
// 	productModelId = "",
// 	supplierId = "",
// 	orgCode = "[]",
// 	managementKind = "[]",
// 	dateIn = "",
// 	updatedAt = "",
// 	page = 1,
// 	limit = 20,
// 	order = "serialNumberA",
// 	sortdirection = "asc",
// 	updatedById = "",
// 	location = ""
// ) => ({
// 	type: SEARCH_VPN_GET_LIST_SERIAL_NUMBER_SEARCH_VPN,
// 	payload: {
// 		urlAPI: "/api/stock-list-serials",
// 		filter: {
// 			limit: limit,
// 			skip: page * limit - limit,
// 			order: `${order} ${sortdirection}`,
// 			where: {
// 				serialNumberA: search,
// 				condition,
// 				status,
// 				productModelId,
// 				supplierId,
// 				// orgId,
// 				orgCode,
// 				managementKind,
// 				dateIn,
// 				updatedAt,
// 				updatedById,
// 				location,
// 			},
// 		},
// 		host: "ERP_API_URL",
// 	},
// });

export const getListSNDataSearchVPN = (
	page,
	limit,
	order = "serialNumberA",
	sortdirection = "asc",
	search = "",
	productModelId = "",
	supplierId = "",
	condition = [],
	warehouseId = [],
	updatedAt = "",
	status = [],
	dateIn = "",
	managementKind = [],
	hashtag = [],
	orgId = [],
	updatedById = "",
	location = ""
) => ({
	type: SEARCH_VPN_GET_LIST_SERIAL_NUMBER_SEARCH_VPN,
	payload: {
		urlAPI: "/api/stock-model-serial/get-list",
		filter: {
			limit: limit,
			skip: page * limit - limit,
			order: `${order} ${sortdirection}`,
			where: {
				_q: search,
				productModelId,
				supplierId,
				condition,
				warehouseId,
				updatedAt,
				status,
				dateIn,
				managementKind,
				hashtag,
				updatedById,
				location,
			},
		},
		//orgId,
	},
});

//Xero invoice
export const getXeroInvoiceSearchVPN = (sr_invoice_number) => ({
	type: SEARCH_VPN_GET_XERO_INVOICE_BY_NUM,
	payload: { sr_invoice_number },
});

export const getFilterNumOfXeroSearchVPN = (num) => ({
	type: GET_XERO_INVOICE_BY_NUM,
	payload: { num },
});

export const getFilterContactNameSearchVPN = (search) => ({
	type: SEARCH_VPN_GET_FILTER_CONTACT_NAME,
	payload: { search },
});

export const getFilterInvoiceDateSearchVPN = (search) => ({
	type: SEARCH_VPN_GET_FILTER_INVOICE_DATE,
	payload: { search },
});

export const getListEmailSearchVPN = (data, page = 1, limit = 20, order = "updatedAt", sortdirection = "desc") => ({
	type: SEARCH_VPN_GET_LIST_EMAIL,
	payload: {
		urlAPI: "/api/search-in-emails",
		filter: {
			limit: limit,
			skip: page * limit - limit,
			order: `${order} ${sortdirection}`,
			where: {
				_q: data,
			},
		},
		host: "ERP_API_URL",
	},
});

export const resetXeroInvoiceNumSearchVPN = () => ({
	type: RESET_XERO_INVOICE_BY_NUM,
});

export const resetDataListXeorInvoiceSearchVPN = () => ({
	type: RESET_LIST_XERO_INVOICE_BY_NUM,
});

export const updateSNProductErpSearchVPN = (data) => ({
	type: SEARCH_VPN_PRODUCT_ERP_UPDATE_SN,
	payload: {
		urlAPI: "/api/stock-model-serial/data-save",
		data: data,
	},
});

export const updateSNProductErpSearchVPNSuccess = (data) => ({
	type: SEARCH_VPN_PRODUCT_ERP_UPDATE_SN_SUCCESS,
	payload: data,
});

export const updateSNProductErpSearchVPNFail = (error) => ({
	type: SEARCH_VPN_PRODUCT_ERP_UPDATE_SN_FAIL,
	payload: error,
});

export const clearIsFetchListProductERPSearchVPN = () => ({
	type: SEARCH_VPN_CLEAR_IS_FETCH_LIST_PRODUCT_ERP,
});

export const getSNByIDProductErpSearchVPN = (data) => ({
	type: SEARCH_VPN_GET_SN_BY_ID,
	payload: {
		urlAPI: "/api/stock-model-serial/get-info/" + data,
	},
});

//Update

export const resetFetchDetailListSNProductErpSearchVPN = () => {
	return {
		type: SEARCH_VPN_RESET_LOADING_PAGE_LIST_SN,
	};
};

export const getDataListOrganizationProductErpSearchVPN = (search, typeUser = "partner") => ({
	type: SEARCH_VPN_GET_LIST_ORGANIZATION_PRODUCT_ERP,
	payload: { search, typeUser },
});

export const getDataListPositionProductErpSearchVPN = (search, group = "IN_ORG") => ({
	type: SEARCH_VPN_GET_LIST_POSITION_PRODUCT_ERP,
	payload: {
		urlAPI: "/api/position/list-combo-box",
		filter: {
			where: {
				_q: search,
				group: group,
			},
		},
	},
});

export const getParamsTableFooterSearchProductErpSearchVPN = (search) => ({
	type: SEARCH_VPN_GET_PARAMS_TABLE_FOOTER_SEARCH,
	payload: search,
});

export const clearDataEmailSearchVPN = () => ({
	type: SEARCH_VPN_CLEAR_DATA_EMAIL,
});

export const clearDataEditProductErpSearchVPN = () => ({
	type: SEARCH_VPN_CLEAR_DATA_EDIT_PRODUCT_ERP,
});

//get List - filter - add
export const getDataListWarehouseProductErpSearchVPN = () => ({
	type: SEARCH_VPN_GET_LIST_WAREHOUSE_PRODUCT_ERP,
	payload: {
		urlAPI: "/api/warehouse-list-combobox",
		// filter: {
		// 	limit: 10,
		// 	skip: 0,
		// 	order: "updateAt desc",
		// 	where: {
		// 		type: "condition",
		// 	},
		// },
		host: "ERP_API_URL",
	},
});
export const getDataListConditionProductErpSearchVPN = (type) => ({
	type: SEARCH_VPN_GET_LIST_CONDITION_PRODUCT_ERP,
	// payload: { type },
	payload: {
		urlAPI: "/api/dictionary-list-combobox",
		filter: {
			limit: 10,
			skip: 0,
			order: "updateAt desc",
			where: {
				type: "condition",
			},
		},
		host: "ERP_API_URL",
	},
});

export const getDataListManagementProductErpSearchVPN = (type) => ({
	type: SEARCH_VPN_GET_LIST_MANAGEMENT_PRODUCT_ERP,
	// payload: { type },
	payload: {
		urlAPI: "/api/dictionary-list-combobox",
		filter: {
			limit: 10,
			skip: 0,
			order: "updateAt desc",
			where: {
				type: "managementKind",
			},
		},
		host: "ERP_API_URL",
	},
});

export const getDataListStatusProductErpSearchVPN = (type) => ({
	type: SEARCH_VPN_GET_LIST_STATUS_PRODUCT_ERP,
	// payload: { type },
	payload: {
		urlAPI: "/api/dictionary-list-combobox",
		filter: {
			limit: 10,
			skip: 0,
			order: "updateAt desc",
			where: {
				type: "status",
			},
		},
		host: "ERP_API_URL",
	},
});

//USERS
export const getDataListUserErpSearchVPN = (search) => ({
	type: SEARCH_VPN_GET_LIST_USER_PRODUCT_ERP,
	payload: {
		urlAPI: "/api/user/list-combo-box",
		filter: {
			where: {
				type: "staff",
				_q: search,
			},
		},
	},
});

export const getDataListHashTagsProductErpSearchVPN = () => ({
	type: SEARCH_VPN_GET_LIST_HASHTAGS_PRODUCT_ERP,
	payload: {
		urlAPI: "/api/hashtags/list-combo-box",
		filter: {
			limit: 12,
			order: "orders ASC",
			where: { _q: "" },
		},
	},
});

export const getDataListSupplierProductErpSearchVPN = (search) => ({
	type: SEARCH_VPN_GET_LIST_SUPPLIER_PRODUCT_ERP,
	// payload: { search },
	payload: {
		urlAPI: "/api/supplier-list-combobox",
		filter: {
			limit: 10,
			skip: 0,
			order: "updateAt desc",
			where: {
				search: "",
			},
		},
		host: "ERP_API_URL",
	},
});

//Quick add Supplier Filter
export const quickAddSupplierFilterProductErpSearchVPN = (name) => ({
	type: SEARCH_VPN_QUICK_ADD_SUPPLIER_FILTER_PRODUCT_ERP,
	payload: {
		urlAPI: "/api/organization/partner-save",
		data: {
			id: "",
			code: "",
			name: name,
			contactType: "",
			secureCV: "0",
			active: "1",
			currency: "",
			partnerPaymentTermId: "",
			ipsPaymentTermId: "",
			partnerWarrantyTermId: "",
			ipsWarrantyTermId: "",
			contactOwnerId: "",
			notes: {
				content: "",
			},
			contactPersons: [],
			accountPayment: [],
			accountShipping: [],
			accountOther: [
				{
					website: [""],
				},
				{
					ebay: [""],
				},
				{
					amazon: [""],
				},
				{
					uneda: [""],
				},
				{
					brokerbin: [""],
				},
			],
			source: [],
			groupIds: [],
		},
	},
});

export const quickAddSupplierFilterProductErpSearchVPNSuccess = (data) => ({
	type: SEARCH_VPN_QUICK_ADD_SUPPLIER_FILTER_PRODUCT_ERP_SUCCESS,
	payload: data,
});

export const quickAddSupplierFilterProductErpSearchVPNFail = (error) => ({
	type: SEARCH_VPN_QUICK_ADD_SUPPLIER_FILTER_PRODUCT_ERP_FAIL,
	payload: error,
});

export const clearQuickAddSupplierFilterProductErpSearchVPN = () => ({
	type: SEARCH_VPN_CLEAR_QUICK_ADD_SUPPLIER_FILTER_PRODUCT_ERP,
});

//Quick add Supplier Edit
export const quickAddSupplierEditProductErpSearchVPN = (name) => ({
	type: SEARCH_VPN_QUICK_ADD_SUPPLIER_EDIT_PRODUCT_ERP,
	payload: {
		urlAPI: "/api/organization/partner-save",
		data: {
			id: "",
			code: "",
			name: name,
			contactType: "",
			secureCV: "0",
			active: "1",
			currency: "",
			partnerPaymentTermId: "",
			ipsPaymentTermId: "",
			partnerWarrantyTermId: "",
			ipsWarrantyTermId: "",
			contactOwnerId: "",
			notes: {
				content: "",
			},
			contactPersons: [],
			accountPayment: [],
			accountShipping: [],
			accountOther: [
				{
					website: [""],
				},
				{
					ebay: [""],
				},
				{
					amazon: [""],
				},
				{
					uneda: [""],
				},
				{
					brokerbin: [""],
				},
			],
			source: [],
			groupIds: [],
		},
	},
});

export const quickAddSupplierEditProductErpSearchVPNSuccess = (data) => ({
	type: SEARCH_VPN_QUICK_ADD_SUPPLIER_EDIT_PRODUCT_ERP_SUCCESS,
	payload: data,
});

export const quickAddSupplierEditProductErpSearchVPNFail = (error) => ({
	type: SEARCH_VPN_QUICK_ADD_SUPPLIER_EDIT_PRODUCT_ERP_FAIL,
	payload: error,
});

export const clearQuickAddSupplierEditProductErpSearchVPN = () => ({
	type: SEARCH_VPN_CLEAR_QUICK_ADD_SUPPLIER_EDIT_PRODUCT_ERP,
});

export const clearAllSearchDataProductErpSearchVPN = () => ({
	type: SEARCH_VPN_CLEAR_SEARCH_DATA_PRODUCT_ERP,
});

export const getProductModelFilterProductErpSearchVPN = (search) => ({
	type: SEARCH_VPN_GET_PRODUCT_MODEL_CODE_FILTER_PRODUCT_ERP,
	payload: search,
});

//getOrgCode
export const getProductModelIdFilterProductErpSearchVPN = (search) => ({
	type: SEARCH_VPN_GET_PRODUCT_MODEL_CODE_ID_FILTER_PRODUCT_ERP,
	payload: search,
});

export const getProductOrgCodeFilterProductErpSearchVPN = (search) => ({
	type: SEARCH_VPN_GET_ORGCODE_FILTER_PRODUCT_ERP,
	payload: search,
});

export const getConditionCodeFilterProductErpSearchVPN = (search) => ({
	type: SEARCH_VPN_GET_CONDITION_CODE_FILTER_PRODUCT_ERP,
	payload: search,
});

export const getWarehouseFilterProductErpSearchVPN = (search) => ({
	type: SEARCH_VPN_GET_WAREHOUSE_FILTER_PRODUCT_ERP,
	payload: { search },
});

export const getConditionFilterProductErpSearchVPN = (search) => ({
	type: SEARCH_VPN_GET_CONDITION_FILTER_PRODUCT_ERP,
	payload: { search },
});

export const getHistoryProductErpSearchVPN = (id) => ({
	type: SEARCH_VPN_GET_HISTORY_PRODUCT_ERP,
	payload: {
		urlAPI: `/api/stock-model-serial/get-history/${id}`,
	},
});

export const getRecordLogsProductErpSearchVPN = (id) => ({
	type: SEARCH_VPN_GET_RECORD_LOGS_PRODUCT_ERP,
	payload: {
		urlAPI: `/api/stock-model-serial/get-record-log/${id}`,
	},
});

import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
// Crypto Redux States
import {
	GET_LIST_MODEL,
	GET_LIST_SUPPLIER,
	GET_LIST_WAREHOUSE,
	GET_LIST_CONDITION,
	GET_LIST_MANAGEMENT,
	GET_LIST_STATUS,
	GET_MODEL,
	GET_LIST_ORGANIZATION,
	GET_LIST_POSITION,
	MASTER_DATA_QUICK_ADD_SUPPLIER,
	MASTER_DATA_GET_LIST_HASHTAGS,
	MASTER_DATA_CACHE_QUICK_ADD_SUPPLIER_EDIT,
	MASTER_DATA_QUICK_ADD_SUPPLIER_EDIT,
	MASTER_DATA_GET_LIST_PRODUCT_CODE,
	MASTER_DATA_QUICK_ADD_PRODUCT,
	MASTER_DATA_GET_LIST_USERS,
} from "./actionType";
import {
	dashboardMasterDataError,
	dashboardMasterDataSuccess,
	masterDataQuickAddProductFail,
	masterDataQuickAddProductSuccess,
	masterDataQuickAddSupplierEditFail,
	masterDataQuickAddSupplierEditSuccess,
	masterDataQuickAddSupplierFail,
	masterDataQuickAddSupplierSuccess,
	masterDataUpdateSNFail,
	masterDataUpdateSNSuccess,
} from "./action";
import {
	getListDictionary,
	getListModel,
	getListOrganization,
	getListPosition,
	getListSuplier,
	getListWarehouse,
	transferGetData,
	transferPostData,
} from "../../helpers/fakebackend_helper";

function* getDataListModel({ payload: data }) {
	try {
		var response;
		response = yield call(getListModel, data);
		yield put(dashboardMasterDataSuccess(GET_LIST_MODEL, response));
	} catch (error) {
		yield put(dashboardMasterDataError(GET_LIST_MODEL, error));
	}
}
function* getDataModel({ payload: data }) {
	try {
		var response;
		response = yield call(getListModel, data);
		yield put(dashboardMasterDataSuccess(GET_MODEL, response));
	} catch (error) {
		yield put(dashboardMasterDataError(GET_MODEL, error));
	}
}

function* getDataListSuplier({ payload: data }) {
	try {
		var response;
		response = yield call(getListSuplier, data);
		yield put(dashboardMasterDataSuccess(GET_LIST_SUPPLIER, response));
	} catch (error) {
		yield put(dashboardMasterDataError(GET_LIST_SUPPLIER, error));
	}
}

function* getDataListWarehouse() {
	try {
		var response;
		response = yield call(getListWarehouse, []);
		yield put(dashboardMasterDataSuccess(GET_LIST_WAREHOUSE, response));
	} catch (error) {
		yield put(dashboardMasterDataError(GET_LIST_WAREHOUSE, error));
	}
}
function* getDataListCondition({ payload: data }) {
	try {
		var response;
		response = yield call(getListDictionary, data);
		yield put(dashboardMasterDataSuccess(GET_LIST_CONDITION, response));
	} catch (error) {
		yield put(dashboardMasterDataError(GET_LIST_CONDITION, error));
	}
}
function* getDataListManagement({ payload: data }) {
	try {
		var response;
		response = yield call(getListDictionary, data);
		yield put(dashboardMasterDataSuccess(GET_LIST_MANAGEMENT, response));
	} catch (error) {
		yield put(dashboardMasterDataError(GET_LIST_MANAGEMENT, error));
	}
}
function* getDataListStatus({ payload: data }) {
	try {
		var response;
		response = yield call(getListDictionary, data);
		yield put(dashboardMasterDataSuccess(GET_LIST_STATUS, response));
	} catch (error) {
		yield put(dashboardMasterDataError(GET_LIST_STATUS, error));
	}
}

function* getDataListOrganization({ payload: data }) {
	try {
		var response;
		response = yield call(getListOrganization, data);
		yield put(dashboardMasterDataSuccess(GET_LIST_ORGANIZATION, response));
	} catch (error) {
		yield put(dashboardMasterDataError(GET_LIST_ORGANIZATION, error));
	}
}

function* getDataListPosition({ payload: data }) {
	try {
		var response;
		response = yield call(transferGetData, data);
		yield put(dashboardMasterDataSuccess(GET_LIST_POSITION, response));
	} catch (error) {
		yield put(dashboardMasterDataError(GET_LIST_POSITION, error));
	}
}

export function* watchGetListModel() {
	yield takeEvery(GET_LIST_MODEL, getDataListModel);
}
export function* watchGetModel() {
	yield takeEvery(GET_MODEL, getDataModel);
}
export function* watchGetListSupplier() {
	yield takeEvery(GET_LIST_SUPPLIER, getDataListSuplier);
}
export function* watchGetListWarehouse() {
	yield takeEvery(GET_LIST_WAREHOUSE, getDataListWarehouse);
}
export function* watchGetListCondition() {
	yield takeEvery(GET_LIST_CONDITION, getDataListCondition);
}
export function* watchGetListManagement() {
	yield takeEvery(GET_LIST_MANAGEMENT, getDataListManagement);
}
export function* watchGetListStatus() {
	yield takeEvery(GET_LIST_STATUS, getDataListStatus);
}
export function* watchGetListOrganization() {
	yield takeEvery(GET_LIST_ORGANIZATION, getDataListOrganization);
}
export function* watchGetListPosition() {
	yield takeEvery(GET_LIST_POSITION, getDataListPosition);
}

function* masterDataQuickAddSupplier({ payload: data }) {
	try {
		const response = yield call(transferPostData, data);
		if (response.Status === "ERROR") {
			toast.error(response.Msg ? response.Msg : "", { autoClose: 3000 });
			yield put(masterDataQuickAddSupplierFail(response));
		} else if (typeof response.error != "undefined") {
			toast.error(response.error.message, { autoClose: 3000 });
			yield put(masterDataQuickAddSupplierFail(response));
		} else {
			yield put(masterDataQuickAddSupplierSuccess(response));
			toast.success("Add Supplier Successfully", { autoClose: 1000 });
		}
	} catch (error) {
		yield put(masterDataQuickAddSupplierFail(error));
		toast.error("Add Supplier Failed", { autoClose: 3000 });
	}
}

export function* watchMasterDataQuickAddSupplier() {
	yield takeEvery(MASTER_DATA_QUICK_ADD_SUPPLIER, masterDataQuickAddSupplier);
}

//Get List HashTags
function* masterDataGetListHashTags({ payload: data }) {
	try {
		const response = yield call(transferGetData, data);
		yield put(dashboardMasterDataSuccess(MASTER_DATA_GET_LIST_HASHTAGS, response));
	} catch (error) {
		yield put(dashboardMasterDataError(MASTER_DATA_GET_LIST_HASHTAGS, error));
	}
}

export function* watcherMasterDataGetListHashTags() {
	yield takeEvery(MASTER_DATA_GET_LIST_HASHTAGS, masterDataGetListHashTags);
}

function* masterDataQuickAddSupplierEdit({ payload: data }) {
	try {
		const response = yield call(transferPostData, data);
		if (response.Status === "ERROR") {
			toast.error(response.Msg ? response.Msg : "", { autoClose: 3000 });
			yield put(masterDataQuickAddSupplierEditFail(response));
		} else if (typeof response.error != "undefined") {
			toast.error(response.error.message, { autoClose: 3000 });
			yield put(masterDataQuickAddSupplierEditFail(response));
		} else {
			yield put(masterDataQuickAddSupplierEditSuccess(response));
			toast.success("Add Supplier Successfully", { autoClose: 1000 });
		}
	} catch (error) {
		yield put(masterDataQuickAddSupplierEditFail(error));
		toast.error("Add Supplier Failed", { autoClose: 3000 });
	}
}

export function* watchMasterDataQuickAddSupplierEdit() {
	yield takeEvery(MASTER_DATA_QUICK_ADD_SUPPLIER_EDIT, masterDataQuickAddSupplierEdit);
}

//Get List Product
function* masterDataGetListProductCode({ payload: data }) {
	try {
		const response = yield call(transferGetData, data);
		yield put(dashboardMasterDataSuccess(MASTER_DATA_GET_LIST_PRODUCT_CODE, response));
	} catch (error) {
		yield put(dashboardMasterDataError(MASTER_DATA_GET_LIST_PRODUCT_CODE, error));
	}
}

export function* watchMasterDataGetListProductCode() {
	yield takeEvery(MASTER_DATA_GET_LIST_PRODUCT_CODE, masterDataGetListProductCode);
}

function* masterDataQuickAddProduct({ payload: data }) {
	try {
		const response = yield call(transferPostData, data);
		if (response.Status === "ERROR") {
			toast.error(response?.Msg && Array?.isArray(response?.Msg) ? response?.Msg?.join("\n") : response?.Msg ?? "", { autoClose: 3000 });
			yield put(masterDataQuickAddProductFail(response));
		} else if (typeof response.error != "undefined") {
			toast.error(response.error?.message, { autoClose: 3000 });
			yield put(masterDataQuickAddProductFail(response));
		} else {
			yield put(masterDataQuickAddProductSuccess(response));
			toast.success("Add Product Successfully", { autoClose: 1000 });
		}
	} catch (error) {
		yield put(masterDataQuickAddProductFail(error));
		toast.error("Add Product Failed", { autoClose: 3000 });
	}
}

export function* watchMasterDataQuickAddProduct() {
	yield takeEvery(MASTER_DATA_QUICK_ADD_PRODUCT, masterDataQuickAddProduct);
}

//Get List Product
function* masterDataGetListUsers({ payload: data }) {
	try {
		const response = yield call(transferGetData, data);
		yield put(dashboardMasterDataSuccess(MASTER_DATA_GET_LIST_USERS, response));
	} catch (error) {
		yield put(dashboardMasterDataError(MASTER_DATA_GET_LIST_USERS, error));
	}
}

export function* watchMasterDataGetListUsers() {
	yield takeEvery(MASTER_DATA_GET_LIST_USERS, masterDataGetListUsers);
}

function* dashboardMasterDataSaga() {
	yield all([
		fork(watchGetListModel),
		fork(watchGetModel),
		fork(watchGetListSupplier),
		fork(watchGetListWarehouse),
		fork(watchGetListCondition),
		fork(watchGetListManagement),
		fork(watchGetListStatus),
		fork(watchGetListOrganization),
		fork(watchGetListPosition),
		fork(watchMasterDataQuickAddSupplier),
		fork(watcherMasterDataGetListHashTags),
		fork(watchMasterDataQuickAddSupplierEdit),
		fork(watchMasterDataGetListProductCode),
		fork(watchMasterDataQuickAddProduct),
		fork(watchMasterDataGetListUsers),
	]);
}

export default dashboardMasterDataSaga;

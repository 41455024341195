import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import { postFakeLogin, postLogin, postJwtLogin, postSocialLogin } from "../../../helpers/fakebackend_helper";

import { toast } from "react-toastify";
import { setAuthorization } from "../../../helpers/api_helper";

const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, history } }) {
	try {
		if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
			const response = yield call(fireBaseBackend.loginUser, user.email, user.password);
			yield put(loginSuccess(response));
		} else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
			const response = yield call(postJwtLogin, {
				email: user.email,
				password: user.password,
			});
			sessionStorage.setItem("authUser", JSON.stringify(response));
			yield put(loginSuccess(response));
		}
		// if (process.env.REACT_APP_API_URL)
		else {
			const response = yield call(postLogin, {
				// email: user.email,
				userEmail: user.username,
				password: user.password,
			});
			// sessionStorage.setItem("authUser", JSON.stringify(response));
			if (response.status === "success") {
				localStorage.setItem("settingNewItem", {
					valueBlackGreen: false,
					valueTimeBlackGreen: "",
				});
				localStorage.setItem("authUser", JSON.stringify(response));
				localStorage.setItem("config_template", JSON.stringify(response.data.config_template));
				localStorage.setItem("roles", JSON.stringify(response.data.roles));
				localStorage.setItem("pageView", JSON.stringify(response.data.pageView));
				setAuthorization(response.token);
				yield put(loginSuccess(response));
				toast.success("Login Successfully", { autoClose: 1000 });
				let dataPage = "/";
				// let pageNoteFirstPage = ["/log-time", "/log-time-management"];
				response.data.pageView.arrayPage.map((arrayPage, idx) => {
					// if (!pageNoteFirstPage.includes(arrayPage) && dataPage == "/") {
					// 	dataPage = arrayPage;
					// }
					// if (response.data.pageView.arrayPage.length == 1) {
					// 	dataPage = arrayPage;
					// }
					//Mặc định default erptools/001_search-vpn
					if (arrayPage === "/") {
						dataPage = arrayPage;
					}
				});
				history.push(dataPage);
			}
			// else {
			// 	yield put(apiError(response));
			// }
		}
	} catch (error) {
		yield put(apiError(error));
	}
}

function* logoutUser() {
	try {
		// sessionStorage.removeItem("authUser");
		localStorage.removeItem("authUser");
		localStorage.removeItem("config_template");
		localStorage.removeItem("roles");
		localStorage.removeItem("pageView");
		if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
			const response = yield call(fireBaseBackend.logout);
			yield put(logoutUserSuccess(LOGOUT_USER, response));
		} else {
			yield put(logoutUserSuccess(LOGOUT_USER, true));
		}
	} catch (error) {
		yield put(apiError(LOGOUT_USER, error));
	}
}

function* socialLogin({ payload: { data, history, type } }) {
	try {
		if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
			const fireBaseBackend = getFirebaseBackend();
			const response = yield call(fireBaseBackend.socialLoginUser, data, type);
			sessionStorage.setItem("authUser", JSON.stringify(response));
			yield put(loginSuccess(response));
		} else {
			const response = yield call(postSocialLogin, data);
			sessionStorage.setItem("authUser", JSON.stringify(response));
			yield put(loginSuccess(response));
		}
		history.push("/dashboard");
	} catch (error) {
		yield put(apiError(error));
	}
}

function* authSaga() {
	yield takeEvery(LOGIN_USER, loginUser);
	yield takeLatest(SOCIAL_LOGIN, socialLogin);
	yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;

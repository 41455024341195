// Actions
export const LIST_MAILS_API_RESPONSE_SUCCESS = "LIST_MAILS_API_RESPONSE_SUCCESS";
export const LIST_MAILS_API_RESPONSE_ERROR = "LIST_MAILS_API_RESPONSE_ERROR";

export const LIST_MAILS_GET_LIST_MAILS = "LIST_MAILS_GET_LIST_MAILS";
export const LIST_MAILS_GET_MAIL_BY_ID = "LIST_MAILS_GET_MAIL_BY_ID";
export const LIST_MAILS_GET_LOAD_FOLDER = "LIST_MAILS_GET_LOAD_FOLDER";
export const LIST_MAILS_GET_LOAD_LABEL = "LIST_MAILS_GET_LOAD_LABEL";
export const LIST_MAILS_GET_DEFINITIONS = "LIST_MAILS_GET_DEFINITIONS";
export const LIST_MAILS_SYNC_DATA = "LIST_MAILS_SYNC_DATA";
export const LIST_MAILS_GET_LIST_QUICK_FILTER = "LIST_MAILS_GET_LIST_QUICK_FILTER";
export const LIST_MAILS_GET_LIST_USERS = "LIST_MAILS_GET_LIST_USERS";
export const LIST_MAILS_GET_LIST_ACCOUNTS = "LIST_MAILS_GET_LIST_ACCOUNTS";

export const LIST_MAILS_MARK_IMPORTANT = "LIST_MAILS_MARK_IMPORTANT";
export const LIST_MAILS_REMOVE_IMPORTANT = "LIST_MAILS_REMOVE_IMPORTANT";

export const LIST_MAILS_ADD_MARK = "LIST_MAILS_ADD_MARK";
export const LIST_MAILS_ADD_MARK_SUCCESS = "LIST_MAILS_ADD_MARK_SUCCESS";
export const LIST_MAILS_ADD_MARK_FAIL = "LIST_MAILS_ADD_MARK_FAIL";

export const LIST_MAILS_REMOVE_MARK = "LIST_MAILS_REMOVE_MARK";
export const LIST_MAILS_REMOVE_MARK_SUCCESS = "LIST_MAILS_REMOVE_MARK_SUCCESS";
export const LIST_MAILS_REMOVE_MARK_FAIL = "LIST_MAILS_REMOVE_MARK_FAIL";

export const LIST_MAILS_ADD_LABEL = "LIST_MAILS_ADD_LABEL";
export const LIST_MAILS_ADD_LABEL_SUCCESS = "LIST_MAILS_ADD_LABEL_SUCCESS";
export const LIST_MAILS_ADD_LABEL_FAIL = "LIST_MAILS_ADD_LABEL_FAIL";

export const LIST_MAILS_REMOVE_LABEL = "LIST_MAILS_REMOVE_LABEL";
export const LIST_MAILS_REMOVE_LABEL_SUCCESS = "LIST_MAILS_REMOVE_LABEL_SUCCESS";
export const LIST_MAILS_REMOVE_LABEL_FAIL = "LIST_MAILS_REMOVE_LABEL_FAIL";

export const LIST_MAILS_CLEAR_CACHE = "LIST_MAILS_CLEAR_CACHE";
export const LIST_MAILS_CLEAR_CACHE_ACTION = "LIST_MAILS_CLEAR_CACHE_ACTION";
export const LIST_MAILS_CLEAR_CACHE_INFO = "LIST_MAILS_CLEAR_CACHE_INFO";

export const LIST_MAILS_ADD_QUICK_FILTER = "LIST_MAILS_ADD_QUICK_FILTER";
export const LIST_MAILS_ADD_QUICK_FILTER_SUCCESS = "LIST_MAILS_ADD_QUICK_FILTER_SUCCESS";
export const LIST_MAILS_ADD_QUICK_FILTER_FAIL = "LIST_MAILS_ADD_QUICK_FILTER_FAIL";

export const LIST_MAILS_UPDATE_QUICK_FILTER = "LIST_MAILS_UPDATE_QUICK_FILTER";
export const LIST_MAILS_UPDATE_QUICK_FILTER_SUCCESS = "LIST_MAILS_UPDATE_QUICK_FILTER_SUCCESS";
export const LIST_MAILS_UPDATE_QUICK_FILTER_FAIL = "LIST_MAILS_UPDATE_QUICK_FILTER_FAIL";

export const LIST_MAILS_DELETE_QUICK_FILTER = "LIST_MAILS_DELETE_QUICK_FILTER";
export const LIST_MAILS_DELETE_QUICK_FILTER_SUCCESS = "LIST_MAILS_DELETE_QUICK_FILTER_SUCCESS";
export const LIST_MAILS_DELETE_QUICK_FILTER_FAIL = "LIST_MAILS_DELETE_QUICK_FILTER_FAIL";

//SEND MAIL
export const LIST_MAILS_SEND_MAIL = "LIST_MAILS_SEND_MAIL";
export const LIST_MAILS_SEND_MAIL_SUCCESS = "LIST_MAILS_SEND_MAIL_SUCCESS";
export const LIST_MAILS_SEND_MAIL_FAIL = "LIST_MAILS_SEND_MAIL_FAIL";

//Assign
export const LIST_MAILS_ASSIGN_TASK = "LIST_MAILS_ASSIGN_TASK";
export const LIST_MAILS_ASSIGN_TASK_SUCCESS = "LIST_MAILS_ASSIGN_TASK_SUCCESS";
export const LIST_MAILS_ASSIGN_TASK_FAIL = "LIST_MAILS_ASSIGN_TASK_FAIL";

//////////////////
export const LIST_MAILS_GET_LIST_EMAIL_CONFIG = "LIST_MAILS_GET_LIST_EMAIL_CONFIG";
export const LIST_MAILS_GET_LIST_EMAIL_CONFIG_SUCCESS = "LIST_MAILS_GET_LIST_EMAIL_CONFIG_SUCCESS";
export const LIST_MAILS_GET_LIST_EMAIL_CONFIG_FAIL = "LIST_MAILS_GET_LIST_EMAIL_CONFIG_FAIL";

export const LIST_MAILS_ADD_CONFIG_EMAIL = "LIST_MAILS_ADD_CONFIG_EMAIL";
export const LIST_MAILS_ADD_CONFIG_EMAIL_SUCCESS = "LIST_MAILS_ADD_CONFIG_EMAIL_SUCCESS";
export const LIST_MAILS_ADD_CONFIG_EMAIL_FAIL = "LIST_MAILS_ADD_CONFIG_EMAIL_FAIL";

export const LIST_MAILS_UPDATE_EMAIL_CONFIG = "LIST_MAILS_UPDATE_EMAIL_CONFIG";
export const LIST_MAILS_UPDATE_EMAIL_CONFIG_SUCCESS = "LIST_MAILS_UPDATE_EMAIL_CONFIG_SUCCESS";
export const LIST_MAILS_UPDATE_EMAIL_CONFIG_FAIL = "LIST_MAILS_UPDATE_EMAIL_CONFIG_FAIL";

export const LIST_MAILS_DELETE_EMAIL_CONFIG = "LIST_MAILS_DELETE_EMAIL_CONFIG";
export const LIST_MAILS_DELETE_EMAIL_CONFIG_SUCCESS = "LIST_MAILS_DELETE_EMAIL_CONFIG_SUCCESS";
export const LIST_MAILS_DELETE_EMAIL_CONFIG_FAIL = "LIST_MAILS_DELETE_EMAIL_CONFIG_FAIL";

export const LIST_MAILS_GET_LIST_USER_CONFIG_ASSIGN = "LIST_MAILS_GET_LIST_USER_CONFIG_ASSIGN";
export const LIST_MAILS_GET_LIST_TEAM_CONFIG_ASSIGN = "LIST_MAILS_GET_LIST_TEAM_CONFIG_ASSIGN";
export const LIST_MAILS_GET_LIST_CONFIG_ASSIGN = "LIST_MAILS_GET_LIST_CONFIG_ASSIGN";

export const LIST_MAILS_ADD_CONFIG_ASSIGN = "LIST_MAILS_ADD_CONFIG_ASSIGN";
export const LIST_MAILS_ADD_CONFIG_ASSIGN_SUCCESS = "LIST_MAILS_ADD_CONFIG_ASSIGN_SUCCESS";
export const LIST_MAILS_ADD_CONFIG_ASSIGN_FAIL = "LIST_MAILS_ADD_CONFIG_ASSIGN_FAIL";

export const LIST_MAILS_UPDATE_CONFIG_ASSIGN = "LIST_MAILS_UPDATE_CONFIG_ASSIGN";
export const LIST_MAILS_UPDATE_CONFIG_ASSIGN_SUCCESS = "LIST_MAILS_UPDATE_CONFIG_ASSIGN_SUCCESS";
export const LIST_MAILS_UPDATE_CONFIG_ASSIGN_FAIL = "LIST_MAILS_UPDATE_CONFIG_ASSIGN_FAIL";

export const LIST_MAILS_DELETE_CONFIG_ASSIGN = "LIST_MAILS_DELETE_CONFIG_ASSIGN";
export const LIST_MAILS_DELETE_CONFIG_ASSIGN_SUCCESS = "LIST_MAILS_DELETE_CONFIG_ASSIGN_SUCCESS";
export const LIST_MAILS_DELETE_CONFIG_ASSIGN_FAIL = "LIST_MAILS_DELETE_CONFIG_ASSIGN_FAIL";

export const LIST_MAILS_CLEAR_ACTION_CONFIG_EMAIL = "LIST_MAILS_CLEAR_ACTION_CONFIG_EMAIL";
export const LIST_MAILS_CLEAR_ACTION_CONFIG_ASSIGN = "LIST_MAILS_CLEAR_ACTION_CONFIG_ASSIGN";

export const LIST_MAILS_TEST_EMAIL_CONFIG = "LIST_MAILS_TEST_EMAIL_CONFIG";
export const LIST_MAILS_TEST_EMAIL_CONFIG_SUCCESS = "LIST_MAILS_TEST_EMAIL_CONFIG_SUCCESS";
export const LIST_MAILS_TEST_EMAIL_CONFIG_FAIL = "LIST_MAILS_TEST_EMAIL_CONFIG_FAIL";
export const LIST_MAILS_CLEAR_TEST_EMAIL_CONFIG = "LIST_MAILS_CLEAR_TEST_EMAIL_CONFIG";

import {
	GET_LIST_MODEL,
	GET_LIST_SUPPLIER,
	GET_LIST_WAREHOUSE,
	GET_LIST_CONDITION,
	GET_LIST_MANAGEMENT,
	GET_LIST_STATUS,
	API_RESPONSE_SUCCESS,
	API_RESPONSE_ERROR,
	GET_SEARCH_TEXT_INPUT,
	GET_PRODUCT_MODEL_ID,
	GET_SUPPLYER_ID,
	GET_WAREHOUSE_SEARCH,
	GET_CONDITION_SEARCH,
	GET_MANAGEMENT_SEARCH,
	GET_STATUS_SEARCH,
	GET_DATEIN_SEARCH,
	GET_UPDATED_SEARCH,
	GET_ALl_PARAMS_SEARCH,
	CLEAR_ALL_SEARCH_DATA,
	GET_PRODUCT_MODEL_CODE,
	GET_MODEL,
	GET_WAREHOUSE,
	GET_FILTER_CONDITION,
	GET_LIST_ORGANIZATION,
	GET_LIST_POSITION,
	GET_PARAMS_TABLE_FOOTER_SEARCH,
	MASTER_DATA_CACHE_QUICK_ADD_SUPPLIER,
	MASTER_DATA_QUICK_ADD_SUPPLIER_SUCCESS,
	MASTER_DATA_QUICK_ADD_SUPPLIER_FAIL,
	MASTER_DATA_GET_LIST_HASHTAGS,
	MASTER_DATA_RESET_LOADING_PAGE_LIST_SN,
	MASTER_DATA_QUICK_ADD_SUPPLIER_EDIT_SUCCESS,
	MASTER_DATA_QUICK_ADD_SUPPLIER_EDIT_FAIL,
	MASTER_DATA_CACHE_QUICK_ADD_SUPPLIER_EDIT,
	MASTER_DATA_GET_LIST_PRODUCT_CODE,
	MASTER_DATA_CLEAR_CACHE_QUICK_ADD_PRODUCT,
	MASTER_DATA_QUICK_ADD_PRODUCT_SUCCESS,
	MASTER_DATA_QUICK_ADD_PRODUCT_FAIL,
	MASTER_DATA_GET_LIST_USERS,
} from "./actionType";

const INIT_STATE = {
	listModel: [],
	model: [],
	listSupplier: [],
	listWarehouse: [],
	wareHouseFilter: [],
	conditionFilter: [],
	listCondition: [],
	listManagement: [],
	listStatus: [],
	listOrganization: [],
	listPosition: [],

	searchTextInput: "",
	productModelId: "",
	productModelCode: null,
	supplierId: "",
	orgCode: "",
	condition: "",
	managementKind: [],
	status: [],
	dateIn: "",
	updatedAt: "",
	allParamsSearch: {},
	refreshLoading: false,
	isClear: false,
	tableFooterSearch: {},
	dataSNByID: {},
	listHashTags: [],
	loadingPage: false,
	dataProductCode: [],
	dataListAddNewProduct: "",
	loadingAddProduct: false,
	warningQuickAdd: [],
	dataListUsers: [],
};

const DashboardMasterData = (state = INIT_STATE, action) => {
	switch (action.type) {
		case API_RESPONSE_SUCCESS:
			switch (action.payload.actionType) {
				case GET_LIST_MODEL:
					return {
						...state,
						listModel: action.payload.data,
					};
				case GET_MODEL:
					return {
						...state,
						model: action.payload.data,
					};
				case GET_LIST_SUPPLIER:
					return {
						...state,
						listSupplier: action.payload.data,
					};
				case GET_LIST_WAREHOUSE:
					return {
						...state,
						listWarehouse: action.payload.data,
					};
				case GET_LIST_CONDITION:
					return {
						...state,
						listCondition: action.payload.data,
					};
				case GET_LIST_MANAGEMENT:
					return {
						...state,
						listManagement: action.payload.data,
					};
				case GET_LIST_STATUS:
					return {
						...state,
						listStatus: action.payload.data,
					};
				case GET_LIST_ORGANIZATION:
					return {
						...state,
						listOrganization: action.payload.data.data,
					};
				case GET_LIST_POSITION:
					return {
						...state,
						listPosition: action.payload.data.data,
					};

				case MASTER_DATA_GET_LIST_HASHTAGS:
					return {
						...state,
						listHashTags: action.payload.data.data,
					};
				case MASTER_DATA_GET_LIST_PRODUCT_CODE:
					return {
						...state,
						dataProductCode: action.payload.data.data,
					};
				case MASTER_DATA_GET_LIST_USERS:
					return {
						...state,
						dataListUsers: action.payload.data.data,
					};
				default:
					return state;
			}
		case API_RESPONSE_ERROR:
			switch (action.payload.actionType) {
				case GET_LIST_MODEL:
					return {
						...state,
						listModel: [],
					};
				default:
					return state;
			}
		case GET_SEARCH_TEXT_INPUT:
			return {
				...state,
				searchTextInput: action.payload,
				isClear: false,
			};
		case GET_PRODUCT_MODEL_ID:
			return {
				...state,
				productModelId: action.payload,
				isClear: false,
			};
		case GET_PRODUCT_MODEL_CODE:
			return {
				...state,
				productModelCode: action.payload,
				isClear: false,
			};
		case GET_SUPPLYER_ID:
			return {
				...state,
				supplierId: action.payload,
				isClear: false,
			};
		case GET_WAREHOUSE_SEARCH:
			return {
				...state,
				orgCode: action.payload,
				isClear: false,
			};
		case GET_CONDITION_SEARCH:
			return {
				...state,
				condition: action.payload,
				isClear: false,
			};
		case GET_MANAGEMENT_SEARCH:
			return {
				...state,
				managementKind: action.payload,
				isClear: false,
			};
		case GET_STATUS_SEARCH:
			return {
				...state,
				status: action.payload,
				isClear: false,
			};
		case GET_PARAMS_TABLE_FOOTER_SEARCH:
			return {
				...state,
				tableFooterSearch: action.payload,
			};
		case GET_DATEIN_SEARCH:
			return {
				...state,
				dateIn: action.payload,
				isClear: false,
			};
		case GET_UPDATED_SEARCH:
			return {
				...state,
				updatedAt: action.payload,
				isClear: false,
			};
		case GET_ALl_PARAMS_SEARCH:
			return {
				...state,
				allParamsSearch: action.payload,
				refreshLoading: !state.refreshLoading,
				isClear: false,
			};
		case CLEAR_ALL_SEARCH_DATA:
			return {
				...state,
				productModelCode: null,
				searchTextInput: "",
				productModelId: "",
				supplierId: "",
				orgCode: "",
				condition: "",
				managementKind: [],
				status: [],
				dateIn: "",
				updatedAt: "",
				isClear: true,
				tableFooterSearch: {},
				allParamsSearch: {},
				wareHouseFilter: [],
				conditionFilter: [],
			};
		case GET_WAREHOUSE:
			return {
				...state,
				wareHouseFilter: action.payload.search,
			};
		case GET_FILTER_CONDITION:
			return {
				...state,
				conditionFilter: action.payload.search,
			};

		case MASTER_DATA_QUICK_ADD_SUPPLIER_SUCCESS:
			return {
				...state,
				dataListAddNewSupplier: action.payload.data,
			};

		case MASTER_DATA_QUICK_ADD_SUPPLIER_FAIL:
			return {
				...state,
				error: action.payload.error,
			};
		case MASTER_DATA_CACHE_QUICK_ADD_SUPPLIER:
			return {
				...state,
				dataListAddNewSupplier: {},
				// error: {},
			};

		case MASTER_DATA_QUICK_ADD_SUPPLIER_EDIT_SUCCESS:
			return {
				...state,
				dataListAddNewSupplierEdit: action.payload.data,
			};

		case MASTER_DATA_QUICK_ADD_SUPPLIER_EDIT_FAIL:
			return {
				...state,
				error: action.payload.error,
			};
		case MASTER_DATA_CACHE_QUICK_ADD_SUPPLIER_EDIT:
			return {
				...state,
				dataListAddNewSupplierEdit: {},
				// error: {},
			};

		case MASTER_DATA_RESET_LOADING_PAGE_LIST_SN:
			return {
				...state,
				loadingPage: !state.loadingPage,
			};
		case MASTER_DATA_CLEAR_CACHE_QUICK_ADD_PRODUCT:
			return {
				...state,
				dataListAddNewProduct: "",
				warningQuickAdd: [],
			};
		case MASTER_DATA_QUICK_ADD_PRODUCT_SUCCESS:
			return {
				...state,
				dataListAddNewProduct: action.payload.data,
				dataProductCode: [action.payload.data, ...state.dataProductCode],
				// loadingAddProduct: !state.loadingAddProduct,
			};
		case MASTER_DATA_QUICK_ADD_PRODUCT_FAIL:
			return {
				...state,
				dataListAddNewProduct: "",
				loadingAddProduct: !state.loadingAddProduct,
				warningQuickAdd: action.payload.Msg,
			};
		default:
			return state;
	}
};
export default DashboardMasterData;

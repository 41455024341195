import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import ParticlesAuth from "../ParticlesAuth";

//import images
import logoLight from "../../../assets/images/logo-light.png";
import avatar5 from "../../../assets/images/users/avatar-5.jpg";
import { useSelector } from "react-redux";

const BasicLockScreen = () => {
  document.title = "Lock Screen | IP Supply";

  const { user } = useSelector((state) => ({
    user: state.Profile.user,
  }));

  const [userName, setUserName] = useState("Admin");

  useEffect(() => {
    if (sessionStorage.getItem("authUser")) {
      const obj = JSON.parse(sessionStorage.getItem("authUser"));
      setUserName(
        user.first_name ||
          obj.data.last_name + " " + obj.data.first_name ||
          "Admin"
      );
    }
  }, [userName, user]);

  return (
    <React.Fragment>
      <div className="auth-page-content">
        <div className="auth-page-wrapper">
          <ParticlesAuth>
            <div className="auth-page-content">
              <Container>
                <Row>
                  <Col lg={12}>
                    <div className="text-center mt-sm-5 mb-4 text-white-50">
                      <p className="mt-3 fs-15 fw-medium">
                        <Link to="/" className="d-inline-block auth-logo">
                          <img src={logoLight} alt="" height="100" />
                        </Link>
                      </p>
                    </div>
                  </Col>
                </Row>

                <Row className="justify-content-center">
                  <Col md={8} lg={6} xl={5}>
                    <Card className="mt-4">
                      <CardBody className="p-4">
                        <div className="text-center mt-2">
                          <h5 className="text-primary">Lock Screen</h5>
                          <p className="text-muted">
                            Enter your password to unlock the screen!
                          </p>
                        </div>
                        <div className="user-thumb text-center">
                          <img
                            src={avatar5}
                            className="rounded-circle img-thumbnail avatar-lg"
                            alt="thumbnail"
                          />
                          <h5 className="font-size-15 mt-3">{userName}</h5>
                        </div>
                        <div className="p-2 mt-4">
                          <form>
                            <div className="mb-3">
                              <label
                                className="form-label"
                                htmlFor="userpassword"
                              >
                                Password
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                id="userpassword"
                                placeholder="Enter password"
                                required
                              />
                            </div>
                            <div className="mb-2 mt-4">
                              <Button
                                color="success"
                                className="w-100"
                                type="submit"
                              >
                                Unlock
                              </Button>
                            </div>
                          </form>
                        </div>
                      </CardBody>
                    </Card>
                    <div className="mt-4 text-center">
                      <p className="mb-0">
                        Not you ? return{" "}
                        <Link
                          to="/login"
                          className="fw-semibold text-primary text-decoration-underline"
                        >
                          {" "}
                          Signin{" "}
                        </Link>{" "}
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </ParticlesAuth>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BasicLockScreen;

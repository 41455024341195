import React from "react";
import { Link } from "react-router-dom";
import { Col, Row, Button } from "reactstrap";

const BreadCrumb = ({ title, pageTitle, to, isReset = false, onClick = () => {} }) => {
	return (
		<React.Fragment>
			<Row>
				<Col xs={12}>
					<div className="page-title-box d-sm-flex align-items-center justify-content-between">
						<div className="d-flex align-items-center">
							<h4 className="mb-sm-0">{title}</h4>
							{isReset && (
								<div className="ms-2">
									<Button style={{ width: "66px", height: "24px" }} color="info" type="button" className="btn-icon" title="Reset" onClick={onClick}>
										<i className="ri-refresh-line fs-16 me-1"></i> Reset
									</Button>
								</div>
							)}
						</div>

						<div className="page-title-right">
							<ol className="breadcrumb m-0">
								<li className="breadcrumb-item">
									<Link to={to ? to : "#"}>{pageTitle}</Link>
								</li>
								<li className="breadcrumb-item active">{title}</li>
							</ol>
						</div>
					</div>
				</Col>
			</Row>
		</React.Fragment>
	);
};

export default BreadCrumb;

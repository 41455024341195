import React, { useState, useEffect } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";

//import images
import avatar5 from "../../assets/images/users/avatar-5.jpg";
import useAuthUser from "../Hooks/AuthUser";

const ProfileDropdown = () => {
	const { fullName } = useAuthUser();
	const [userName, setUserName] = useState("Admin");

	useEffect(() => {
		setUserName(fullName || "Admin");
	}, [fullName]);

	//Dropdown Toggle
	const [isProfileDropdown, setIsProfileDropdown] = useState(false);
	const toggleProfileDropdown = () => {
		setIsProfileDropdown(!isProfileDropdown);
	};
	return (
		<React.Fragment>
			<Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
				<DropdownToggle tag="button" type="button" className="btn">
					<span className="d-flex align-items-center">
						<img className="rounded-circle header-profile-user" src={avatar5} alt="Header Avatar" />
						<span className="text-start ms-xl-2">
							<span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{userName}</span>
							<span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">{"Admin"}</span>
						</span>
					</span>
				</DropdownToggle>
				<DropdownMenu className="dropdown-menu-end">
					<h6 className="dropdown-header">Welcome {userName}!</h6>
					<DropdownItem href="/pages-profile">
						<i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
						<span className="align-middle">Profile</span>
					</DropdownItem>
					<DropdownItem href="/apps-chat">
						<i className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Messages</span>
					</DropdownItem>
					{/* <DropdownItem href="#"><i
                        className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle">Taskboard</span></DropdownItem> */}
					<DropdownItem href="/pages-faqs">
						<i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Help</span>
					</DropdownItem>
					<div className="dropdown-divider"></div>
					{/* <DropdownItem href="/pages-profile"><i
                        className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle">Balance : <b>$5971.67</b></span></DropdownItem> */}
					<DropdownItem href="/pages-profile-settings">
						<span className="badge bg-soft-success text-success mt-1 float-end">New</span>
						<i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Settings</span>
					</DropdownItem>
					<DropdownItem href="/auth-lockscreen-basic">
						<i className="mdi mdi-lock text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Lock screen</span>
					</DropdownItem>
					<DropdownItem href="/logout">
						<i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
						<span className="align-middle" data-key="t-logout">
							Logout
						</span>
					</DropdownItem>
				</DropdownMenu>
			</Dropdown>
		</React.Fragment>
	);
};

export default ProfileDropdown;

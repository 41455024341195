// Actions
export const GET_LIST_MODEL = "GET_LIST_MODEL";
export const GET_MODEL = "GET_MODEL";
export const GET_LIST_SUPPLIER = "GET_LIST_SUPPLIER";
export const GET_LIST_WAREHOUSE = "GET_LIST_WAREHOUSE";
export const GET_WAREHOUSE = "GET_WAREHOUSE";
export const GET_FILTER_CONDITION = "GET_FILTER_CONDITION";
export const GET_LIST_CONDITION = "GET_LIST_CONDITION";
export const GET_LIST_MANAGEMENT = "GET_LIST_MANAGEMENT";
export const GET_LIST_STATUS = "GET_LIST_STATUS";
export const GET_LIST_ORGANIZATION = "GET_LIST_ORGANIZATION";
export const GET_LIST_POSITION = "GET_LIST_POSITION";

// Action filter
export const GET_SEARCH_TEXT_INPUT = "GET_SEARCH_TEXT_INPUT";
export const GET_PRODUCT_MODEL_ID = "GET_PRODUCT_MODEL_ID";
export const GET_PRODUCT_MODEL_CODE = "GET_PRODUCT_MODEL_CODE";
export const GET_SUPPLYER_ID = "GET_SUPPLYER_ID";
export const GET_WAREHOUSE_SEARCH = "GET_WAREHOUSE_SEARCH";
export const GET_CONDITION_SEARCH = "GET_CONDITION_SEARCH";
export const GET_MANAGEMENT_SEARCH = "GET_MANAGEMENT_SEARCH";
export const GET_STATUS_SEARCH = "GET_STATUS_SEARCH";
export const GET_DATEIN_SEARCH = "GET_DATEIN_SEARCH";
export const GET_UPDATED_SEARCH = "GET_UPDATED_SEARCH";
export const GET_ALl_PARAMS_SEARCH = "GET_ALl_PARAMS_SEARCH";
export const CLEAR_ALL_SEARCH_DATA = "CLEAR_ALL_SEARCH_DATA";
export const GET_PARAMS_TABLE_FOOTER_SEARCH = "GET_PARAMS_TABLE_FOOTER_SEARCH";

export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const MASTER_DATA_QUICK_ADD_SUPPLIER = "MASTER_DATA_QUICK_ADD_SUPPLIER";
export const MASTER_DATA_QUICK_ADD_SUPPLIER_SUCCESS = "MASTER_DATA_QUICK_ADD_SUPPLIER_SUCCESS";
export const MASTER_DATA_QUICK_ADD_SUPPLIER_FAIL = "MASTER_DATA_QUICK_ADD_SUPPLIER_FAIL";
export const MASTER_DATA_CACHE_QUICK_ADD_SUPPLIER = "MASTER_DATA_CLEAR_CACHE_QUICK_ADD_SUPPLIER";

export const MASTER_DATA_SN_GET_LIST_HASHTAGS = "MASTER_DATA_SN_GET_LIST_HASHTAGS";
export const MASTER_DATA_GET_LIST_HASHTAGS = "MASTER_DATA_GET_LIST_HASHTAGS";
export const MASTER_DATA_UPDATE_SN = "MASTER_DATA_UPDATE_SN";
export const MASTER_DATA_UPDATE_SN_SUCCESS = "MASTER_DATA_UPDATE_SN_SUCCESS";
export const MASTER_DATA_UPDATE_SN_FAIL = "MASTER_DATA_UPDATE_SN_FAIL";
export const MASTER_DATA_RESET_LOADING_PAGE_LIST_SN = "MASTER_DATA_RESET_LOADING_PAGE_LIST_SN";

export const MASTER_DATA_QUICK_ADD_SUPPLIER_EDIT = "MASTER_DATA_QUICK_ADD_SUPPLIER_EDIT";
export const MASTER_DATA_QUICK_ADD_SUPPLIER_EDIT_SUCCESS = "MASTER_DATA_QUICK_ADD_SUPPLIER_EDIT_SUCCESS";
export const MASTER_DATA_QUICK_ADD_SUPPLIER_EDIT_FAIL = "MASTER_DATA_QUICK_ADD_SUPPLIER_EDIT_FAIL";
export const MASTER_DATA_CACHE_QUICK_ADD_SUPPLIER_EDIT = "MASTER_DATA_CACHE_QUICK_ADD_SUPPLIER_EDIT";

export const MASTER_DATA_GET_LIST_PRODUCT_CODE = "MASTER_DATA_GET_LIST_PRODUCT_CODE";
export const MASTER_DATA_QUICK_ADD_PRODUCT = "MASTER_DATA_QUICK_ADD_PRODUCT";
export const MASTER_DATA_QUICK_ADD_PRODUCT_SUCCESS = "MASTER_DATA_QUICK_ADD_PRODUCT_SUCCESS";
export const MASTER_DATA_QUICK_ADD_PRODUCT_FAIL = "MASTER_DATA_QUICK_ADD_PRODUCT_FAIL";
export const MASTER_DATA_CLEAR_CACHE_QUICK_ADD_PRODUCT = "MASTER_DATA_CLEAR_CACHE_QUICK_ADD_PRODUCT";

export const MASTER_DATA_GET_LIST_USERS = "MASTER_DATA_GET_LIST_USERS";

import {
	LIST_MAILS_GET_LIST_MAILS,
	LIST_MAILS_GET_LOAD_FOLDER,
	LIST_MAILS_API_RESPONSE_SUCCESS,
	LIST_MAILS_API_RESPONSE_ERROR,
	LIST_MAILS_GET_LOAD_LABEL,
	LIST_MAILS_GET_DEFINITIONS,
	LIST_MAILS_CLEAR_CACHE,
	LIST_MAILS_CLEAR_CACHE_ACTION,
	LIST_MAILS_GET_MAIL_BY_ID,
	LIST_MAILS_SYNC_DATA,
	LIST_MAILS_MARK_IMPORTANT,
	LIST_MAILS_REMOVE_IMPORTANT,
	LIST_MAILS_ADD_MARK,
	LIST_MAILS_ADD_MARK_SUCCESS,
	LIST_MAILS_ADD_MARK_FAIL,
	LIST_MAILS_REMOVE_MARK,
	LIST_MAILS_REMOVE_MARK_SUCCESS,
	LIST_MAILS_REMOVE_MARK_FAIL,
	LIST_MAILS_ADD_LABEL,
	LIST_MAILS_ADD_LABEL_SUCCESS,
	LIST_MAILS_ADD_LABEL_FAIL,
	LIST_MAILS_REMOVE_LABEL,
	LIST_MAILS_REMOVE_LABEL_SUCCESS,
	LIST_MAILS_REMOVE_LABEL_FAIL,
	LIST_MAILS_CLEAR_CACHE_INFO,
	LIST_MAILS_GET_LIST_QUICK_FILTER,
	LIST_MAILS_UPDATE_QUICK_FILTER,
	LIST_MAILS_UPDATE_QUICK_FILTER_SUCCESS,
	LIST_MAILS_UPDATE_QUICK_FILTER_FAIL,
	LIST_MAILS_DELETE_QUICK_FILTER,
	LIST_MAILS_DELETE_QUICK_FILTER_SUCCESS,
	LIST_MAILS_DELETE_QUICK_FILTER_FAIL,
	LIST_MAILS_ADD_QUICK_FILTER,
	LIST_MAILS_ADD_QUICK_FILTER_SUCCESS,
	LIST_MAILS_ADD_QUICK_FILTER_FAIL,
	LIST_MAILS_GET_LIST_USERS,
	LIST_MAILS_SEND_MAIL,
	LIST_MAILS_SEND_MAIL_FAIL,
	LIST_MAILS_SEND_MAIL_SUCCESS,
	LIST_MAILS_ASSIGN_TASK,
	LIST_MAILS_ASSIGN_TASK_SUCCESS,
	LIST_MAILS_ASSIGN_TASK_FAIL,
	LIST_MAILS_GET_LIST_ACCOUNTS,
	LIST_MAILS_DELETE_EMAIL_CONFIG,
	LIST_MAILS_DELETE_EMAIL_CONFIG_SUCCESS,
	LIST_MAILS_DELETE_EMAIL_CONFIG_FAIL,
	LIST_MAILS_GET_LIST_EMAIL_CONFIG,
	LIST_MAILS_ADD_CONFIG_EMAIL,
	LIST_MAILS_ADD_CONFIG_EMAIL_SUCCESS,
	LIST_MAILS_ADD_CONFIG_EMAIL_FAIL,
	LIST_MAILS_UPDATE_EMAIL_CONFIG_SUCCESS,
	LIST_MAILS_UPDATE_EMAIL_CONFIG_FAIL,
	LIST_MAILS_UPDATE_EMAIL_CONFIG,
	LIST_MAILS_GET_LIST_USER_CONFIG_ASSIGN,
	LIST_MAILS_GET_LIST_TEAM_CONFIG_ASSIGN,
	LIST_MAILS_GET_LIST_CONFIG_ASSIGN,
	LIST_MAILS_DELETE_CONFIG_ASSIGN,
	LIST_MAILS_DELETE_CONFIG_ASSIGN_SUCCESS,
	LIST_MAILS_DELETE_CONFIG_ASSIGN_FAIL,
	LIST_MAILS_UPDATE_CONFIG_ASSIGN,
	LIST_MAILS_UPDATE_CONFIG_ASSIGN_FAIL,
	LIST_MAILS_UPDATE_CONFIG_ASSIGN_SUCCESS,
	LIST_MAILS_ADD_CONFIG_ASSIGN_SUCCESS,
	LIST_MAILS_ADD_CONFIG_ASSIGN_FAIL,
	LIST_MAILS_ADD_CONFIG_ASSIGN,
	LIST_MAILS_CLEAR_ACTION_CONFIG_EMAIL,
	LIST_MAILS_CLEAR_ACTION_CONFIG_ASSIGN,
	LIST_MAILS_TEST_EMAIL_CONFIG,
	LIST_MAILS_TEST_EMAIL_CONFIG_SUCCESS,
	LIST_MAILS_TEST_EMAIL_CONFIG_FAIL,
	LIST_MAILS_CLEAR_TEST_EMAIL_CONFIG,
} from "./actionType";

// common success
export const listMailsApiResponseSuccess = (actionType, data) => ({
	type: LIST_MAILS_API_RESPONSE_SUCCESS,
	payload: { actionType, data },
});
// common error
export const listMailsApiResponseError = (actionType, error) => ({
	type: LIST_MAILS_API_RESPONSE_ERROR,
	payload: { actionType, error },
});

export const listMailsGetListMails = (
	page,
	limit,
	order,
	sortDirection,
	search = "",
	account = "",
	folder = "",
	label = "",
	fromDate = "",
	toDate = "",
	status = "",
	timezone = "",
	assignedId = null,
	notShow = [],
	optionSearch = [],
	notShowOptionSearch
) => ({
	type: LIST_MAILS_GET_LIST_MAILS,
	payload: {
		urlAPI: "/api/emails/get-email-list",
		host: "EMAIL_STORE_API_URL",
		filter: {
			limit: limit,
			skip: page * limit - limit,
			order: order + " " + sortDirection,
			where: {
				_q: search,
				// account,
				// folder,
				label,
				fromDate,
				toDate,
				status,
				timezone,
				// assignedId,
				notShow,
				optionSearch,
				notShowOptionSearch,
			},
		},
	},
});

export const listMailsGetMailByID = (data) => ({
	type: LIST_MAILS_GET_MAIL_BY_ID,
	payload: {
		urlAPI: "/api/emails/get-info/" + data,
		host: "EMAIL_STORE_API_URL",
	},
});

// export const listMailsGetLoadFolder = (email = "") => ({
// 	type: LIST_MAILS_GET_LOAD_FOLDER,
// 	payload: {
// 		urlAPI: "/api/tool-email/load-folder",
// 		host: "EMAIL_STORE_API_URL",
// 		filter: {
// 			where: {
// 				email,
// 			},
// 		},
// 	},
// });

export const listMailsGetLoadLabel = (email = "") => ({
	type: LIST_MAILS_GET_LOAD_LABEL,
	payload: {
		urlAPI: "/api/emails/load-label",
		host: "EMAIL_STORE_API_URL",
		filter: {
			where: { email },
		},
	},
});

export const listMailsGetListDefinitions = () => ({
	type: LIST_MAILS_GET_DEFINITIONS,
	payload: {
		urlAPI: "/api/definitions/definitions-list",
		host: "EMAIL_STORE_API_URL",
	},
});

export const listMailsClearCache = () => ({
	type: LIST_MAILS_CLEAR_CACHE,
});

export const listMailsClearCacheAction = () => ({
	type: LIST_MAILS_CLEAR_CACHE_ACTION,
});

export const listMailsSyncData = () => ({
	type: LIST_MAILS_SYNC_DATA,
	payload: {
		urlAPI: "/api/emails/sync-data",
		host: "EMAIL_STORE_API_URL",
	},
});

//Mark important
export const listMailsMarkImportant = (data) => ({
	type: LIST_MAILS_MARK_IMPORTANT,
	payload: {
		urlAPI: "/api/emails/add-mark",
		host: "EMAIL_STORE_API_URL",
		data,
	},
});

//Remove important
export const listMailsRemoveImportant = (data) => ({
	type: LIST_MAILS_REMOVE_IMPORTANT,
	payload: {
		urlAPI: "/api/emails/remove-mark",
		host: "EMAIL_STORE_API_URL",
		data,
	},
});

//Add Mark
export const listMailsAddMark = (data, kind = "") => ({
	type: LIST_MAILS_ADD_MARK,
	payload: {
		urlAPI: "/api/emails/add-mark",
		host: "EMAIL_STORE_API_URL",
		data,
	},
	kind,
});

export const listMailsAddMarkSuccess = (data) => ({
	type: LIST_MAILS_ADD_MARK_SUCCESS,
	payload: data,
});

export const listMailsAddMarkFail = (error) => ({
	type: LIST_MAILS_ADD_MARK_FAIL,
	payload: error,
});

//Remove Mark
export const listMailsRemoveMark = (data) => ({
	type: LIST_MAILS_REMOVE_MARK,
	payload: {
		urlAPI: "/api/emails/remove-mark",
		host: "EMAIL_STORE_API_URL",
		data,
	},
});

export const listMailsRemoveMarkSuccess = (data) => ({
	type: LIST_MAILS_REMOVE_MARK_SUCCESS,
	payload: data,
});

export const listMailsRemoveMarkFail = (error) => ({
	type: LIST_MAILS_REMOVE_MARK_FAIL,
	payload: error,
});

//Add Tag
export const listMailsAddTag = (data) => ({
	type: LIST_MAILS_ADD_LABEL,
	payload: {
		urlAPI: "/api/emails/add-tag",
		host: "EMAIL_STORE_API_URL",
		data,
	},
});

export const listMailsAddTagSuccess = (data) => ({
	type: LIST_MAILS_ADD_LABEL_SUCCESS,
	payload: data,
});

export const listMailsAddTagFail = (error) => ({
	type: LIST_MAILS_ADD_LABEL_FAIL,
	payload: error,
});

//Remove Tag
export const listMailsRemoveTag = (data) => ({
	type: LIST_MAILS_REMOVE_LABEL,
	payload: {
		urlAPI: "/api/emails/remove-tag",
		host: "EMAIL_STORE_API_URL",
		data,
	},
});

export const listMailsRemoveTagSuccess = (data) => ({
	type: LIST_MAILS_REMOVE_LABEL_SUCCESS,
	payload: data,
});

export const listMailsRemoveTagFail = (error) => ({
	type: LIST_MAILS_REMOVE_LABEL_FAIL,
	payload: error,
});

export const listMailsClearCacheInfo = () => ({
	type: LIST_MAILS_CLEAR_CACHE_INFO,
});

//Quick filter
export const listMailsGetListQuickFilter = (account = "") => ({
	type: LIST_MAILS_GET_LIST_QUICK_FILTER,
	payload: {
		urlAPI: "/api/email-quick-filter/get-quick-filter-list",
		host: "EMAIL_STORE_API_URL",
		filter: {
			limit: 50,
			skip: 0,
			order: "updatedAt desc",
			where: {
				account,
			},
		},
	},
});

//Add quick filter
export const listMailsAddQuickFilter = (data) => ({
	type: LIST_MAILS_ADD_QUICK_FILTER,
	payload: {
		urlAPI: "/api/email-quick-filter/save-data",
		host: "EMAIL_STORE_API_URL",
		data,
	},
});

export const listMailsAddQuickFilterSuccess = (data) => ({
	type: LIST_MAILS_ADD_QUICK_FILTER_SUCCESS,
	payload: data,
});

export const listMailsAddQuickFilterFail = (error) => ({
	type: LIST_MAILS_ADD_QUICK_FILTER_FAIL,
	payload: error,
});

//Update quick filter
export const listMailsUpdateQuickFilter = (data) => ({
	type: LIST_MAILS_UPDATE_QUICK_FILTER,
	payload: {
		urlAPI: "/api/email-quick-filter/save-data",
		host: "EMAIL_STORE_API_URL",
		data,
	},
});

export const listMailsUpdateQuickFilterSuccess = (data) => ({
	type: LIST_MAILS_UPDATE_QUICK_FILTER_SUCCESS,
	payload: data,
});

export const listMailsUpdateQuickFilterFail = (error) => ({
	type: LIST_MAILS_UPDATE_QUICK_FILTER_FAIL,
	payload: error,
});

//Delete quick filter
export const listMailsDeleteQuickFilter = (data) => ({
	type: LIST_MAILS_DELETE_QUICK_FILTER,
	payload: {
		urlAPI: "/api/email-quick-filter/delete-quick-filter/" + data,
		host: "EMAIL_STORE_API_URL",
	},
	id: data,
});

export const listMailsDeleteQuickFilterSuccess = (data) => ({
	type: LIST_MAILS_DELETE_QUICK_FILTER_SUCCESS,
	payload: data,
});

export const listMailsDeleteQuickFilterFail = (error) => ({
	type: LIST_MAILS_DELETE_QUICK_FILTER_FAIL,
	payload: error,
});

//Users
export const listMailsGetListUsers = (search = "") => ({
	type: LIST_MAILS_GET_LIST_USERS,
	payload: {
		urlAPI: "/api/user/list-combo-box",
		// host: "EMAIL_STORE_API_URL",
		filter: {
			order: "updatedAt desc",
			where: {
				_q: search,
				type: "staff",
			},
		},
	},
});

//Upload File
export const listMailsSendMail = (data) => ({
	type: LIST_MAILS_SEND_MAIL,
	payload: {
		urlAPI: "/api/tool-email/sent-email",
		host: "EMAIL_STORE_API_URL",
		data,
	},
});

export const listMailsSendMailSuccess = () => ({
	type: LIST_MAILS_SEND_MAIL_SUCCESS,
});
export const listMailsSendMailFail = () => ({
	type: LIST_MAILS_SEND_MAIL_FAIL,
});

//Assign
export const listMailsAssignTask = (data) => ({
	type: LIST_MAILS_ASSIGN_TASK,
	payload: {
		urlAPI: "/api/tool-email/assign-tasks",
		host: "EMAIL_STORE_API_URL",
		data,
	},
});

export const listMailsAssignTaskSuccess = (data) => ({
	type: LIST_MAILS_ASSIGN_TASK_SUCCESS,
	payload: data,
});

export const listMailsAssignTaskFail = (error) => ({
	type: LIST_MAILS_ASSIGN_TASK_FAIL,
	payload: error,
});

export const listMailsGetListAccount = () => ({
	type: LIST_MAILS_GET_LIST_ACCOUNTS,
	payload: {
		urlAPI: "/api/tool-email/get-account-list",
		host: "EMAIL_STORE_API_URL",
	},
});

////////////////////////

//Modal Config Email
export const listMailsGetListConfigEmail = (data) => ({
	type: LIST_MAILS_GET_LIST_EMAIL_CONFIG,
	payload: {
		urlAPI: "/api/email-account/get-list",
		host: "EMAIL_STORE_API_URL",
		filter: {
			limit: 100,
			skip: 0,
			order: "updatedAt DESC",
		},
	},
});

//Add quick filter
export const listMailsAddConfigEmail = (data) => ({
	type: LIST_MAILS_ADD_CONFIG_EMAIL,
	payload: {
		urlAPI: "/api/email-account/save-data",
		host: "EMAIL_STORE_API_URL",
		data,
	},
});

export const listMailsAddConfigEmailSuccess = (data) => ({
	type: LIST_MAILS_ADD_CONFIG_EMAIL_SUCCESS,
	payload: data,
});

export const listMailsAddConfigEmailFail = (error) => ({
	type: LIST_MAILS_ADD_CONFIG_EMAIL_FAIL,
	payload: error,
});

//Update config email
export const listMailsUpdateConfigEmail = (data) => ({
	type: LIST_MAILS_UPDATE_EMAIL_CONFIG,
	payload: {
		urlAPI: "/api/email-account/save-data",
		host: "EMAIL_STORE_API_URL",
		data,
	},
});

export const listMailsUpdateConfigEmailSuccess = (data) => ({
	type: LIST_MAILS_UPDATE_EMAIL_CONFIG_SUCCESS,
	payload: data,
});

export const listMailsUpdateConfigEmailFail = (error) => ({
	type: LIST_MAILS_UPDATE_EMAIL_CONFIG_FAIL,
	payload: error,
});

//Delete config email
export const listMailsDeleteConfigEmail = (data) => ({
	type: LIST_MAILS_DELETE_EMAIL_CONFIG,
	payload: {
		urlAPI: "/api/email-account/delete-account/" + data,
		host: "EMAIL_STORE_API_URL",
	},
	id: data,
});

export const listMailsDeleteConfigEmailSuccess = (data) => ({
	type: LIST_MAILS_DELETE_EMAIL_CONFIG_SUCCESS,
	payload: data,
});

export const listMailsDeleteConfigEmailFail = (error) => ({
	type: LIST_MAILS_DELETE_EMAIL_CONFIG_FAIL,
	payload: error,
});

//Modal Config Assign
export const listMailsGeListConfigAssign = (data) => ({
	type: LIST_MAILS_GET_LIST_CONFIG_ASSIGN,
	payload: {
		urlAPI: "/api/email-config-assign/get-list",
		host: "EMAIL_STORE_API_URL",
		filter: {
			limit: 100,
			skip: 0,
			order: "updatedAt DESC",
		},
	},
});

export const listMailsGeListUserConfigAssign = (data="", limit=1000) => ({
	type: LIST_MAILS_GET_LIST_USER_CONFIG_ASSIGN,
	payload: {
		urlAPI: "/api/email-config-assign/get-list-user",
		host: "EMAIL_STORE_API_URL",
		filter: {
			limit: limit,
			skip: 0,
			order: "updatedAt DESC",
			where: {
				_q: data,
				type: "staff"
			}
		},
	},
});

export const listMailsGeListTeamConfigAssign = (data="", limit=100) => ({
	type: LIST_MAILS_GET_LIST_TEAM_CONFIG_ASSIGN,
	payload: {
		urlAPI: "/api/email-config-assign/get-list-team",
		host: "EMAIL_STORE_API_URL",
		filter: {
			limit: limit,
			skip: 0,
			order: "updatedAt DESC",
			where: {
				_q: data,
			}
		},
	},
});

//Add config assign
export const listMailsAddConfigAssign = (data) => ({
	type: LIST_MAILS_ADD_CONFIG_ASSIGN,
	payload: {
		urlAPI: "/api/email-config-assign/data-save",
		host: "EMAIL_STORE_API_URL",
		data,
	},
});

export const listMailsAddConfigAssignSuccess = (data) => ({
	type: LIST_MAILS_ADD_CONFIG_ASSIGN_SUCCESS,
	payload: data,
});

export const listMailsAddConfigAssignFail = (error) => ({
	type: LIST_MAILS_ADD_CONFIG_ASSIGN_FAIL,
	payload: error,
});

// update config assign
export const listMailsUpdateConfigAssign = (data) => ({
	type: LIST_MAILS_UPDATE_CONFIG_ASSIGN,
	payload: {
		urlAPI: "/api/email-config-assign/data-save",
		host: "EMAIL_STORE_API_URL",
		data,
	},
});

export const listMailsUpdateConfigAssignSuccess = (data) => ({
	type: LIST_MAILS_UPDATE_CONFIG_ASSIGN_SUCCESS,
	payload: data,
});

export const listMailsUpdateConfigAssignFail = (error) => ({
	type: LIST_MAILS_UPDATE_CONFIG_ASSIGN_FAIL,
	payload: error,
});

// delete config assign
export const listMailsDeleteConfigAssign = (data) => ({
	type: LIST_MAILS_DELETE_CONFIG_ASSIGN,
	payload: {
		urlAPI: "/api/email-config-assign/delete-config-assign/" + data,
		host: "EMAIL_STORE_API_URL",
	},
	id: data,
});

export const listMailsDeleteConfigAssignSuccess = (data) => ({
	type: LIST_MAILS_DELETE_CONFIG_ASSIGN_SUCCESS,
	payload: data,
});

export const listMailsDeleteConfigAssignFail = (error) => ({
	type: LIST_MAILS_DELETE_CONFIG_ASSIGN_FAIL,
	payload: error,
});

export const listMailsClearActionConfigEmail = () => ({
	type: LIST_MAILS_CLEAR_ACTION_CONFIG_EMAIL,
});
export const listMailsClearActionConfigAssign = () => ({
	type: LIST_MAILS_CLEAR_ACTION_CONFIG_ASSIGN,
});

//Remove important
export const listEmailsTestEmailConfig = (data) => ({
	type: LIST_MAILS_TEST_EMAIL_CONFIG,
	payload: {
		urlAPI: "/api/email-account/check-connect-to-server",
		host: "EMAIL_STORE_API_URL",
		data,
	},
});

export const listEmailsTestEmailConfigSuccess = (data) => ({
	type: LIST_MAILS_TEST_EMAIL_CONFIG_SUCCESS,
	payload: data,
});

export const listEmailsTestEmailConfigFail = (data) => ({
	type: LIST_MAILS_TEST_EMAIL_CONFIG_FAIL,
	payload: data,
});

export const listEmailsClearTestEmailConfig = (data) => ({
	type: LIST_MAILS_CLEAR_TEST_EMAIL_CONFIG,
	payload: data,
});

import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { Button, Col, Modal, ModalBody, Popover, PopoverBody, Row } from "reactstrap";
import FormSubmit from "../../../Components/Common/FormSubmit";
import { Link } from "react-router-dom";
import InputComponent from "../../../Components/Hooks/InputComponent";
import SimpleBar from "simplebar-react";
import SelectComponent from "../../../Components/Hooks/SelectComponent";
import DeleteModal from "../../../Components/Common/DeleteModal";
import { useDispatch } from "react-redux";
import {
	listMailsAddConfigAssign,
	listMailsClearActionConfigAssign,
	listMailsDeleteConfigAssign,
	listMailsGeListConfigAssign,
	listMailsGeListTeamConfigAssign,
	listMailsGeListUserConfigAssign,
	listMailsGetListConfigEmail,
	listMailsUpdateConfigAssign,
} from "../../../store/actions";
import { useSelector } from "react-redux";
import InputTagComponent from "../../../Components/Hooks/InputTagComponent";
import Flatpickr from "react-flatpickr";
import { dateFormatString10, dateFormatString15 } from "../../../Components/Common/FormatTime";
import useFormatDate from "../../../Components/Hooks/FormatDateGMT";
import AvatarIcon from "../../../Components/Common/AvatarIcon";
import moment from "moment";
import useAuthUser from "../../../Components/Hooks/AuthUser";

const ModalConfigFilterAssigner = ({ isOpen = false, toggle = () => {} }) => {
	const dispatch = useDispatch();
	const { arrayPageRoles, userId, typeUser } = useAuthUser();
	const { FormatDateGMT } = useFormatDate();
	const formikRef = useRef();
	const {
		dataDefinitions,
		dataListTeamConfigAssign,
		dataListUserConfigAssign,
		dataListConfigAssign,
		isAddConfigAssignSuccess,
		isDeleteConfigAssignSuccess,
		isUpdateConfigAssignSuccess,
	} = useSelector((state) => state.DashboardListMails);

	// const [isSubmitForm, setIsSubmitForm] = useState(false);
	const refFromDateIn = useRef();
	const [listOptionSearch, setListOptionSearch] = useState([]);
	const [listConfigAssign, setListConfigAssign] = useState([]);
	const [dataConfigById, setDataConfigById] = useState(null);
	const [openModalDelete, setOpenDeleteModal] = useState(false);

	// const [configFilterKeywordsOption, setConfigFilterKeywordsOption] = useState([]);
	// const [configFilterNotKeywordsOption, setConfigFilterNotKeywordsOption] = useState([]);

	const [configNotShowInBody, setConfigNotShowInBody] = useState([]);
	const [configNotShowInSubject, setConfigNotShowInSubject] = useState([]);
	const [configNotShowInSender, setConfigNotShowInSender] = useState([]);
	const [configNotShowInRecipients, setConfigNotShowInRecipients] = useState([]);

	const [configShowInBody, setConfigShowInBody] = useState([]);
	const [configShowInSubject, setConfigShowInSubject] = useState([]);
	const [configShowInSender, setConfigShowInSender] = useState([]);
	const [configShowInRecipients, setConfigShowInRecipients] = useState([]);

	const [optionChooseAssigner, setOptionChooseAssigner] = useState({
		id: "choose_user",
		code: "User",
		value: "User",
	});
	const [openPopoverAssigner, setOpenPopoverAssigner] = useState(false);

	const [listUser, setListUser] = useState([]);
	const [listTeam, setListTeam] = useState([]);
	const [inputSearchTeamAssign, setInputSearchTeamAssign] = useState("");
	const [optionTeamAssigned, setOptionTeamAssigned] = useState([]);
	const [inputSearchUserAssign, setInputSearchUserAssign] = useState("");
	const [optionUserAssigned, setOptionUserAssigned] = useState([]);
	const optionSearchInitArr = useMemo(() => Object.values(dataDefinitions?.OPTION_SEARCH_EMAIL ?? {}) ?? [], [dataDefinitions?.OPTION_SEARCH_EMAIL]);

	useEffect(() => {
		if (optionChooseAssigner?.id === "choose_user") {
			setOptionTeamAssigned([]);
			setInputSearchTeamAssign("");
		} else {
			setOptionUserAssigned([]);
			setInputSearchUserAssign("");
		}
	}, [optionChooseAssigner?.id]);

	useEffect(() => {
		if (Array.isArray(dataListUserConfigAssign)) {
			setListUser(dataListUserConfigAssign);
		}
	}, [dataListUserConfigAssign]);

	useEffect(() => {
		if (Array.isArray(dataListTeamConfigAssign)) {
			setListTeam(dataListTeamConfigAssign);
		}
	}, [dataListTeamConfigAssign]);

	useEffect(() => {
		dispatch(listMailsGeListUserConfigAssign(""));
		dispatch(listMailsGeListTeamConfigAssign(""));
		dispatch(listMailsGeListConfigAssign(""));
	}, [dispatch]);

	useEffect(() => {
		if (Array.isArray(dataListConfigAssign)) {
			setListConfigAssign(dataListConfigAssign);
		}
	}, [dataListConfigAssign]);

	useEffect(() => {
		setListOptionSearch(optionSearchInitArr);
		// setConfigFilterKeywordsOption(optionSearchInitArr);
	}, [optionSearchInitArr]);

	const validate = (values) => {
		let errors = {};
		if (values.configFilterName === "") errors.configFilterName = "Please enter title";
		return errors;
	};

	const submitForm = (values) => {
		const data = {
			configFilterName: values?.configFilterName,
			// configFilterKeywordsOption: values?.configFilterKeywordsOption,

			configShowInSender: values?.configShowInSender,
			configShowInRecipients: values?.configShowInRecipients,
			configShowInSubject: values?.configShowInSubject,
			configShowInBody: values?.configShowInBody,

			configNotShowInSender: values?.configNotShowInSender,
			configNotShowInRecipients: values?.configNotShowInRecipients,
			configNotShowInSubject: values?.configNotShowInSubject,
			configNotShowInBody: values?.configNotShowInBody,

			configFilterNameFromDateIn: values?.configFilterNameFromDateIn ? moment(values?.configFilterNameFromDateIn).format(dateFormatString10) : "",
			configFilterUserId: values?.configFilterUserId,
			configFilterTeamId: values?.configFilterTeamId,
		};

		if (dataConfigById?.id) {
			dispatch(listMailsUpdateConfigAssign({ ...data, id: dataConfigById?.id }));
		} else {
			dispatch(listMailsAddConfigAssign(data));
		}
	};

	const initialValues = {
		configFilterName: dataConfigById?.configFilterName ?? "",
		// configFilterKeywords: dataConfigById?.configFilterKeywords ?? [],
		// configFilterKeywordsOption: dataConfigById?.configFilterKeywordsOption ?? Object.values(dataDefinitions?.OPTION_SEARCH_EMAIL ?? {}) ?? [],
		// configFilterNotKeywords: dataConfigById?.configFilterNotKeywords ?? [],
		// configFilterNotKeywordsOption: dataConfigById?.configFilterNotKeywordsOption ?? [],
		configShowInSender: dataConfigById?.configShowInSender ?? [],
		configShowInRecipients: dataConfigById?.configShowInRecipients ?? [],
		configShowInSubject: dataConfigById?.configShowInSubject ?? [],
		configShowInBody: dataConfigById?.configShowInBody ?? [],

		configNotShowInSender: dataConfigById?.configNotShowInSender ?? [],
		configNotShowInRecipients: dataConfigById?.configNotShowInRecipients ?? [],
		configNotShowInSubject: dataConfigById?.configNotShowInSubject ?? [],
		configNotShowInBody: dataConfigById?.configNotShowInBody ?? [],

		configFilterNameFromDateIn: dataConfigById?.configFilterNameFromDateIn ? new Date(dataConfigById?.configFilterNameFromDateIn) : "",
		configFilterUserId: dataConfigById?.configFilterUserId ?? [],
		configFilterTeamId: dataConfigById?.configFilterTeamId ?? [],
	};

	const clearDate = () => {
		formikRef.current.setFieldValue("configFilterNameFromDateIn", "");
	};

	useEffect(() => {
		// if (dataConfigById?.configFilterKeywords) {
		// setConfigFilterKeywords(dataConfigById.configFilterKeywords);
		// }
		// if (dataConfigById?.configFilterNotKeywords) {
		// setConfigFilterNotKeywords(dataConfigById.configFilterNotKeywords);
		// }
		// if (dataConfigById?.configFilterKeywordsOption) {
		// setConfigFilterKeywordsOption(dataConfigById.configFilterKeywordsOption);
		// }
		// if (dataConfigById?.configFilterNotKeywordsOption) {
		// 	setConfigFilterNotKeywordsOption(dataConfigById.configFilterNotKeywordsOption);
		// }

		if (dataConfigById?.configNotShowInSender) {
			setConfigNotShowInSender(dataConfigById.configNotShowInSender);
		}
		if (dataConfigById?.configNotShowInBody) {
			setConfigNotShowInBody(dataConfigById.configNotShowInBody);
		}
		if (dataConfigById?.configNotShowInRecipients) {
			setConfigNotShowInRecipients(dataConfigById.configNotShowInRecipients);
		}
		if (dataConfigById?.configNotShowInSubject) {
			setConfigNotShowInSubject(dataConfigById.configNotShowInSubject);
		}

		if (dataConfigById?.configShowInBody) {
			setConfigShowInBody(dataConfigById.configShowInBody);
		}
		if (dataConfigById?.configShowInRecipients) {
			setConfigShowInRecipients(dataConfigById.configShowInRecipients);
		}
		if (dataConfigById?.configShowInSender) {
			setConfigShowInSender(dataConfigById.configShowInSender);
		}
		if (dataConfigById?.configShowInSubject) {
			setConfigShowInSubject(dataConfigById.configShowInSubject);
		}

		const userIdArr = dataConfigById?.configFilterUserId ?? [];
		setOptionUserAssigned(listUser?.filter((i) => userIdArr.includes(i.id)) ?? []);
		const teamIdArr = dataConfigById?.configFilterTeamId ?? [];
		setOptionTeamAssigned(listTeam?.filter((i) => teamIdArr.includes(i.id) ?? []));
	}, [dataConfigById]);

	const handleReset = () => {
		setOpenPopoverAssigner(false);
		setDataConfigById(null);
		// setListUser([]);
		// setListTeam([]);
		setInputSearchTeamAssign("");
		setOptionTeamAssigned([]);
		setInputSearchUserAssign("");
		setOptionUserAssigned([]);
		// setConfigFilterKeywords([]);
		// setConfigFilterNotKeywords([]);
		// setConfigFilterKeywordsOption(optionSearchInitArr);
		// setConfigFilterNotKeywordsOption([]);

		setConfigNotShowInSender([]);
		setConfigNotShowInBody([]);
		setConfigNotShowInRecipients([]);
		setConfigNotShowInSubject([]);

		setConfigShowInBody([]);
		setConfigShowInRecipients([]);
		setConfigShowInSender([]);
		setConfigShowInSubject([]);
		toggle();
	};

	useEffect(() => {
		if (isAddConfigAssignSuccess || isDeleteConfigAssignSuccess || isUpdateConfigAssignSuccess) {
			if (isAddConfigAssignSuccess || isDeleteConfigAssignSuccess) {
				setDataConfigById(dataListConfigAssign?.[0] ?? null);
			} else {
				setDataConfigById((prev) => {
					const prevId = prev?.id;
					return dataListConfigAssign?.find((i) => i.id === prevId);
				});
			}

			dispatch(listMailsClearActionConfigAssign());
		}
	}, [isAddConfigAssignSuccess, isDeleteConfigAssignSuccess, isUpdateConfigAssignSuccess, dataListConfigAssign]);

	// useEffect(() => {
	// 	if (isOpen) {
	// 		let delayDebounceFn = setTimeout(() => {
	// 			if (inputSearchUserAssign !== "") dispatch(listMailsGeListUserConfigAssign(inputSearchUserAssign));
	// 		}, 300);

	// 		return () => clearTimeout(delayDebounceFn);
	// 	}
	// }, [dispatch, inputSearchUserAssign]);

	// useEffect(() => {
	// 	if (isOpen) {
	// 		let delayDebounceFn = setTimeout(() => {
	// 			if (inputSearchTeamAssign !== "") dispatch(listMailsGeListTeamConfigAssign(inputSearchTeamAssign));
	// 		}, 300);

	// 		return () => clearTimeout(delayDebounceFn);
	// 	}
	// }, [dispatch, inputSearchTeamAssign]);

	return (
		<>
			<DeleteModal
				show={openModalDelete}
				onDeleteClick={() => {
					if (dataConfigById?.id) {
						dispatch(listMailsDeleteConfigAssign(dataConfigById?.id));
						setDataConfigById(null);
					}
					setOpenDeleteModal(false);
				}}
				onCloseClick={() => {
					setOpenDeleteModal(false);
				}}
			/>
			<Modal isOpen={isOpen} toggle={() => handleReset()} centered className="modal-lg-2">
				<Formik enableReinitialize initialValues={initialValues} validate={validate} onSubmit={submitForm} innerRef={formikRef}>
					{(formik) => {
						const { values, handleChange, setFieldValue, handleSubmit, errors, touched, handleBlur, isValid, dirty, validateForm } = formik;
						return (
							<FormSubmit onSubmit={handleSubmit}>
								<ModalBody className="p-0">
									<Row className="d-flex align-items-center border-bottom border-1 fs-16 m-0">
										<Col xs={3} className="pe-0">
											List Filter & Assign
										</Col>
										<Col xs={9} className="d-flex align-items-center justify-content-between border-start border-1">
											{/* {dataQuickFilter?.id ? "Edit filter" : "Create new filter"} */}
											Config Filter & Assign
											<Link to="#" onClick={handleReset}>
												<i style={{ color: "#adadad" }} className="ri-close-line fs-2"></i>
											</Link>
										</Col>
									</Row>
									<Row className="m-0">
										<Col xs={3} className="pe-0 ps-0">
											<div
												onClick={() => {
													// const optionSearchInitArr = Object.values(dataDefinitions?.OPTION_SEARCH_EMAIL ?? {}) ?? [];
													setDataConfigById(null);
													// setConfigFilterKeywords([]);
													// setConfigFilterNotKeywords([]);
													// setConfigFilterKeywordsOption(optionSearchInitArr);
													setConfigNotShowInSender([]);
													setConfigNotShowInBody([]);
													setConfigNotShowInRecipients([]);
													setConfigNotShowInSubject([]);

													setConfigShowInBody([]);
													setConfigShowInRecipients([]);
													setConfigShowInSender([]);
													setConfigShowInSubject([]);

													// setConfigFilterNotKeywordsOption([]);
													setOptionUserAssigned([]);
													setOptionTeamAssigned([]);
												}}
												className="table-row-hover border-bottom border-1"
												style={{ backgroundColor: "#98FFCF" }}>
												<div className="d-flex align-items-center p-2">
													<i className="ri-add-line me-1"></i>Add Filter
												</div>
											</div>
											<SimpleBar style={{ height: "40vh" }}>
												{listConfigAssign?.map((item, index) => (
													<div
														style={{ backgroundColor: dataConfigById?.id === item.id ? "#d9d9d9" : "" }}
														onClick={() => setDataConfigById(item)}
														className="table-row-hover border-bottom border-1"
														key={index}>
														<div className="p-2">{item?.configFilterName}</div>
													</div>
												))}
											</SimpleBar>
										</Col>
										<Col xs={9} className="border-start border-1 pb-3 ps-0 pe-0  d-flex flex-column justify-content-center ">
											<div className="p-2 d-flex align-items-center border-bottom border-1">
												<div className="w-25 text-end">Name Filter:</div>
												<div className="w-75 mx-3">
													<InputComponent
														type="search"
														name="configFilterName"
														className="form-control"
														placeholder="Enter Name Filter"
														value={values.configFilterName}
														onChange={(value) => setFieldValue("configFilterName", value)}
														onBlur={() => {
															handleBlur({ target: { name: "configFilterName" } });
														}}
														invalid={touched.configFilterName && errors.configFilterName ? true : false}
														error={errors?.configFilterName}
														englishValue
													/>
												</div>
											</div>
											<div className="pe-2 ps-2 d-flex align-items-center border-bottom border-1">
												<div className="mb-0 w-100 pb-1">
													<fieldset className="border rounded-3">
														<legend className="float-none w-auto px-3 fs-14 text-left fw-bold">Keywords</legend>
														<Row className="gy-3">
															<Col md={12}>
																<div className="p-1 d-flex align-items-center border-bottom border-1">
																	<div className="text-end" style={{ width: "20%" }}>
																		Sender:
																	</div>
																	<div className="mx-1" style={{ width: "80%" }}>
																		<InputTagComponent
																			classNameContainer="border-0"
																			classNameBox="p-1 border border-1"
																			styleBox={{ minHeight: "40px" }}
																			styleContainer={{ minHeight: "40px" }}
																			style={{ height: "30px" }}
																			valueTag={configShowInSender}
																			setValueTag={setConfigShowInSender}
																			placeholder="Type and enter to add"
																			handleSetValueField={(newTags) => {
																				setFieldValue("configShowInSender", newTags);
																			}}
																			englishValue
																		/>
																	</div>
																</div>
																<div className="p-1 d-flex align-items-center border-bottom border-1">
																	<div className="text-end" style={{ width: "20%" }}>
																		Recipients:
																	</div>
																	<div className="mx-1" style={{ width: "80%" }}>
																		<InputTagComponent
																			classNameContainer="border-0"
																			classNameBox="p-1 border border-1"
																			styleBox={{ minHeight: "40px" }}
																			styleContainer={{ minHeight: "40px" }}
																			style={{ height: "30px" }}
																			valueTag={configShowInRecipients}
																			setValueTag={setConfigShowInRecipients}
																			placeholder="Type and enter to add"
																			handleSetValueField={(newTags) => {
																				setFieldValue("configShowInRecipients", newTags);
																			}}
																			englishValue
																		/>
																	</div>
																</div>
																<div className="p-1 d-flex align-items-center border-bottom border-1">
																	<div className="text-end" style={{ width: "20%" }}>
																		Subject:
																	</div>
																	<div className="mx-1" style={{ width: "80%" }}>
																		<InputTagComponent
																			classNameContainer="border-0"
																			classNameBox="p-1 border border-1"
																			styleBox={{ minHeight: "40px" }}
																			styleContainer={{ minHeight: "40px" }}
																			style={{ height: "30px" }}
																			valueTag={configShowInSubject}
																			setValueTag={setConfigShowInSubject}
																			placeholder="Type and enter to add"
																			handleSetValueField={(newTags) => {
																				setFieldValue("configShowInSubject", newTags);
																			}}
																			englishValue
																		/>
																	</div>
																</div>
																<div className="p-1 d-flex align-items-center border-bottom border-1">
																	<div className="text-end" style={{ width: "20%" }}>
																		Body:
																	</div>
																	<div className="mx-1" style={{ width: "80%" }}>
																		<InputTagComponent
																			classNameContainer="border-0"
																			classNameBox="p-1 border border-1"
																			styleBox={{ minHeight: "40px" }}
																			styleContainer={{ minHeight: "40px" }}
																			style={{ height: "30px" }}
																			valueTag={configShowInBody}
																			setValueTag={setConfigShowInBody}
																			placeholder="Type and enter to add"
																			handleSetValueField={(newTags) => {
																				setFieldValue("configShowInBody", newTags);
																			}}
																			englishValue
																		/>
																	</div>
																</div>
															</Col>
														</Row>
													</fieldset>
												</div>
											</div>

											<div className="pe-2 ps-2 d-flex align-items-center border-bottom border-1">
												<div className="mb-0 w-100 pb-1">
													<fieldset className="border rounded-3">
														<legend className="float-none w-auto px-3 fs-14 text-left fw-bold">Keywords Exclude</legend>
														<Row className="gy-3">
															<Col md={12}>
																<div className="p-1 d-flex align-items-center border-bottom border-1">
																	<div className="text-end" style={{ width: "20%" }}>
																		Sender:
																	</div>
																	<div className="mx-1" style={{ width: "80%" }}>
																		<InputTagComponent
																			classNameContainer="border-0"
																			classNameBox="p-1 border border-1"
																			styleBox={{ minHeight: "40px" }}
																			styleContainer={{ minHeight: "40px" }}
																			style={{ height: "30px" }}
																			valueTag={configNotShowInSender}
																			setValueTag={setConfigNotShowInSender}
																			placeholder="Type and enter to add"
																			handleSetValueField={(newTags) => {
																				setFieldValue("configNotShowInSender", newTags);
																			}}
																			englishValue
																		/>
																	</div>
																</div>
																<div className="p-1 d-flex align-items-center border-bottom border-1">
																	<div className="text-end" style={{ width: "20%" }}>
																		Recipients:
																	</div>
																	<div className="mx-1" style={{ width: "80%" }}>
																		<InputTagComponent
																			classNameContainer="border-0"
																			classNameBox="p-1 border border-1"
																			styleBox={{ minHeight: "40px" }}
																			styleContainer={{ minHeight: "40px" }}
																			style={{ height: "30px" }}
																			valueTag={configNotShowInRecipients}
																			setValueTag={setConfigNotShowInRecipients}
																			placeholder="Type and enter to add"
																			handleSetValueField={(newTags) => {
																				setFieldValue("configNotShowInRecipients", newTags);
																			}}
																			englishValue
																		/>
																	</div>
																</div>
																<div className="p-1 d-flex align-items-center border-bottom border-1">
																	<div className="text-end" style={{ width: "20%" }}>
																		Subject:
																	</div>
																	<div className="mx-1" style={{ width: "80%" }}>
																		<InputTagComponent
																			classNameContainer="border-0"
																			classNameBox="p-1 border border-1"
																			styleBox={{ minHeight: "40px" }}
																			styleContainer={{ minHeight: "40px" }}
																			style={{ height: "30px" }}
																			valueTag={configNotShowInSubject}
																			setValueTag={setConfigNotShowInSubject}
																			placeholder="Type and enter to add"
																			handleSetValueField={(newTags) => {
																				setFieldValue("configNotShowInSubject", newTags);
																			}}
																			englishValue
																		/>
																	</div>
																</div>
																<div className="p-1 d-flex align-items-center border-bottom border-1">
																	<div className="text-end" style={{ width: "20%" }}>
																		Body:
																	</div>
																	<div className="mx-1" style={{ width: "80%" }}>
																		<InputTagComponent
																			classNameContainer="border-0"
																			classNameBox="p-1 border border-1"
																			styleBox={{ minHeight: "40px" }}
																			styleContainer={{ minHeight: "40px" }}
																			style={{ height: "30px" }}
																			valueTag={configNotShowInBody}
																			setValueTag={setConfigNotShowInBody}
																			placeholder="Type and enter to add"
																			handleSetValueField={(newTags) => {
																				setFieldValue("configNotShowInBody", newTags);
																			}}
																			englishValue
																		/>
																	</div>
																</div>
															</Col>
														</Row>
													</fieldset>
												</div>
											</div>

											<div className="p-2 d-flex align-items-center border-bottom border-1">
												<div className="w-25 text-end">From Date In:</div>
												<div className="input-group w-75 mx-3">
													<Flatpickr
														className="form-control"
														id="date-field"
														value={values.configFilterNameFromDateIn}
														options={{
															dateFormat: "Y M d",
														}}
														onChange={(e) => {
															setFieldValue("configFilterNameFromDateIn", e[0]);
														}}
														placeholder="Choose the day"
														ref={refFromDateIn}
													/>
													{values.configFilterNameFromDateIn && (
														<button
															type="button"
															className="btn bg-transparent"
															style={{ marginLeft: "-40px", zIndex: 1 }}
															onClick={() => {
																clearDate();
															}}>
															<i className="fa fa-times text-black-50"></i>
														</button>
													)}
													<div className="input-group-text bg-primary border-primary text-white">
														<i className="ri-calendar-2-line"></i>
													</div>
												</div>
											</div>
											<div className="p-2 d-flex align-items-center border-bottom border-1">
												<div className="w-25 text-end">Assigner option:</div>
												<div className="w-75 mx-3 d-flex align-items-center">
													<div style={{ width: "80px" }}>
														<SelectComponent
															options={[
																{
																	id: "choose_user",
																	code: "User",
																	value: "User",
																},
																{
																	id: "choose_team",
																	code: "Team",
																	value: "Team",
																},
															]}
															name="assigned"
															onChange={(option) => {
																setOptionChooseAssigner(option);
															}}
															value={optionChooseAssigner}
															fieldValue="id"
															display="value"
															isClearable={false}
														/>
													</div>

													<div
														className="d-flex avatar-group ms-1"
														onClick={(e) => {
															e.stopPropagation();
														}}>
														{optionUserAssigned?.length > 0 &&
															optionUserAssigned?.slice(0, optionUserAssigned?.length > 3 ? 3 : optionUserAssigned?.length)?.map((i, index) => {
																let fullName = i?.id === userId ? "Me" : i?.fullName;
																return (
																	<Link key={index} to="#" className="avatar-group-item" title={fullName}>
																		<div className="rounded-circle">
																			<AvatarIcon text={fullName} sizeAvatar="xs" />
																		</div>
																	</Link>
																);
															})}
														{optionTeamAssigned?.length > 0 &&
															optionTeamAssigned?.slice(0, optionTeamAssigned?.length > 3 ? 3 : optionTeamAssigned?.length)?.map((i, index) => {
																const fullName = i?.name;
																return (
																	<Link key={index} to="#" className="avatar-group-item" title={fullName}>
																		<div className="rounded-circle">
																			<AvatarIcon text={fullName} sizeAvatar="xs" />
																		</div>
																	</Link>
																);
															})}
														<div
															className="avatar-group-item"
															id="popoverConfigFilter"
															onClick={() => {
																setOpenPopoverAssigner(true);
															}}>
															<div className="rounded-circle">
																{(optionUserAssigned?.length || optionTeamAssigned?.length) > 3 ? (
																	<>
																		<AvatarIcon
																			text={`+${
																				(optionUserAssigned?.length || optionTeamAssigned?.length) > 3
																					? (optionUserAssigned?.length || optionTeamAssigned?.length) - 3
																					: ""
																			}`}
																			sizeAvatar="xs"
																		/>
																	</>
																) : (
																	<>
																		<AvatarIcon text={"+"} sizeAvatar="xs" icon={<i className="bx bx-user-plus"></i>} />
																	</>
																)}
															</div>
														</div>
													</div>

													{optionChooseAssigner?.id === "choose_user" && (
														<>
															<Popover
																fade={false}
																placement={"right"}
																isOpen={openPopoverAssigner}
																target="popoverConfigFilter"
																toggle={() => {
																	setOpenPopoverAssigner(false);
																}}
																// innerRef={popoverRef}
															>
																<PopoverBody
																	style={{
																		height: "auto",
																		width: "400px",
																		backgroundColor: "white",
																		boxShadow: "0 5px 15px rgba(30, 32, 37, 0.12)",
																	}}>
																	<div>
																		<div style={{ width: "130px" }}>Assigned to:</div>
																		<div style={{ width: "100%" }}>
																			<SelectComponent
																				isSearchServer
																				options={listUser
																					?.filter((item) => item?.fullName?.toLowerCase()?.includes(inputSearchUserAssign?.toLowerCase()))
																					?.slice(0, 10)}
																				name="configFilterUserId"
																				onChange={(option) => {
																					setOptionUserAssigned(option);
																					setInputSearchUserAssign("");
																					setFieldValue(
																						"configFilterUserId",
																						option?.map((i) => i.id)
																					);
																					setFieldValue("configFilterTeamId", []);
																				}}
																				value={optionUserAssigned}
																				fieldValue="id"
																				setInputValue={setInputSearchUserAssign}
																				inputValue={inputSearchUserAssign}
																				display="fullName"
																				isMulti
																				isShowMultiRows
																				optionIcon
																				displayFieldIcon="icon"
																				isClearable={true}
																			/>
																		</div>
																		<div className="mt-1 d-flex justify-content-end">
																			<Button
																				style={{ width: "60px" }}
																				// disabled={
																				// 	data?.assignedId?.length === optionUsers?.map((user) => user.id).length &&
																				// 	data?.assignedId?.every((item) => optionUsers?.map((user) => user.id).includes(item))
																				// }
																				type="button"
																				color="primary"
																				className="btn btn-sm"
																				onClick={(e) => {
																					e.stopPropagation();
																					setOpenPopoverAssigner(false);
																				}}>
																				Close
																			</Button>
																		</div>
																	</div>
																</PopoverBody>
															</Popover>
														</>
													)}

													{optionChooseAssigner?.id === "choose_team" && (
														<>
															<Popover
																fade={false}
																placement={"right"}
																isOpen={openPopoverAssigner}
																target="popoverConfigFilter"
																toggle={() => {
																	setOpenPopoverAssigner(false);
																}}
																// innerRef={popoverRef}
															>
																<PopoverBody
																	style={{
																		height: "auto",
																		width: "400px",
																		backgroundColor: "white",
																		boxShadow: "0 5px 15px rgba(30, 32, 37, 0.12)",
																	}}>
																	<div>
																		<div style={{ width: "130px" }}>Assigned to:</div>
																		<div style={{ width: "100%" }}>
																			<SelectComponent
																				isSearchServer
																				options={listTeam
																					?.filter((item) => item?.name?.toLowerCase()?.includes(inputSearchTeamAssign?.toLowerCase()))
																					?.slice(0, 10)}
																				name="configFilterTeamId"
																				onChange={(option) => {
																					setOptionTeamAssigned(option);
																					setFieldValue(
																						"configFilterTeamId",
																						option?.map((i) => i.id)
																					);
																					setInputSearchTeamAssign("");
																					setFieldValue("configFilterUserId", []);
																				}}
																				value={optionTeamAssigned}
																				placeholder={"Select team"}
																				fieldValue="id"
																				setInputValue={setInputSearchTeamAssign}
																				inputValue={inputSearchTeamAssign}
																				display="name"
																				isMulti
																				isShowMultiRows
																				optionIcon
																				displayFieldIcon="icon"
																				isClearable={true}
																			/>
																		</div>
																		<div className="mt-1 d-flex justify-content-end">
																			<Button
																				style={{ width: "60px" }}
																				type="button"
																				color="primary"
																				className="btn btn-sm"
																				onClick={(e) => {
																					e.stopPropagation();
																					setOpenPopoverAssigner(false);
																				}}>
																				Close
																			</Button>
																		</div>
																	</div>
																</PopoverBody>
															</Popover>
														</>
													)}
												</div>
											</div>

											<div className="mt-3 px-4 d-flex align-items-center justify-content-between">
												<Button
													disabled={!dataConfigById?.id}
													className="btn-danger"
													onClick={() => {
														setOpenDeleteModal(true);
													}}>
													Delete
												</Button>
												<div>
													<Button
														className="btn-soft-info mx-3"
														onClick={() => {
															handleReset();
														}}>
														Cancel
													</Button>
													{/* <Button type="submit" color="success" disabled={disableButtonUpdate || isErrorWarehouse}> */}
													<Button
														disabled={!dirty}
														type="button"
														color="success"
														onClick={() => {
															// setIsSubmitting(true);
															handleSubmit(values);
															// setTimeout(() => {
															// 	setIsSubmitting(false);
															// }, 3000);
														}}>
														Save
													</Button>
												</div>
											</div>
										</Col>
									</Row>
								</ModalBody>
							</FormSubmit>
						);
					}}
				</Formik>
			</Modal>
		</>
	);
};

export default ModalConfigFilterAssigner;

//

import React from "react";
import PropTypes from "prop-types";

const AvatarIcon = ({ text = "", sizeAvatar = "xxs", icon = null }) => {
	let value = "";
	const arrayText = String(text)
		?.split(" ")
		?.filter((i) => i);

	if (arrayText?.length >= 2) {
		value = `${arrayText?.[0]?.substring(0, 1)?.toUpperCase()}${arrayText?.[1]?.substring(0, 1)?.toUpperCase()}`;
	}
	if (arrayText?.length < 2) {
		value = String(text)?.substring(0, 2)?.toUpperCase();
	}
	return (
		<div className="team-profile-img">
			<div className={`avatar-${sizeAvatar} img-thumbnail rounded-circle flex-shrink-0`} style={{ padding: "0.1rem " }}>
				<div className="avatar-title text-uppercase border rounded-circle bg-light text-primary">{icon ? icon : value}</div>
			</div>
		</div>
	);
};

export default AvatarIcon;

// Actions
export const SEARCH_VPN_GET_PRODUCT_PRICE_XERO = "SEARCH_VPN_GET_PRODUCT_PRICE_XERO";
export const SEARCH_VPN_GET_PRODUCT_PRICE = "SEARCH_VPN_GET_PRODUCT_PRICE";
export const SEARCH_VPN_GET_PRODUCT_PRICE_QUOTE_IN = "SEARCH_VPN_GET_PRODUCT_PRICE_QUOTE_IN";
export const SEARCH_VPN_GET_PRODUCT_PRICE_XERO_QUOTE_IN = "SEARCH_VPN_GET_PRODUCT_PRICE_XERO_QUOTE_IN";
export const SEARCH_VPN_GET_PRODUCT_ERP = "SEARCH_VPN_GET_PRODUCT_ERP";
export const SEARCH_VPN_GET_AMOUNT_PRODUCT_ERP_AU = "SEARCH_VPN_GET_AMOUNT_PRODUCT_ERP_AU";
export const SEARCH_VPN_GET_AMOUNT_PRODUCT_ERP_US = "SEARCH_VPN_GET_AMOUNT_PRODUCT_ERP_US";
export const SEARCH_VPN_GET_FILTER_CONTACT_NAME = "SEARCH_VPN_GET_FILTER_CONTACT_NAME";
export const SEARCH_VPN_GET_FILTER_INVOICE_DATE = "SEARCH_VPN_GET_FILTER_INVOICE_DATE";
// Actions
export const SEARCH_VPN_RESPONSE_SUCCESS = "SEARCH_VPN_RESPONSE_SUCCESS";
export const SEARCH_VPN_RESPONSE_ERROR = "SEARCH_VPN_RESPONSE_ERROR";
// export const SEARCH_VPN_GET_REVENUE_CHARTS_DATA = "SEARCH_VPN_GET_REVENUE_CHARTS_DATA";
export const SEARCH_VPN_REFRESH_LOADING = "SEARCH_VPN_REFRESH_LOADING";
export const SEARCH_VPN_REFRESH_LOADING_LIST_SN = "SEARCH_VPN_REFRESH_LOADING_LIST_SN";

// Action Xero invoice
export const SEARCH_VPN_GET_XERO_INVOICE_BY_NUM = "SEARCH_VPN_GET_XERO_INVOICE_BY_NUM";
export const GET_XERO_INVOICE_BY_NUM = "GET_XERO_INVOICE_BY_NUM";
export const RESET_XERO_INVOICE_BY_NUM = "RESET_XERO_INVOICE_BY_NUM";
export const RESET_LIST_XERO_INVOICE_BY_NUM = "RESET_LIST_XERO_INVOICE_BY_NUM";

//Action Detail
export const SEARCH_VPN_GET_LIST_SERIAL_NUMBER_SEARCH_VPN = "SEARCH_VPN_GET_LIST_SERIAL_NUMBER_SEARCH_VPN";

export const SEARCH_VPN_PRODUCT_ERP_UPDATE_SN = "SEARCH_VPN_PRODUCT_ERP_UPDATE_SN";
export const SEARCH_VPN_PRODUCT_ERP_UPDATE_SN_SUCCESS = "SEARCH_VPN_PRODUCT_ERP_UPDATE_SN_SUCCESS";
export const SEARCH_VPN_PRODUCT_ERP_UPDATE_SN_FAIL = "SEARCH_VPN_PRODUCT_ERP_UPDATE_SN_FAIL";
export const SEARCH_VPN_CLEAR_IS_FETCH_LIST_PRODUCT_ERP = "SEARCH_VPN_CLEAR_IS_FETCH_LIST_PRODUCT_ERP";
export const SEARCH_VPN_GET_SN_BY_ID = "SEARCH_VPN_GET_SN_BY_ID";

export const SEARCH_VPN_QUICK_ADD_SUPPLIER = "SEARCH_VPN_QUICK_ADD_SUPPLIER";
export const SEARCH_VPN_QUICK_ADD_SUPPLIER_SUCCESS = "SEARCH_VPN_QUICK_ADD_SUPPLIER_SUCCESS";
export const SEARCH_VPN_QUICK_ADD_SUPPLIER_FAIL = "SEARCH_VPN_QUICK_ADD_SUPPLIER_FAIL";
export const SEARCH_VPN_CACHE_QUICK_ADD_SUPPLIER = "SEARCH_VPN_CACHE_QUICK_ADD_SUPPLIER";
export const SEARCH_VPN_GET_LIST_HASHTAGS = "SEARCH_VPN_GET_LIST_HASHTAGS";
export const SEARCH_VPN_RESET_LOADING_PAGE_LIST_SN = "SEARCH_VPN_RESET_LOADING_PAGE_LIST_SN";
export const SEARCH_VPN_GET_LIST_SUPPLIER = "SEARCH_VPN_GET_LIST_SUPPLIER";

export const SEARCH_VPN_GET_LIST_MANAGEMENT = "SEARCH_VPN_GET_LIST_MANAGEMENT";
export const SEARCH_VPN_GET_LIST_STATUS = "SEARCH_VPN_GET_LIST_STATUS";
export const SEARCH_VPN_GET_LIST_ORGANIZATION = "SEARCH_VPN_GET_LIST_ORGANIZATION";
export const SEARCH_VPN_GET_LIST_POSITION = "SEARCH_VPN_GET_LIST_POSITION";
export const SEARCH_VPN_GET_LIST_USER_PRODUCT_ERP = "SEARCH_VPN_GET_LIST_USER_PRODUCT_ERP";
export const SEARCH_VPN_GET_TEST_LOG_SERIAL_NUMBER_PRODUCT_ERP = "SEARCH_VPN_GET_TEST_LOG_SERIAL_NUMBER_PRODUCT_ERP";
// Scan
export const SEARCH_VPN_GET_LOG_TEXT_SERIAL_NUMBER_PRODUCT_ERP = "SEARCH_VPN_GET_LOG_TEXT_SERIAL_NUMBER_PRODUCT_ERP";
export const SEARCH_VPN_GET_LOG_TEXT_SERIAL_NUMBER_PRODUCT_ERP_SUCCESS = "SEARCH_VPN_GET_LOG_TEXT_SERIAL_NUMBER_PRODUCT_ERP_SUCCESS";
export const SEARCH_VPN_GET_LOG_TEXT_SERIAL_NUMBER_PRODUCT_ERP_FAIL = "SEARCH_VPN_GET_LOG_TEXT_SERIAL_NUMBER_PRODUCT_ERP_FAIL";
export const SEARCH_VPN_RESET_INIT_DATA_LOG_PRODUCT_ERP = "SEARCH_VPN_RESET_INIT_DATA_LOG_PRODUCT_ERP_FAIL";

export const SEARCH_VPN_GET_WAREHOUSE = "SEARCH_VPN_GET_WAREHOUSE";
export const SEARCH_VPN_GET_FILTER_CONDITION = "SEARCH_VPN_GET_FILTER_CONDITION";
export const SEARCH_VPN_GET_PRODUCT_MODEL_ID = "SEARCH_VPN_GET_PRODUCT_MODEL_ID";
export const SEARCH_VPN_GET_PRODUCT_MODEL_CODE = "SEARCH_VPN_GET_PRODUCT_MODEL_CODE";
export const SEARCH_VPN_GET_WAREHOUSE_SEARCH = "SEARCH_VPN_GET_WAREHOUSE_SEARCH";
export const SEARCH_VPN_GET_PARAMS_TABLE_FOOTER_SEARCH = "SEARCH_VPN_GET_PARAMS_TABLE_FOOTER_SEARCH";
export const SEARCH_VPN_GET_CONDITION_SEARCH = "SEARCH_VPN_GET_CONDITION_SEARCH";
export const SEARCH_VPN_CLEAR_ALL_SEARCH_DATA = "SEARCH_VPN_CLEAR_ALL_SEARCH_DATA";
export const SEARCH_VPN_GET_LIST_MODEL = "SEARCH_VPN_GET_LIST_MODEL";
export const SEARCH_VPN_GET_HISTORY_PRODUCT_ERP = "SEARCH_VPN_GET_HISTORY_PRODUCT_ERP";
export const SEARCH_VPN_GET_RECORD_LOGS_PRODUCT_ERP = "SEARCH_VPN_GET_RECORD_LOGS_PRODUCT_ERP";

export const SEARCH_VPN_GET_LIST_EMAIL = "SEARCH_VPN_GET_LIST_EMAIL";
export const SEARCH_VPN_CLEAR_DATA_EDIT_PRODUCT_ERP = "SEARCH_VPN_CLEAR_DATA_EDIT_PRODUCT_ERP";
export const SEARCH_VPN_CLEAR_DATA_EMAIL = "SEARCH_VPN_CLEAR_DATA_EMAIL";

export const SEARCH_VPN_GET_LIST_WAREHOUSE_PRODUCT_ERP = "SEARCH_VPN_GET_LIST_WAREHOUSE_PRODUCT_ERP";
export const SEARCH_VPN_GET_LIST_CONDITION_PRODUCT_ERP = "SEARCH_VPN_GET_LIST_CONDITION_PRODUCT_ERP";
export const SEARCH_VPN_GET_LIST_MANAGEMENT_PRODUCT_ERP = "SEARCH_VPN_GET_LIST_MANAGEMENT_PRODUCT_ERP";
export const SEARCH_VPN_GET_LIST_STATUS_PRODUCT_ERP = "SEARCH_VPN_GET_LIST_STATUS_PRODUCT_ERP";
export const SEARCH_VPN_GET_LIST_HASHTAGS_PRODUCT_ERP = "SEARCH_VPN_GET_LIST_HASHTAGS_PRODUCT_ERP";
export const SEARCH_VPN_GET_LIST_SUPPLIER_PRODUCT_ERP = "SEARCH_VPN_GET_LIST_SUPPLIER_PRODUCT_ERP";

export const SEARCH_VPN_QUICK_ADD_SUPPLIER_FILTER_PRODUCT_ERP = "SEARCH_VPN_QUICK_ADD_SUPPLIER_FILTER_PRODUCT_ERP";
export const SEARCH_VPN_QUICK_ADD_SUPPLIER_FILTER_PRODUCT_ERP_SUCCESS = "SEARCH_VPN_QUICK_ADD_SUPPLIER_PRODUCT_ERP_SUCCESS";
export const SEARCH_VPN_QUICK_ADD_SUPPLIER_FILTER_PRODUCT_ERP_FAIL = "SEARCH_VPN_QUICK_ADD_SUPPLIER_FILTER_PRODUCT_ERP_FAIL";
export const SEARCH_VPN_CLEAR_QUICK_ADD_SUPPLIER_FILTER_PRODUCT_ERP = "SEARCH_VPN_CLEAR_QUICK_ADD_SUPPLIER_FILTER_PRODUCT_ERP";
export const SEARCH_VPN_QUICK_ADD_SUPPLIER_EDIT_PRODUCT_ERP = "SEARCH_VPN_QUICK_ADD_SUPPLIER_EDIT_PRODUCT_ERP";
export const SEARCH_VPN_QUICK_ADD_SUPPLIER_EDIT_PRODUCT_ERP_SUCCESS = "SEARCH_VPN_QUICK_ADD_SUPPLIER_EDIT_PRODUCT_ERP_SUCCESS";
export const SEARCH_VPN_QUICK_ADD_SUPPLIER_EDIT_PRODUCT_ERP_FAIL = "SEARCH_VPN_QUICK_ADD_SUPPLIER_EDIT_PRODUCT_ERP_FAIL";
export const SEARCH_VPN_CLEAR_QUICK_ADD_SUPPLIER_EDIT_PRODUCT_ERP = "SEARCH_VPN_CLEAR_QUICK_ADD_SUPPLIER_EDIT_PRODUCT_ERP";
export const SEARCH_VPN_CLEAR_SEARCH_DATA_PRODUCT_ERP = "SEARCH_VPN_CLEAR_SEARCH_DATA_PRODUCT_ERP";
export const SEARCH_VPN_GET_PRODUCT_MODEL_CODE_FILTER_PRODUCT_ERP = "SEARCH_VPN_GET_PRODUCT_MODEL_CODE_FILTER_PRODUCT_ERP";
export const SEARCH_VPN_GET_PRODUCT_MODEL_CODE_ID_FILTER_PRODUCT_ERP = "SEARCH_VPN_GET_PRODUCT_MODEL_CODE_ID_FILTER_PRODUCT_ERP";
export const SEARCH_VPN_GET_ORGCODE_FILTER_PRODUCT_ERP = "SEARCH_VPN_GET_ORGCODE_FILTER_PRODUCT_ERP";
export const SEARCH_VPN_GET_CONDITION_CODE_FILTER_PRODUCT_ERP = "SEARCH_VPN_GET_CONDITION_CODE_FILTER_PRODUCT_ERP";
export const SEARCH_VPN_GET_CONDITION_FILTER_PRODUCT_ERP = "SEARCH_VPN_GET_CONDITION_FILTER_PRODUCT_ERP";
export const SEARCH_VPN_GET_WAREHOUSE_FILTER_PRODUCT_ERP = "SEARCH_VPN_GET_WAREHOUSE_FILTER_PRODUCT_ERP";
export const SEARCH_VPN_GET_LIST_MODEL_PRODUCT_ERP = "SEARCH_VPN_GET_LIST_MODEL_PRODUCT_ERP";
export const SEARCH_VPN_GET_LIST_ORGANIZATION_PRODUCT_ERP = "SEARCH_VPN_GET_LIST_ORGANIZATION_PRODUCT_ERP";
export const SEARCH_VPN_GET_LIST_POSITION_PRODUCT_ERP = "SEARCH_VPN_GET_LIST_POSITION_PRODUCT_ERP";

/* EVENTS */
export const CATEGORY_API_GET_LIST = "CATEGORY_API_GET_LIST";
// export const CATEGORY_GET_LIST_MANUFACTOR = "CATEGORY_GET_LIST_MANUFACTOR";
export const CATEGORY_GET_LIST_CATEGORY_FILTER = "CATEGORY_GET_LIST_CATEGORY_FILTER";
export const CATEGORY_GET_LIST_CATEGORY = "CATEGORY_GET_LIST_CATEGORY";

export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAIL = "DELETE_CATEGORY_FAIL";

export const CATEGORY_ADD_CATEGORY = "CATEGORY_ADD_CATEGORY";
export const CATEGORY_ADD_CATEGORY_SUCCESS = "CATEGORY_ADD_CATEGORY_SUCCESS";
export const CATEGORY_ADD_CATEGORY_FAIL = "CATEGORY_ADD_CATEGORY_FAIL";

export const CATEGORY_UPDATE_CATEGORY = "CATEGORY_UPDATE_CATEGORY";
export const CATEGORY_UPDATE_CATEGORY_SUCCESS = "CATEGORY_UPDATE_CATEGORY_SUCCESS";
export const CATEGORY_UPDATE_CATEGORY_FAIL = "CATEGORY_UPDATE_CATEGORY_FAIL";

export const CATEGORY_GET_DATA_CATEGORY_BY_ID = "CATEGORY_GET_DATA_CATEGORY_BY_ID";

// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";

export const CATEGORY_CLEAR_CACHE = "CATEGORY_CLEAR_CACHE";
export const CATEGORY_CLEAR_CACHE_ACTION = "CATEGORY_CLEAR_CACHE_ACTION";

import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";
import { Accordion, AccordionItem, AccordionHeader, AccordionBody } from "reactstrap";

function AccordionComponent({ header, content, tagHeader = "div", classNameHeader = "accordion-padding-0", isAutoOpen = true }) {
	const [open, setOpen] = useState(isAutoOpen ? "1" : "");
	const toggle = (id) => {
		if (open === id) {
			setOpen("");
		} else {
			setOpen(id);
		}
	};

	return (
		<Accordion flush open={open} toggle={toggle} className="lefticon-accordion">
			<AccordionItem>
				<AccordionHeader tag={tagHeader} targetId="1" className={classNameHeader}>
					{header}
				</AccordionHeader>
				<AccordionBody accordionId="1" className="accordion-body-color-unset">
					{content}
				</AccordionBody>
			</AccordionItem>
		</Accordion>
	);
}

export default AccordionComponent;
